/**
 * Top画面表示種別
 */
import { createContext, useContext } from 'react';

// TOP画面表示種別
export type TopType = 'usage' | 'wash' | 'maintenance';

type TopTypeContextParams = {
  topType: TopType;
  setTopType: (newTopType: TopType) => void;
};
export const TopTypeContext = createContext<TopTypeContextParams>({} as TopTypeContextParams);
export const useTopType = (): TopTypeContextParams => useContext(TopTypeContext);
