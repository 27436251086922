/**
 * 画面サイズ変更をフックする
 */
import { useEffect, useState, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useWindowSize = () => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const unmounted = useRef(false);

  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    width: undefined | number;
    height: undefined | number;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // メモリリーク対応
  const Debounce = (fn: () => void, ms: number): (() => void) => {
    return () => {
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        timer.current = null;
        fn();
      }, ms);
    };
  };

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = Debounce(() => {
      // Set window width/height to state
      if (!unmounted.current) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    }, 500);

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => {
      unmounted.current = true;
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};
