/** @jsx jsx */
import React, { FC, useEffect, useState } from 'react';
import { jsx, css } from '@emotion/core';
import 'semantic-ui-css/semantic.min.css';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import Amplify, { I18n, Auth } from 'aws-amplify';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { UserContext, User, useAuth, AuthData } from 'custom_hook/useAuth';
import { RestroomContext, RestroomItems } from 'custom_hook/useRestroom';
import { TopTypeContext, TopType } from 'custom_hook/useTopType';
import { ToDateContext, ToDateParams, ChengeState } from 'custom_hook/useToDate';
import TopContainer from 'containers/Top';
import DetailinfoContainer from 'containers/Detailinfo';
import awsExports from 'aws-exports';
import { GraphQLResult } from '@aws-amplify/api';
import { getClipHgRestroom } from 'graphql/queries';
import { GetClipHgRestroomQuery, GetClipHgRestroomQueryVariables } from 'API';
import { LastestLog } from 'utils/Log';
import { ApiGraphqlOperationWrapper, GetImageList } from 'utils/Common';
import dayjs from 'dayjs';
import { TRANSLATION } from 'utils/translations';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { SystemSettingContainer } from 'containers/SystemSetting';
import { SystemSettingOpenContext } from 'custom_hook/useSystemSettingOpen';
// import { Test } from 'components/Graphs';

Amplify.configure(awsExports);
// 日本語化
I18n.putVocabulariesForLanguage('ja', TRANSLATION);

const cssMain = css`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const UserProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User>();

  const updateUser = async (authData?: unknown) => {
    const tmpUser = (authData || (await Auth.currentAuthenticatedUser({ bypassCache: true }))) as AuthData;
    if (tmpUser) {
      const groups = tmpUser.signInUserSession?.accessToken?.payload?.['cognito:groups'] ?? [''];
      const isAdmin = groups.includes('Admins');

      const data = tmpUser as User;
      if (data.username) {
        setUser({
          username: data.username,
          attributes: {
            'custom:map_coordinate': data.attributes?.['custom:map_coordinate'],
            'custom:map_zoom_level': data.attributes?.['custom:map_zoom_level'],
            'custom:disp_tw_zoom_level': data.attributes?.['custom:disp_tw_zoom_level'],
            email: data.attributes?.email,
          },
          isAdmin,
        });
      }
    }
  };

  return <UserContext.Provider value={{ user, setUser, updateUser }}>{children}</UserContext.Provider>;
};

const RestroomProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [restroom, setRestroom] = useState<RestroomItems>();

  // 最新のRestroom取得
  const Update = async (restroomId?: string): Promise<boolean> => {
    const updateRestroomId = (() => {
      if (restroomId !== undefined) {
        return restroomId;
      }
      if (restroom?.restroomId !== undefined) {
        return restroom.restroomId;
      }

      return '';
    })();
    if (updateRestroomId.length <= 0) {
      return false;
    }
    try {
      // restroomIdとnameで1件取得
      const conditions: GetClipHgRestroomQueryVariables = {
        restroomId: updateRestroomId,
      };
      const result = await ApiGraphqlOperationWrapper<
        GetClipHgRestroomQueryVariables,
        GraphQLResult<GetClipHgRestroomQuery>
      >(conditions, getClipHgRestroom);

      const restroomItem = (result.data?.getClipHgRestroom as unknown) as RestroomItems;

      if (restroomItem) {
        restroomItem.images = await GetImageList(restroomItem.restroomId);
        setRestroom(restroomItem);

        return true;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('[RestroomProvider_Update]', e);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[RestroomProvider_Update] ${(e as { message: string })?.message}`);
    }

    return false;
  };

  return <RestroomContext.Provider value={{ restroom, setRestroom, Update }}>{children}</RestroomContext.Provider>;
};

const TopTypeProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [topType, setTopType] = useState<TopType>('usage');

  return <TopTypeContext.Provider value={{ topType, setTopType }}>{children}</TopTypeContext.Provider>;
};

const ToDateProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [toDates, setToDates] = useState<ToDateParams>({
    top: dayjs().subtract(1, 'hours').format('YYYY-MM-DD HH:59:59'),
    detail: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  });

  const setToDate = (newToDate: string, state?: ChengeState) => {
    const stateToDate = ((): string => {
      // TOPからは１時間引かれた時間が来る
      if (state === 'top') {
        return dayjs(newToDate).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss');
      }

      // detailはそのまま使う
      return dayjs(newToDate).format('YYYY-MM-DD HH:mm:ss');
    })();

    // 時間は最新にする
    const updateDate = dayjs(stateToDate).format('YYYY-MM-DD');
    const updateTime = dayjs().format('HH:mm:ss');
    const updateDateTime = dayjs(`${updateDate} ${updateTime}`).format('YYYY-MM-DD HH:mm:ss');

    setToDates({
      top: dayjs(updateDateTime).subtract(1, 'hours').format('YYYY-MM-DD HH:59:59'),
      detail: dayjs(updateDateTime).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  return <ToDateContext.Provider value={{ toDates, setToDate }}>{children}</ToDateContext.Provider>;
};

const SystemSettingOpenProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isShow, setIsShow] = useState<boolean>(false);

  const toggle = () => {
    setIsShow(!isShow);
  };

  return (
    <SystemSettingOpenContext.Provider value={{ isShow, setIsShow, toggle }}>
      {children}
    </SystemSettingOpenContext.Provider>
  );
};
const AppProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <UserProvider>
      <RestroomProvider>
        <ToDateProvider>
          <SystemSettingOpenProvider>
            <TopTypeProvider>{children}</TopTypeProvider>
          </SystemSettingOpenProvider>
        </ToDateProvider>
      </RestroomProvider>
    </UserProvider>
  );
};

const AuthStateCheck: FC = () => {
  const [authState, setAuthState] = useState<AuthState>();
  const { user, updateUser } = useAuth();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);

      if (authData) {
        void updateUser(authData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <React.Fragment>
      <SystemSettingContainer />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={TopContainer} />
          <Route exact path="/detail" component={DetailinfoContainer} />
          {/* <Route exact path="/test" component={Test} /> */}
          <Route exact path="/:view" component={TopContainer} />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" hideSignUp />
    </AmplifyAuthenticator>
  );
};

const App: FC = () => {
  return (
    <div className="App" css={cssMain}>
      <AppProvider>
        <AuthStateCheck />
      </AppProvider>
    </div>
  );
};

export default App;
