/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsx jsx */
import { FC, useState, useRef, useEffect } from 'react';
import { jsx, css } from '@emotion/core';
import { Overlay } from 'components/Overlay';
import { useAuth } from 'custom_hook/useAuth';
import GoogleMapReact from 'google-map-react';
import { ConvertCoordinateByUser, MapLatlon, getRestroom } from 'utils/Common';
import { GOOGLEMAP_API_KEY, AUTO_UPDATE_TIME } from 'utils/AppConfig';
import { RestroomItems } from 'custom_hook/useRestroom';
import { useSystemSettingOpen } from 'custom_hook/useSystemSettingOpen';
import { Loader } from 'semantic-ui-react';

const cssSystemSetting = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.3em;
  color: #565656;

  button {
    width: 100%;
    height: 100%;
    padding: 0;
    color: inherit;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    appearance: none;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .outer {
    z-index: 1001;
    width: 100%;
    max-height: 100%;
    padding: 30px 0;
    overflow-y: auto;

    .outer-row {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .outer-row + .outer-row {
      padding-top: 50px;
    }
  }

  .inner {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 1em;
    background-color: #fff;
    border-radius: 10px;

    li {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 0;
      margin: 0 13px;
    }

    li + li {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    span {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .left {
      justify-content: left;
    }

    .left-title {
      width: 200px;
    }

    .right {
      z-index: 2;
      justify-content: right;
    }

    .icon-close {
      position: absolute;
      top: -8px;
      right: calc(-50px - 1em);
      width: 32px;
      height: 50px;
    }

    .center {
      position: absolute;
      justify-content: center;
      font-size: 25px;
      font-weight: bold;
    }

    .map {
      height: 200px;

      .title: {
        width: 100px;
      }

      .content {
        flex-grow: 1;
        height: 100%;
        padding-left: 20px;
      }
    }

    .inner-title {
      height: 60px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 125px;
    }

    .all-button {
      height: 25px;
      font-size: 14px;
      font-weight: 700;
      color: #333;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 4px;
    }

    .all-button:disabled {
      cursor: auto;
      opacity: 0.4;
    }
  }

  /* 閉じるボタン */
  .round_btn {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    background: #fff; /* ボタンの背景色 */
    border-radius: 50%; /* 丸みの度合い */
  }

  .round_btn::before,
  .round_btn::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px; /* 棒の幅（太さ） */
    height: 22px; /* 棒の高さ */
    content: '';
    background: #333; /* バツ印の色 */
  }

  .round_btn::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .round_btn::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

/**
 * システム設定画面
 *
 * @param {function} getRestroomsList トイレデータ取得処理（全データ）
 * @param {function} UpdateSendAlertMail アラートメール送信切替
 * @param {function} UpdateViewAlertSecurity  防犯アラート表示切替
 */
type SystemSettingProps = {
  getRestroomsList: () => Promise<RestroomItems[]>;
  UpdateSendAlertMail: (restrooms: RestroomItems[], isOn: boolean) => Promise<void>;
  UpdateViewAlertSecurity: (restrooms: RestroomItems[], isOn: boolean) => Promise<void>;
};
export const SystemSetting: FC<SystemSettingProps> = ({
  getRestroomsList,
  UpdateSendAlertMail,
  UpdateViewAlertSecurity,
}) => {
  const unmounted = useRef(false);
  const systemSetting = useSystemSettingOpen();
  const intervalId = useRef<NodeJS.Timeout | number>(0);
  const [mapCoord, setMapCoord] = useState<MapLatlon | null>(null);
  const [restrooms, setRestrooms] = useState<RestroomItems[]>([]);
  const [isLoadingSendAlertMail, setIsLoadingSendAlertMail] = useState<boolean>(false);
  const auth = useAuth();

  // マーカー設置
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleApiLoaded = (map: any, maps: any) => {
    if (!mapCoord) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, no-new, @typescript-eslint/no-unsafe-member-access
    new maps.Marker({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      map,
      position: {
        lat: mapCoord.lat,
        lng: mapCoord.lon,
      },
    });
  };

  // トイレ更新
  const Update = async () => {
    const newRestrooms = await getRestroomsList();
    if (!unmounted.current) {
      setRestrooms(newRestrooms);
    }
  };

  // アラートメール送信切り替え
  const handleChangeSendAlertMail = async (updateRestrooms: RestroomItems[], isOn: boolean) => {
    if (updateRestrooms.length <= 0) {
      return;
    }

    if (!unmounted.current) {
      setIsLoadingSendAlertMail(true);
    }

    await UpdateSendAlertMail(updateRestrooms, isOn);

    // 単体
    if (updateRestrooms.length === 1) {
      const newRestroom = await getRestroom(updateRestrooms[0].restroomId, auth.user?.username ?? '');
      if (newRestroom) {
        for (let i = 0; i < restrooms.length; i += 1) {
          if (restrooms[i].restroomId === newRestroom.restroomId) {
            restrooms[i].isNotify = newRestroom.isNotify;
            if (!unmounted.current) {
              setRestrooms([...restrooms]);
            }
            break;
          }
        }
      }
    }
    // 全更新
    else {
      await Update();
    }

    if (!unmounted.current) {
      setIsLoadingSendAlertMail(false);
    }
  };

  // 防犯アラート表示切替
  const handleChangeViewAlertSecurity = async (updateRestrooms: RestroomItems[], isOn: boolean) => {
    if (updateRestrooms.length <= 0) {
      return;
    }

    if (!unmounted.current) {
      setIsLoadingSendAlertMail(true);
    }

    await UpdateViewAlertSecurity(updateRestrooms, isOn);

    // 単体
    if (updateRestrooms.length === 1) {
      const newRestroom = await getRestroom(updateRestrooms[0].restroomId, auth.user?.username ?? '');
      if (newRestroom) {
        for (let i = 0; i < restrooms.length; i += 1) {
          if (restrooms[i].restroomId === newRestroom.restroomId) {
            restrooms[i].isHiddenSecurityWarning = newRestroom.isHiddenSecurityWarning;
            if (!unmounted.current) {
              setRestrooms([...restrooms]);
            }
            break;
          }
        }
      }
    }
    // 全更新
    else {
      await Update();
    }

    if (!unmounted.current) {
      setIsLoadingSendAlertMail(false);
    }
  };

  // マーカー座標更新
  useEffect(() => {
    const coord = auth.user?.attributes?.['custom:map_coordinate'];
    const tmp = ConvertCoordinateByUser(coord);
    if (!unmounted.current) {
      setMapCoord(tmp);
    }
  }, [auth]);

  // トイレ一覧更新
  useEffect(() => {
    const fn = () => void Update();
    fn();

    clearInterval(intervalId.current as number);
    intervalId.current = setInterval(fn, AUTO_UPDATE_TIME);

    return () => {
      clearInterval(intervalId.current as number);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <section css={cssSystemSetting} style={{ display: `${systemSetting.isShow ? '' : 'none'}` }}>
      <Overlay isOpen={systemSetting.isShow} isBlack />
      <ul className="outer">
        {isLoadingSendAlertMail && (
          <div>
            <Loader active size="large" />
          </div>
        )}
        <li className="outer-row">
          <ul className="inner">
            <li className="inner-title">
              <span className="icon-close">
                <button type="button" onClick={() => systemSetting.toggle()}>
                  <span className="round_btn" />
                </button>
              </span>
              <span className="center">ユーザー情報</span>
            </li>
            <li>
              <span className="left-title">ユーザー名</span>
              <span className="left">{auth.user?.username}</span>
            </li>
            <li>
              <span className="left-title">メールアドレス</span>
              <span className="left">{auth.user?.attributes.email}</span>
            </li>
            <li className="map">
              <div className="title">初期表示位置</div>
              <div className="content">
                {mapCoord && (
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: `${GOOGLEMAP_API_KEY}` }}
                    defaultCenter={{ lat: mapCoord.lat, lng: mapCoord.lon }}
                    defaultZoom={13}
                    options={{
                      clickableIcons: false,
                      disableDoubleClickZoom: true,
                      fullscreenControl: false,
                      zoomControl: false,
                      scaleControl: false,
                      draggable: false,
                      keyboardShortcuts: false,
                      draggableCursor: 'auto',
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onGoogleApiLoaded={(maps: { map: any; maps: any }) => handleApiLoaded(maps.map, maps.maps)}
                    yesIWantToUseGoogleMapApiInternals
                  />
                )}
              </div>
            </li>
            <li className="inner-title">
              <span className="center">アラートメール</span>
              <span className="right">
                <div className="buttons">
                  <button
                    type="button"
                    className="all-button"
                    disabled={restrooms.every((item) => item.isNotify)}
                    onClick={() => handleChangeSendAlertMail(restrooms, true)}
                  >
                    ON
                  </button>
                  <button
                    type="button"
                    className="all-button"
                    disabled={restrooms.every((item) => !item.isNotify)}
                    onClick={() => handleChangeSendAlertMail(restrooms, false)}
                  >
                    OFF
                  </button>
                </div>
              </span>
            </li>
            {restrooms.map((restroom) => {
              const key = `system_setting_notify_${restroom.restroomId}`;

              return (
                <li key={key}>
                  <span>{restroom.name}</span>
                  <span className="right">
                    <div className="ui fitted toggle checkbox">
                      <input
                        type="checkbox"
                        checked={restroom.isNotify}
                        onChange={() => handleChangeSendAlertMail([restroom], !restroom.isNotify)}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label />
                    </div>
                  </span>
                </li>
              );
            })}
            {/*
            <li className="inner-title">
              <span className="center">防犯検知アラート</span>
              <span className="right">
                <div className="buttons">
                  <button
                    type="button"
                    className="all-button"
                    disabled={restrooms.every((item) => !item.isHiddenSecurityWarning)}
                    onClick={() => handleChangeViewAlertSecurity(restrooms, false)}
                  >
                    ON
                  </button>
                  <button
                    type="button"
                    className="all-button"
                    disabled={restrooms.every((item) => item.isHiddenSecurityWarning)}
                    onClick={() => handleChangeViewAlertSecurity(restrooms, true)}
                  >
                    OFF
                  </button>
                </div>
              </span>
            </li>
            {restrooms.map((restroom) => {
              const key = `system_setting_security_${restroom.restroomId}`;

              return (
                <li key={key}>
                  <span>{restroom.name}</span>
                  <span className="right">
                    <div className="ui fitted toggle checkbox">
                      <input
                        type="checkbox"
                        checked={!restroom.isHiddenSecurityWarning}
                        onChange={() => handleChangeViewAlertSecurity([restroom], !restroom.isHiddenSecurityWarning)}
                      />
                      <label />
                    </div>
                  </span>
                </li>
              );
            })}
            */}
          </ul>
        </li>
      </ul>
      <Overlay isOpen={isLoadingSendAlertMail} isTransparent zIndex={1001} />
    </section>
  );
};
