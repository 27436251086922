/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
/**
 * Top画面
 *
 * 使い方
 * タグで囲んで使用する。サイズは親に合わせる
 * <div style={{ height: '50vh', width: '50%' }}>
 *  <Top />
 * </div>
 */
import React, { FC, useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { jsx, css } from '@emotion/core';
import { RestroomItems, useRestroom } from 'custom_hook/useRestroom';
import GoogleMapReact from 'google-map-react';
import titleUsageStatus from 'images/title/UsageStatus.png';
import titleWashStatus from 'images/title/WashStatus.png';
import titleMaintenance from 'images/title/Maintenance.png';
import dayjs from 'dayjs';
import partsCorner from 'images/htmlparts/corner.png';
import partsLogoFrame from 'images/htmlparts/logo_frame.png';
import clipLogoWhiteImg from 'images/ClipLogoWhite.svg';
import bgRankingUserDesc from 'images/background/ranking/user_desc.png';
import bgRankingUserAsc from 'images/background/ranking/user_asc.png';
import bgRankingWashDesc from 'images/background/ranking/wash_desc.png';
import bgRankingWashAsc from 'images/background/ranking/wash_asc.png';
import bgAlertWash from 'images/background/alert/wash.png';
import {
  usageIcon,
  usageIconWhite,
  Usage,
  GraphColor,
  AUTO_UPDATE_TIME,
  alertColor,
  AlertIconType,
  TIMER_COLOR_NOW,
  TIMER_COLOR,
  GOOGLEMAP_API_KEY,
} from 'utils/AppConfig';
import { Marker, MarkerProps, ConvertRestroomToMarker } from 'components/Marker';
import { ToiletGraph } from 'components/Graphs';
import { useTopType, TopType } from 'custom_hook/useTopType';
import iconTotalTrans from 'images/icon/all_window/total_trans.png';
import WeatherFolderContainer from 'containers/WeatherFolder';
import { GetWarningIconByTopList } from 'utils/Warnings';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader, Icon, SemanticICONS } from 'semantic-ui-react';
import { CreateClipHgSubtotalInput } from 'API';
import { Menu, MenuHandler } from 'components/parts/Menu';
import { AllAreaDatas, AreaDatas, MapInitData } from 'containers/Top';
import { UserContextType } from 'custom_hook/useAuth';
import { GetFromDatetimeByTop, MapLatlon } from 'utils/Common';
import { useSystemSettingOpen } from 'custom_hook/useSystemSettingOpen';

// トイレ検索表示設定保存キー
const SAVEKEY_RESTROOM_SEARCH_VIEW = 'SAVEKEY_RESTROOM_SEARCH_VIEW';
// トイレ検索選択状態保存キー
const SAVEKEY_RESTROOM_SELECTED_VALUE = 'SAVEKEY_RESTROOM_SELECTED_VALUE';
// 地図座標保存キー
const SAVEKEY_MAP_CENTER = 'SAVEKEY_MAP_CENTER';
// 地図ズームレベル保存キー
const SAVEKEY_MAP_ZOOM = 'SAVEKEY_MAP_ZOOM';

// ズームレベルで表示するマーカー
const MarkerZoomLevel = {
  ward: 13,
  city: 10,
  pref: 7,
};

// 地図表示範囲
export type MapBounds = {
  sw: MapLatlon;
  ne: MapLatlon;
};

// エリアマーカーデータ
type MarkerArea = {
  pref: MarkerProps[];
  city: MarkerProps[];
  ward: MarkerProps[];
};

// アラートデータ
export type WarningData = {
  warningId: string;
  title: string;
  date: string;
  warningNumber: number;
  info: string;
  usage: number | null | undefined;
  sortKey: string;
};

// アラートデータ種類別
export type WarningDataByType = {
  dirt: WarningData[];
  repair: WarningData[];
  warning: WarningData[];
  wash: WarningData[];
};

// ランキングデータ
export type RankingData = {
  name: string;
  num: number;
  icon: boolean;
  sortKey: string;
};

// ランキングデータ全体
export type Ranking = {
  UseAsc: RankingData[];
  UseDesc: RankingData[];
  WashAsc: RankingData[];
  WashDesc: RankingData[];
};

// トイレ全体データ
export type AllRestroomDatas = {
  Use: {
    male: number;
    female: number;
    multi: number;
  };
  Wash: {
    male: {
      all: number;
      wash: number;
    };
    female: {
      all: number;
      wash: number;
    };
    multi: {
      all: number;
      wash: number;
    };
  };
};

/**
 * メインCSS
 */
const cssMain = css`
  width: 100%;
  height: 100%;

  padding-bottom: 3px;

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    right: 50px !important;
  }

  .map-view-area {
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 360px;
  }

  .map-option-buttons {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 26px;
    color: #666;
    cursor: pointer;
    background-color: #fff;

    .map-option-buttons-inner {
      margin: 0;
    }
  }

  .map-option-buttons:hover {
    color: #333;
  }

  .current-location {
    right: 20px;
    bottom: 115px;
  }
`;

/**
 * 全体ウィンドウCSS
 */
const AllWindowBackground = 'rgba(2, 29, 60, 0.7)';
const AllWindowFrameLen = '10px';
const AllWindowLeftWidth = '370px';
const cssAllWindow = css`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  pointer-events: none;

  .allwindow-content {
    display: table;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .allwindow-left {
    display: table-cell;
    width: ${AllWindowLeftWidth};
    height: 100%;
    background: ${AllWindowBackground};
  }

  .allwindow-right {
    position: relative;
    display: table-cell;
    height: 100%;
    border: solid 10px ${AllWindowBackground};
    border-width: 10px 10px 3px 0;

    img {
      position: absolute;
      opacity: 0.7;
    }

    .logo {
      top: 5px;
      right: 20px;
      width: 165px;
      pointer-events: auto;
      cursor: pointer;
      opacity: 1;
    }

    .logo-frame {
      right: 0;
      width: 300px;
    }

    .corner {
      width: 15px;
    }

    .corner.bottom-left {
      bottom: 0;
      transform: scale(1, -1);
    }

    .corner.bottom-right {
      right: 0;
      bottom: 0;
      transform: scale(-1, -1);
    }

    .search-menus {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;
      width: calc(100% - 300px);
    }

    .timer {
      display: flex;
      flex-direction: row;
      padding: 0 20px;
      margin: 0;
      list-style: none;
      pointer-events: auto;
      user-select: none;
      background: rgba(2, 29, 60, 0.8);
      border-radius: 5px;

      span {
        font-family: 'DSEG7ModernMini-Regular', sans-serif;
        font-size: 15px;
        line-height: 2em;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
      }

      li + li {
        padding-left: 10px;
      }

      .large-icon {
        font-size: 13px;
      }

      .small-icon {
        font-size: 17px;
      }

      .disabled {
        color: #8a8d92;
        opacity: 0.1;
      }

      .no-link-cursor {
        cursor: auto !important;
      }
    }
  }
`;

/**
 * 利用状況
 */
const cssAllWindowStatus = css`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;

  ul {
    padding: 0;
    margin: 0;
  }

  .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${AllWindowFrameLen} 0;

    .row {
      flex-grow: 1;
    }

    .title {
      height: 120px;

      img {
        width: 100%;
        pointer-events: auto;
        cursor: pointer;
        user-select: none;
      }

      span {
        position: absolute;
        line-height: 19px;
        color: #fff;
        text-align: center;
      }

      .text {
        left: 132px;
        width: 185px;
      }

      .title {
        top: 63px;
        font-size: 17px;
        text-align: center;
      }

      .info {
        top: calc(63px + 2.5em);
        font-size: 11px;
      }
    }
  }
`;

const cssRanking = css`
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  color: #fff;
  pointer-events: auto;
  user-select: none;

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 5px;

    h3 {
      font-weight: 600;
    }

    .list {
      position: relative;
      display: table;
      width: 100%;
      border-collapse: separate;

      ul {
        display: table-row;

        li {
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          background: rgba(0, 0, 0, 0.3);
        }

        li + li {
          padding-left: 5px;
        }

        span {
          font-size: 12px;
          font-weight: bold;
        }

        .rank {
          width: 20px;
        }

        .num {
          width: 80px;
          padding-right: 5px;
          text-align: center;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 90%;
            -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
          }
        }

        .name {
          text-align: left;
        }
      }
    }
  }

  .back-icon {
    position: absolute;
    right: 5px;
    bottom: 5px;
    opacity: 0.8;
  }

  .user-desc {
    background: linear-gradient(to left top, #ff0b14, #ff4a4d);
  }

  .user-asc {
    background: linear-gradient(to left top, #00b4cc, #8acba9);
  }

  .wash-desc {
    background: linear-gradient(to left top, #0168cc, #00bae5);
  }

  .wash-asc {
    background: linear-gradient(to left top, #cd2218, #fc6349);
  }
`;

const cssTotalChart = css`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  user-select: none;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }

  .total-datas {
    display: table;
    width: 100%;
    height: 100%;
    padding: 10px 20px;

    .cell {
      display: table-cell;
    }

    .chart {
      vertical-align: middle;
    }

    .count {
      position: relative;
      width: 60%;
    }

    .square {
      position: relative;
      width: 100%;
      height: auto;

      &::before {
        display: block;
        padding-top: 100%;
        content: '';
      }

      .square-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .split-count {
    position: absolute;
    top: 60%;
    left: 50%;
    display: table;
    width: 80%;
    border-spacing: 7px;
    border-collapse: separate;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);

    ul {
      display: table-row;
    }

    li {
      position: relative;
      display: table-cell;
      text-align: center;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    .icon-size {
      width: 1em;
    }

    .num-bar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 1.6em;
      padding-right: 0.5em;
      clip-path: polygon(20px 0, 100% 0, 100% 100%, 6px 100%);
      background-color: rgb(59, 142, 227);
      border-radius: 11px;

      .text-min {
        padding: 0 2px;
        font-size: calc(1.5em / 2);
      }

      span {
        font-family: 'DSEG7ModernMini-Regular', sans-serif;
        font-size: 1.2em;
        color: #fff;
      }
    }
  }
`;

const cssAletList = css`
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  color: #fff;
  text-shadow: 0 0 4px #767676;
  pointer-events: auto;
  user-select: none;

  .alert-list-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5px 15px;
    border-radius: 5px;

    .scroll {
      position: relative;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }

    .list {
      position: absolute;
      top: 0;
      left: 0;
      display: table;
      width: 100%;
      height: 100%;
      border-spacing: 5px;
      border-collapse: separate;

      ul {
        display: table-row;
      }

      li {
        display: table-cell;
      }

      .alert-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60%;
        transform: translateY(-50%) translateX(-50%);
      }

      .info {
        padding-left: 1em;
        font-size: 11px;
        line-height: 14px;

        .number {
          position: relative;
          display: inline-block;
          width: 3em;
          height: 14px;
          margin-right: 13px;
          background-color: #fff;

          &::after {
            position: absolute;
            top: 0;
            right: -7px;
            z-index: 10;
            width: 0;
            height: 0;
            margin: auto;
            content: '';
            border-color: transparent transparent transparent #fff;
            border-style: solid;
            border-width: 7px 0 7px 7px;
          }

          .warning-number {
            position: absolute;
            padding-left: 4px;
            font-size: 13px;
            font-weight: bold;
            line-height: 14px;
            text-shadow: none;
          }
        }
      }
    }

    h3 {
      width: 100%;
      margin-bottom: 4px;
      font-size: 11px;
    }

    h4 {
      margin: 0;
    }

    .back-icon {
      position: absolute;
      right: 5px;
      bottom: 5px;
      width: auto;
      height: 75%;
      max-height: 350px;
      opacity: 0.8;
    }

    .usage-icon {
      position: absolute;
      height: 15px;
      padding-left: 1em;
    }

    /* 手洗い率 */
    .wash {
      background-color: #f08c01;
    }

    .wash-text {
      color: #f08c01;
    }

    .wash-info {
      background: linear-gradient(90deg, #f08c01, rgba(240, 140, 1, 0.5));
    }

    .scroll.scroll-wash {
      ::-webkit-scrollbar-thumb {
        background: #f08c01;
      }
    }

    /* 修理 */
    .repair {
      background-color: ${alertColor.repair};
    }

    .repair-text {
      color: ${alertColor.repair};
    }

    .repair-info {
      background: linear-gradient(90deg, #ff7e02, rgba(255, 126, 2, 0.5));
    }

    .scroll.scroll-repair {
      ::-webkit-scrollbar-thumb {
        background: #ff7e02;
      }
    }

    /* 汚れ */
    .dirt {
      background-color: ${alertColor.dirt};
    }

    .dirt-text {
      color: ${alertColor.dirt};
    }

    .dirt-info {
      background: linear-gradient(90deg, #7512a5, rgba(117, 18, 165, 0.5));
    }

    .scroll.scroll-dirt {
      ::-webkit-scrollbar-thumb {
        background: #7512a5;
      }
    }

    /* 防犯 */
    .security {
      background-color: ${alertColor.warning};
    }

    .security-text {
      color: ${alertColor.warning};
    }

    .security-info {
      background: linear-gradient(90deg, #c1bf02, rgba(193, 191, 2, 0.5));
    }

    .scroll.scroll-security {
      ::-webkit-scrollbar-thumb {
        background: #c1bf02;
      }
    }
  }

  .wash-back {
    background: linear-gradient(to left top, #e9e307, #eae05e);
  }

  .repair-back {
    background: linear-gradient(to left top, #ff921a, #ffc246);
  }

  .dirt-back {
    background: linear-gradient(to left top, #77138a, #bd51f0);
  }

  .security-back {
    background: linear-gradient(to left top, #effe28, #effe26);
  }
`;

const cssWashPercent = css`
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  color: #fff;
  pointer-events: auto;
  user-select: none;

  .content {
    width: 100%;
    height: 100%;
    padding: 5px 10px 5px 10px;
    background: linear-gradient(172deg, rgba(18, 49, 81, 0.7), rgba(19, 70, 157, 0.7));
    border: solid 1px #9ddee1;
    border-radius: 5px;
  }

  h3 {
    height: 15px;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 100;
  }

  .column-parent {
    display: flex;
    flex-direction: row;
    height: calc(100% - 24px);

    .graph {
      width: 70px;
      padding-top: 5px;
    }

    .percent {
      flex-grow: 1;
      padding-left: 10px;
      font-family: 'DSEG7ModernMini-Regular', sans-serif;
      font-size: 10px;
      text-align: center;

      .table {
        display: table;
        width: 100%;
        border-collapse: collapse;

        ul {
          display: table-row;
          border: solid 1px #9ddee1;
        }

        li {
          display: table-cell;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 18px;
          transform: translateY(-50%) translateX(-50%);
        }

        .total {
          font-family: initial;
          font-weight: bold;
          color: #000;
          background-color: #9ddee1;

          img {
            object-fit: scale-down;
          }
        }

        .title-len {
          position: relative;
          width: 55px;
        }
      }

      .total-line {
        width: 100%;
        height: 12px;
        line-height: 10px;
        color: #9ddee1;
        text-align: center;
      }
    }
  }
`;

const cssRestroomSearch = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  height: 30px;
  padding: 0 5px;
  pointer-events: auto;
  background: rgba(2, 29, 60, 0.8);
  border-radius: 5px;

  button {
    padding: 0;
    color: inherit;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    appearance: none;
  }

  .icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #fff;
    cursor: pointer;
  }

  select {
    color: #fff;
    background: transparent;
    border: none;
    outline: none;
  }

  select option {
    background: rgba(2, 29, 60, 0.8);
  }
`;

/**
 * 合計グラフ
 *
 * @param {number} female 女性用回数
 * @param {number} multi 多目的回数
 * @param {number} male 男性用回数
 */
type TotalChartProps = {
  female: number;
  multi: number;
  male: number;
};
const TotalChart: FC<TotalChartProps> = ({ female, multi, male }) => {
  const datas = [
    { key: 'male', backColor: GraphColor.male, count: male },
    { key: 'female', backColor: GraphColor.female, count: female },
    { key: 'multi', backColor: GraphColor.multi, count: multi },
  ];

  return (
    <section css={cssTotalChart}>
      <ul className="total-datas">
        <li className="cell chart">
          <article className="square">
            <div className="square-inner">
              <Marker FixFontSize="20px" pieData={[female, multi, male]} isDetail={false} />
            </div>
          </article>
        </li>
        <li className="cell count">
          <article className="split-count">
            {datas.map((item, index) => {
              return (
                <ul key={`TotalChart_${item.key}`}>
                  <li className="icon-size">
                    <img className="icon-size" alt="icon" src={usageIcon[index + 1]} />
                  </li>
                  <li>
                    <div className="num-bar" style={{ backgroundColor: `${item.backColor}` }}>
                      <span>
                        {item.count}
                        <span className="text-min">人</span>
                      </span>
                    </div>
                  </li>
                </ul>
              );
            })}
          </article>
        </li>
      </ul>
    </section>
  );
};

/**
 * ランキング
 *
 * @param {array} datas       表示データ
 * @param {RankingType} type  ランキング種別
 * @param {boolean} isIcon    アイコンの表示有無
 */
type RankingType = 'userDesc' | 'userAsc' | 'washDesc' | 'washAsc';
const RankingTypeParams = {
  userDesc: {
    image: bgRankingUserDesc,
    classname: 'user-desc',
    title: '利用者数の多いトイレ',
  },
  userAsc: {
    image: bgRankingUserAsc,
    classname: 'user-asc',
    title: '利用者数の少ないトイレ',
  },
  washDesc: {
    image: bgRankingWashDesc,
    classname: 'wash-desc',
    title: 'ベストトイレ [ 高手洗い率 ]',
  },
  washAsc: {
    image: bgRankingWashAsc,
    classname: 'wash-asc',
    title: 'ワーストトイレ [ 低手洗い率 ]',
  },
};
type RankingProps = {
  datas: {
    name: string;
    num: number;
    icon: boolean;
  }[];
  type: RankingType;
  unit?: string;
  isMin?: boolean;
};
const Ranking: FC<RankingProps> = ({ datas, type, unit, isMin = false }) => {
  const { image, classname, title } = RankingTypeParams[type];

  const cssRankingUpdate = css`
    ${cssRanking}
    ${isMin
      ? `
      .list {
        border-spacing: 2px;
      }
      .inner {
        padding: 5px 15px;
        h3 {
          margin-bottom: 4px;
          font-size: 11px;
        }
      }
      .back-icon {
        width: 33%;
      }
    `
      : `
      .list {
        border-spacing: 5px;
      }
      .inner {
        padding: 10px 15px;
        h3 {
          margin-bottom: 5px;
          font-size: 13px;
        }
      }
      .back-icon {
        width: 40%;
      }
    `}
  `;

  return (
    <section css={cssRankingUpdate}>
      <div className={`inner ${classname}`}>
        <h3>{title}</h3>
        <img className="back-icon" alt="back" src={image} />
        <div className="list">
          {datas.map((data, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <ul key={`ranking_${type}_${index}`}>
                <li className="rank">
                  <span>{index + 1}</span>
                </li>
                <li className="name">
                  <span style={{ fontWeight: 'normal' }}>{data.name}</span>
                </li>
                <li className="num">
                  <span>
                    {data.num}
                    {unit ?? ''}
                  </span>
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    </section>
  );
};

/**
 * アラート一覧
 *
 */
type AlertListType = 'wash' | 'repair' | 'dirt' | 'security';
type AlertListProps = {
  type: AlertListType;
  datas?: WarningData[];
};
const AlertList: FC<AlertListProps> = ({ type, datas }) => {
  const unmounted = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [scrollList, setScrollList] = useState<WarningData[]>([]);
  const LoadCount = 20;
  const [isFetchList, setIsFetchList] = useState(false);

  // スクロールデータ読み込み
  const LoadMore = (index: number) => {
    if (isFetchList) {
      return;
    }
    if (datas === undefined) {
      return;
    }
    if (!unmounted.current) {
      setIsFetchList(true);
    }
    const start = (index - 1) * LoadCount;
    const end = start + LoadCount;

    if (!unmounted.current) {
      setScrollList(datas.slice(0, end));
    }
  };

  // アラート一覧のタイトル取得
  const GetTitle = () => {
    switch (type) {
      case 'repair':
        return '故障最新メッセージ 一覧';
      case 'dirt':
        return '汚れ最新メッセージ 一覧';
      case 'security':
        return '防犯最新メッセージ 一覧';
      default:
        return '最新アラート一覧 [ 手洗器 ]';
    }
  };

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  // 無限スクロールに表示するデータがあるかどうか
  useEffect(() => {
    if (datas === undefined) {
      return;
    }
    if (scrollList.length > 0) {
      if (!unmounted.current) {
        setIsFetchList(scrollList.length >= datas.length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollList]);

  // 元データ変更時
  useEffect(() => {
    if (datas === undefined) {
      return;
    }

    if (isLoading) {
      if (!unmounted.current) {
        setIsLoading(false);
      }
    }

    if (datas.length <= 0) {
      if (!unmounted.current) {
        setScrollList([]);
      }
    } else if (!unmounted.current) {
      setScrollList(datas.slice(0, LoadCount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas]);

  return (
    <section css={cssAletList}>
      <div className={`alert-list-inner ${type}-back`}>
        <h3>{GetTitle()}</h3>
        {type === 'wash' && <img className="back-icon" alt="icon" src={bgAlertWash} />}
        {isLoading ? (
          <div className={`scroll scroll-${type}`}>
            <Loader active size="large" />
          </div>
        ) : (
          <div className={`scroll scroll-${type}`}>
            <InfiniteScroll
              pageStart={0}
              loadMore={LoadMore}
              hasMore={!isFetchList}
              useWindow={false}
              loader={<div key={0}>loading</div>}
              className="list"
            >
              {scrollList.map((item) => {
                const listKey = `alert_list_${type}_${item.date}_${item.warningId}`;

                return (
                  <ul key={listKey} style={{ height: '50px' }}>
                    <li style={{ width: '50px', position: 'relative' }} className={type}>
                      <img src={GetWarningIconByTopList(item.warningNumber)} alt="icon" className="alert-icon" />
                    </li>
                    <li className={`info ${type}-info`}>
                      <h4>{item.title}</h4>
                      <span>
                        {item.date}
                        {item.usage && <img className="usage-icon" alt="icon" src={usageIconWhite[item.usage]} />}
                      </span>
                      <br />
                      <span>
                        <div className="number">
                          <span className={`warning-number ${type}-text`}>{item.warningNumber}</span>
                        </div>
                        {item.info}
                      </span>
                    </li>
                  </ul>
                );
              })}
              <ul>
                <li />
              </ul>
            </InfiniteScroll>
          </div>
        )}
      </div>
    </section>
  );
};

/**
 * 表示中手洗い率
 *
 * @param {AllRestroomDatas} allRestroomDatas トイレ全体データ
 */
type WashPercentData = {
  count: number;
  total: number;
  usage?: Usage;
};
type WashPercentProps = {
  allRestroomDatas?: AllRestroomDatas;
};
const WashPercent: FC<WashPercentProps> = ({ allRestroomDatas }) => {
  const unmounted = useRef(false);
  const [datas, setDatas] = useState<WashPercentData[]>([]);
  const [perDatas, setPerDatas] = useState<number[]>([0, 0, 0]);

  const totalData = ((): WashPercentData => {
    let count = 0;
    let total = 0;
    for (let i = 0; i < datas.length; i += 1) {
      count += datas[i].count;
      total += datas[i].total;
    }

    return { count, total };
  })();

  const getPercent = (count: number, total: number): number => {
    if (total === 0) {
      return 0;
    }

    return Math.floor((count / total) * 100);
  };

  useEffect(() => {
    if (allRestroomDatas === undefined || allRestroomDatas === null) {
      return;
    }

    const newDatas: WashPercentData[] = [
      { count: allRestroomDatas.Wash.male.wash, total: allRestroomDatas.Wash.male.all, usage: 1 },
      { count: allRestroomDatas.Wash.female.wash, total: allRestroomDatas.Wash.female.all, usage: 2 },
      { count: allRestroomDatas.Wash.multi.wash, total: allRestroomDatas.Wash.multi.all, usage: 3 },
    ];
    if (!unmounted.current) {
      setDatas(newDatas);
    }
    if (!unmounted.current) {
      setPerDatas([
        getPercent(newDatas[0].count, newDatas[0].total),
        getPercent(newDatas[1].count, newDatas[1].total),
        getPercent(newDatas[2].count, newDatas[2].total),
      ]);
    }
  }, [allRestroomDatas]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <section css={cssWashPercent}>
      <div className="content">
        <h3>表示中トイレ手洗い率</h3>
        <div className="column-parent">
          {/* グラフ */}
          <article className="graph">
            <ToiletGraph Percent={{ Male: perDatas[0], Female: perDatas[1], Multi: perDatas[2] }} IsIcons />
          </article>
          {/* 数値 */}
          <article className="percent">
            <div className="table">
              <ul style={{ fontSize: '13px' }}>
                <li className="total title-len">
                  <img alt="icon" src={iconTotalTrans} />
                </li>
                <li>{getPercent(totalData.count, totalData.total)}%</li>
                <li />
                <li>{totalData.count}人</li>
                <li>/</li>
                <li>{totalData.total}人</li>
              </ul>
            </div>
            <div className="total-line">▲</div>
            <div className="table">
              {datas.map((item) => {
                const usage = item.usage ? item.usage : 1;

                return (
                  <ul key={`WashPercent_list_${usage}`}>
                    <li className="title-len">
                      <div className={`title_icon${usage}`} />
                      <img alt="icon" src={usageIcon[usage]} />
                    </li>
                    <li>{getPercent(item.count, item.total)}%</li>
                    <li />
                    <li>{item.count}人</li>
                    <li>/</li>
                    <li>{item.total}人</li>
                  </ul>
                );
              })}
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

/**
 * メンテナンス
 *
 * @param {WarningDataByType} warningByType アラートデータ種類別
 */
type MaintenanceProps = {
  warningByType?: WarningDataByType;
};
const Maintenance: FC<MaintenanceProps> = ({ warningByType }) => {
  return (
    <section css={cssAllWindowStatus}>
      <ul className="inner">
        <li className="title">
          <Link to={{ pathname: '/wash' }}>
            <img alt="usage status" src={titleMaintenance} />
            <span className="text title" style={{ fontSize: '16px' }}>
              防犯・メンテナンス管理
            </span>
            <span className="text info">トイレの異常および故障の検知</span>
          </Link>
        </li>
        {/* 故障 */}
        <li className="row">
          <AlertList type="repair" datas={warningByType?.repair} />
        </li>
        {/* 防犯 */}
        <li className="row">
          <AlertList type="security" datas={warningByType?.warning} />
        </li>
        {/* 汚れ */}
        <li className="row">
          <AlertList type="dirt" datas={warningByType?.dirt} />
        </li>
        <li style={{ height: '10px' }} />
      </ul>
    </section>
  );
};

/**
 * 手洗い状況
 *
 * @param {AllRestroomDatas} allRestroomDatas トイレ全体データ
 * @param {Ranking} ranking ランキングデータ
 * @param {WarningDataByType} warningByType アラートデータ種類別
 */
type WashStatusProps = {
  allRestroomDatas?: AllRestroomDatas;
  ranking: Ranking;
  warningByType?: WarningDataByType;
};
const WashStatus: FC<WashStatusProps> = ({ allRestroomDatas, ranking, warningByType }) => {
  return (
    <section css={cssAllWindowStatus}>
      <ul className="inner">
        <li className="title">
          <Link to={{ pathname: '/' }}>
            <img alt="usage status" src={titleWashStatus} />
            <span className="text title">手洗い状況管理</span>
            <span className="text info">トイレ利用後の手洗い率評価</span>
          </Link>
        </li>
        {/* 天気 */}
        <li>
          <WeatherFolderContainer />
        </li>
        {/* 手洗い率 */}
        <li style={{ height: '145px' }}>
          <WashPercent allRestroomDatas={allRestroomDatas} />
        </li>
        {/* ランキング */}
        <li style={{ height: '155px' }}>
          <Ranking datas={ranking.WashDesc} type="washDesc" unit="%" isMin />
        </li>
        <li style={{ height: '155px' }}>
          <Ranking datas={ranking.WashAsc} type="washAsc" unit="%" isMin />
        </li>
        {/* アラート */}
        <li className="row" style={{ maxHeight: '400px' }}>
          <AlertList type="wash" datas={warningByType?.wash} />
        </li>
        <li style={{ height: '10px' }} />
      </ul>
    </section>
  );
};

/**
 * 利用状況
 *
 * @param {AllRestroomDatas} allRestroomDatas トイレ全体データ
 * @param {Ranking} ranking ランキングデータ
 */
type UsageStatusPorps = {
  allRestroomDatas?: AllRestroomDatas;
  ranking: Ranking;
};
const UsageStatus: FC<UsageStatusPorps> = ({ allRestroomDatas, ranking }) => {
  return (
    <section css={cssAllWindowStatus}>
      <ul className="inner">
        <li className="title">
          <Link to={{ pathname: '/maintenance' }}>
            <img alt="usage status" src={titleUsageStatus} />
            <span className="text title">トイレ利用状況</span>
            <span className="text info">トイレの混雑状況や換気の必要性</span>
          </Link>
        </li>
        <li>
          <WeatherFolderContainer />
        </li>
        <li style={{ height: '155px' }}>
          {allRestroomDatas && (
            <TotalChart
              female={allRestroomDatas.Use.female}
              male={allRestroomDatas.Use.male}
              multi={allRestroomDatas.Use.multi}
            />
          )}
        </li>
        <li style={{ height: '185px' }}>
          <Ranking datas={ranking.UseDesc} type="userDesc" unit="人" />
        </li>
        <li style={{ height: '185px' }}>
          <Ranking datas={ranking.UseAsc} type="userAsc" unit="人" />
        </li>
        <li className="row" style={{ minHeight: '30px' }} />
      </ul>
    </section>
  );
};

/**
 * トイレ検索
 *
 * @param {RestroomItems} restrooms トイレデータ
 * @param {function} setMapCenter 地図の中央座標更新
 */
type RestroomSearchSelectItem = {
  key: string;
  value: string;
  text: string;
};
type RestroomSearchProps = {
  restrooms?: RestroomItems[];
  setMapCenter: (latlon: MapLatlon) => void;
};
const RestroomSearch: FC<RestroomSearchProps> = ({ restrooms, setMapCenter }) => {
  const [selectedText, setSelectedText] = useState<string>('選択してください');
  const [selectedValue] = useState<string>(
    (() => {
      const newValue = localStorage.getItem(SAVEKEY_RESTROOM_SELECTED_VALUE);
      if (!newValue) {
        return '';
      }

      return newValue;
    })(),
  );
  const [items, setItems] = useState<RestroomSearchSelectItem[]>([]);
  const [view, setView] = useState<boolean>((): boolean => {
    const data = localStorage.getItem(SAVEKEY_RESTROOM_SEARCH_VIEW);
    if (!data) {
      return true;
    }

    return data === '1';
  });
  const unmounted = useRef(false);

  // アイコンクリック
  const handleClickIcon = () => {
    const newView = !view;
    if (!unmounted.current) {
      setView(newView);
      localStorage.setItem(SAVEKEY_RESTROOM_SEARCH_VIEW, newView ? '1' : '0');
    }
  };

  // 選択
  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem(SAVEKEY_RESTROOM_SELECTED_VALUE, e.target.value);
    const latlon = e.target.value.split('_');
    if (!unmounted.current) {
      setMapCenter({
        lat: Number(latlon[0]),
        lon: Number(latlon[1]),
      });
      setSelectedText(e.target.options[e.currentTarget.selectedIndex].text);
    }
    e.currentTarget.selectedIndex = 0;
  };

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (!restrooms) {
      return;
    }

    const newItems: RestroomSearchSelectItem[] = [];

    restrooms.forEach((item) => {
      newItems.push({
        key: `restroomsearch_item_${item.restroomId}`,
        value: `${item.location.lat}_${item.location.lon}`,
        text: item.name,
      });
    });

    if (!unmounted.current) {
      setItems(newItems);
    }

    if (selectedValue !== '') {
      for (let i = 0; i < newItems.length; i += 1) {
        if (selectedValue === newItems[i].value) {
          if (!unmounted.current) {
            setSelectedText(newItems[i].text);
            break;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrooms]);

  if (items.length <= 0) {
    return <div />;
  }

  return (
    <section css={cssRestroomSearch}>
      <button type="button" className="icon" onClick={handleClickIcon}>
        <Icon name="search" />
      </button>
      <select style={{ display: view ? 'inline' : 'none' }} onChange={handleChangeSelect} defaultValue="">
        <option key="restroomsearch_item_initselected" disabled style={{ display: 'none' }} value="">
          {selectedText}
        </option>
        {items.map((item) => {
          return (
            <option key={item.key} value={item.value}>
              {item.text}
            </option>
          );
        })}
      </select>
    </section>
  );
};

/**
 * 全体ウィンドウ
 *
 * @param {TopType} view TOP画面表示種別
 * @param {AllRestroomDatas} allRestroomDatas トイレ全体データ
 * @param {Ranking} ranking ランキングデータ
 * @param {WarningDataByType} warningByType アラートデータ種類別
 * @param {RestroomItems} restrooms トイレデータ
 * @param {string} toDate 検索日付to
 * @param {function} setToDate 検索日付to設定
 * @param {function} setMapCenter 地図の中央座標更新
 */
type AllWindowProps = {
  view: TopType;
  allRestroomDatas?: AllRestroomDatas;
  ranking: Ranking;
  warningByType?: WarningDataByType;
  restrooms?: RestroomItems[];
  toDate: string;
  setToDate: (newToDate: string) => void;
  setMapCenter: (latlon: MapLatlon) => void;
};
const AllWindow: FC<AllWindowProps> = ({
  view,
  allRestroomDatas,
  ranking,
  warningByType,
  restrooms,
  toDate,
  setToDate,
  setMapCenter,
}) => {
  const refMenu = useRef({} as MenuHandler);
  // 日付セット
  const setDate = (flag?: string) => {
    let newToDate = dayjs().subtract(1, 'hours').format('YYYY-MM-DD HH:00:00');
    switch (flag) {
      case 'next':
        newToDate = dayjs(toDate).add(1, 'day').format('YYYY-MM-DD HH:00:00');
        break;
      case 'next_week':
        newToDate = dayjs(toDate).add(7, 'day').format('YYYY-MM-DD HH:00:00');
        break;
      case 'next_month':
        newToDate = dayjs(toDate).add(1, 'month').format('YYYY-MM-DD HH:00:00');
        break;
      case 'prev':
        newToDate = dayjs(toDate).subtract(1, 'day').format('YYYY-MM-DD HH:00:00');
        break;
      case 'prev_week':
        newToDate = dayjs(toDate).subtract(7, 'day').format('YYYY-MM-DD HH:00:00');
        break;
      case 'prev_month':
        newToDate = dayjs(toDate).subtract(1, 'month').format('YYYY-MM-DD HH:00:00');
        break;
      default:
        break;
    }
    setToDate(newToDate);
  };

  // ロゴクリック
  const handleLogoClick = () => {
    if (refMenu !== undefined) {
      refMenu.current.setIsView();
    }
  };

  // 日付変更ボタン
  const DateChangeButton = (iconName: SemanticICONS, flag: string, is: boolean, classes?: string) => {
    return (
      <li
        className={`${!is && 'no-link-cursor'}`}
        onClick={() => {
          if (is) {
            setDate(flag);
          }
        }}
      >
        <Icon name={iconName} size="small" className={`${classes} ${!is && 'disabled'}`} />
      </li>
    );
  };

  if (restrooms === undefined) {
    return <div />;
  }

  const timerColor =
    parseInt(dayjs(toDate).add(1, 'day').format('YYYYMMDDHH'), 10) <= parseInt(dayjs().format('YYYYMMDDHH'), 10)
      ? TIMER_COLOR
      : TIMER_COLOR_NOW;

  return (
    <React.Fragment>
      <Menu ref={refMenu} />
      <section css={cssAllWindow}>
        <ul className="allwindow-content">
          {/* 左側 */}
          <li className="allwindow-left">
            {(() => {
              switch (view) {
                case 'wash':
                  return (
                    <WashStatus allRestroomDatas={allRestroomDatas} ranking={ranking} warningByType={warningByType} />
                  );
                case 'maintenance':
                  return <Maintenance warningByType={warningByType} />;
                default:
                  return <UsageStatus allRestroomDatas={allRestroomDatas} ranking={ranking} />;
              }
            })()}
          </li>
          {/* 右側 */}
          <li className="allwindow-right">
            <div className="search-menus">
              {/* 日付変更タイマー */}
              <ul className="timer" style={{ color: `${timerColor}` }}>
                {DateChangeButton('backward', 'prev_week', true, 'large-icon')}
                {DateChangeButton('caret left', 'prev', true, 'small-icon')}
                {(() => {
                  const srcClock = dayjs(toDate);
                  const fromClock = GetFromDatetimeByTop(toDate);
                  const clockToDate = dayjs(srcClock).format('MM/DD');
                  const clockToTime = dayjs(srcClock).format('HH:mm');
                  const clockFromDate = dayjs(fromClock).format('MM/DD');
                  const clockFromTime = dayjs(fromClock).format('HH:mm');
                  const is = dayjs(toDate).format('YYYYMMDDHH') !== dayjs().format('YYYYMMDDHH');

                  return (
                    <li
                      className={`${!is && 'no-link-cursor'}`}
                      onClick={() => {
                        if (is) {
                          setDate();
                        }
                      }}
                    >
                      <div style={{ textShadow: `0 0 10px ${timerColor}, 0 0 15px ${timerColor}` }}>
                        <span>{`${clockFromDate}`}</span>
                        <span style={{ paddingLeft: '12px' }}>{`${clockFromTime}`}</span>
                        <span style={{ padding: '0px 10px 0 14px' }}>～</span>
                        <span>{`${clockToDate}`}</span>
                        <span style={{ paddingLeft: '12px' }}>{`${clockToTime}`}</span>
                      </div>
                    </li>
                  );
                })()}
                {DateChangeButton(
                  'caret right',
                  'next',
                  parseInt(dayjs(toDate).add(1, 'day').format('YYYYMMDDHH'), 10) <=
                    parseInt(dayjs().format('YYYYMMDDHH'), 10),
                  'small-icon',
                )}
                {DateChangeButton(
                  'forward',
                  'next_week',
                  parseInt(dayjs(toDate).add(1, 'week').format('YYYYMMDDHH'), 10) <=
                    parseInt(dayjs().format('YYYYMMDDHH'), 10),
                  'large-icon',
                )}
              </ul>
              {/* トイレサーチ */}
              <RestroomSearch setMapCenter={setMapCenter} restrooms={restrooms} />
              <div style={{ flexGrow: 1 }} />
            </div>
            {/* 角丸とロゴの背景は画像 */}
            <img src={partsCorner} className="corner" alt="topleft" />
            <img className="corner bottom-left" src={partsCorner} alt="bottomleft" />
            <img className="corner bottom-right" src={partsCorner} alt="bottomright" />
            <img src={partsLogoFrame} className="logo-frame" alt="logo-frame" />
            <img src={clipLogoWhiteImg} onClick={handleLogoClick} className="logo" alt="Clip" />
          </li>
        </ul>
      </section>
    </React.Fragment>
  );
};

/**
 * TOP画面
 *
 * @param {MapInitData} mapDefaultOption 地図データのオプション
 * @param {RestroomItems} restrooms トイレデータ
 * @param {function} getRestroomsList トイレデータ取得処理（全データ）
 * @param {AllRestroomDatas} allRestroomDatas トイレ全体データ
 * @param {Ranking} ranking ランキングデータ
 * @param {WarningDataByType} warningByType アラートデータ種類別
 * @param {function} GetSubtotalsByRestroomIds トイレIDの配列からSubtotalデータ取得
 * @param {function} GetWarningIconByRestroomIds トイレIDの配列からWarningアイコン取得
 * @param {string} toDate 検索日付to
 * @param {function} setToDate 検索日付to設定
 * @param {AllAreaDatas} allAreaDatas エリアデータ
 * @param {UserContextType} auth 認証データ
 */
type TopProps = {
  mapDefaultOption: MapInitData;
  restrooms?: RestroomItems[];
  // getRestrooms: (lat: number, lon: number, distance?: number) => Promise<void>;
  getRestroomsList: () => Promise<void>;
  allRestroomDatas?: AllRestroomDatas;
  ranking: Ranking;
  warningByType?: WarningDataByType;
  GetSubtotalsByRestroomIds?: (restroomIds: string[]) => Promise<CreateClipHgSubtotalInput[]>;
  GetWarningIconByRestroomIds?: (restroomIds: string[]) => Promise<AlertIconType>;
  toDate: string;
  setToDate: (newToDate: string) => void;
  allAreaDatas: AllAreaDatas;
  auth: UserContextType;
};
export const Top: FC<TopProps> = ({
  mapDefaultOption,
  getRestroomsList,
  restrooms,
  allRestroomDatas,
  ranking,
  warningByType,
  GetSubtotalsByRestroomIds,
  GetWarningIconByRestroomIds,
  toDate,
  setToDate,
  allAreaDatas,
  auth,
}) => {
  const unmounted = useRef(false);
  const [markersRestroom, setMarkersRestroom] = useState<MarkerProps[]>([]);
  const restroom = useRestroom();
  const topType = useTopType();
  const intervalId = useRef<NodeJS.Timeout | number>(0);
  const { view } = useParams<{ view: TopType }>();
  const [markerArea, setMarkerArea] = useState<MarkerArea>({ pref: [], city: [], ward: [] });
  const [mapBounds, setMapBounds] = useState<MapBounds>();
  const [mapCenter, setMapCenter] = useState<MapLatlon>(
    (() => {
      let newMapCenter = mapDefaultOption as MapLatlon;
      const item = localStorage.getItem(SAVEKEY_MAP_CENTER);
      if (item) {
        try {
          const json = JSON.parse(item) as MapLatlon;

          // データチェック
          if (json.lat && json.lon) {
            const pattern = /^-?[0-9]+\.[0-9]+$/;
            if (pattern.test(json.lat.toString()) && pattern.test(json.lon.toString())) {
              newMapCenter = json;
            }
          }
        } catch {
          // 何もせずデフォルト値のままで返す
        }
      }

      return newMapCenter;
    })(),
  );
  const [mapZoomLevel, setMapZoomLevel] = useState<number>(
    (() => {
      let newMapZoomLevel = mapDefaultOption.zoom;
      const item = localStorage.getItem(SAVEKEY_MAP_ZOOM);
      if (item) {
        const pattern = /^[0-9]+$/;
        if (pattern.test(item)) {
          newMapZoomLevel = Number(item);
        }
      }

      return newMapZoomLevel;
    })(),
  );
  const systemSetting = useSystemSettingOpen();
  const initSystemSetting = useRef(false);

  // 外部参照用useStateのset
  const setMapCenterWrapper = (newLatLon: MapLatlon) => {
    if (!unmounted.current) {
      setMapCenter(newLatLon);
    }
  };

  // 地図変更イベント
  const handleChange = (value: GoogleMapReact.ChangeEventValue) => {
    if (!unmounted.current && value.zoom) {
      setMapZoomLevel(value.zoom);
      localStorage.setItem(SAVEKEY_MAP_ZOOM, value.zoom.toString());
    }

    if (!unmounted.current && value.center) {
      const newMapCenter = {
        lat: value.center.lat,
        lon: value.center.lng,
      };
      setMapCenter(newMapCenter);
      localStorage.setItem(SAVEKEY_MAP_CENTER, JSON.stringify(newMapCenter));
    }

    if (!unmounted.current && value.bounds) {
      setMapBounds({
        sw: {
          lat: value.bounds.sw.lat,
          lon: value.bounds.sw.lng,
        },
        ne: {
          lat: value.bounds.ne.lat,
          lon: value.bounds.ne.lng,
        },
      });
    }
  };

  // 現在地ボタン
  const handleClickCurrentLocation = () => {
    if (!unmounted.current) {
      setMapZoomLevel(mapDefaultOption.zoom);
    }
    if (!unmounted.current) {
      setMapCenter({ lat: mapDefaultOption.lat, lon: mapDefaultOption.lon });
    }
  };

  // トイレマーカー
  const restroomMarker = (item: MarkerProps, IsView: boolean) => {
    return (
      <Marker
        {...{ lat: item.lat, lng: item.lng }}
        IsCaution={item.IsCaution}
        key={`marker_restroom_${item.restroom?.restroomId}`}
        onClick={item.onClick}
        restroom={item.restroom}
        IsMap
        IsBalloon
        IsToiletWindow
        IsTopType
        mapZoomLevel={mapZoomLevel}
        IsView={IsView}
        toDate={toDate}
        mapBounds={mapBounds}
        dispTwZoomLevel={mapDefaultOption.dispTwZoomLevel}
        isDetail={false}
      />
    );
  };

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const ReloadResroomsList = () => {
    const fn = () => void getRestroomsList();
    fn();

    clearInterval(intervalId.current as number);
    intervalId.current = setInterval(fn, AUTO_UPDATE_TIME);
  };

  useEffect(() => {
    if (!initSystemSetting.current) {
      initSystemSetting.current = true;

      return;
    }
    if (!systemSetting.isShow) {
      ReloadResroomsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemSetting.isShow]);

  useEffect(() => {
    ReloadResroomsList();

    return () => {
      clearInterval(intervalId.current as number);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  // 画面遷移時にTopType変更
  useEffect(() => {
    topType.setTopType(view === undefined ? 'usage' : view);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  // restroom更新処理
  useEffect(() => {
    if (restrooms === undefined) {
      return;
    }

    // トイレ単位
    const newMarkersRestroom = new Array<MarkerProps>();
    for (let i = 0; i < restrooms.length; i += 1) {
      const marker = {
        ...ConvertRestroomToMarker(restrooms[i]),
        ...{
          onClick: () => restroom.setRestroom(restrooms[i]),
        },
      };

      newMarkersRestroom.push(marker);
    }

    if (!unmounted.current) {
      setMarkersRestroom(newMarkersRestroom);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrooms]);

  // エリア更新
  useEffect(() => {
    const newMarkersArea: MarkerArea = {
      pref: [],
      city: [],
      ward: [],
    };

    const SetMarkerArea = (area: AreaDatas, returnArea: MarkerProps[]) => {
      Object.keys(area).forEach((key) => {
        returnArea.push({
          lat: area[key].lat,
          lng: area[key].lon,
          areaId: area[key].areaId,
          restroomIds: area[key].restrooms.map((item) => item.restroomId),
        });
      });
    };

    SetMarkerArea(allAreaDatas.pref, newMarkersArea.pref);
    SetMarkerArea(allAreaDatas.city, newMarkersArea.city);
    SetMarkerArea(allAreaDatas.ward, newMarkersArea.ward);

    if (!unmounted.current) {
      setMarkerArea(newMarkersArea);
    }
  }, [restrooms, allAreaDatas]);

  // ズームレベル
  const isZoomRestroom = mapZoomLevel > MarkerZoomLevel.ward;
  const isZoomWard = mapZoomLevel <= MarkerZoomLevel.ward && mapZoomLevel > MarkerZoomLevel.city;
  const isZoomCity = mapZoomLevel <= MarkerZoomLevel.city && mapZoomLevel > MarkerZoomLevel.pref;
  const isZoomPref = mapZoomLevel <= MarkerZoomLevel.pref;

  return (
    <section css={cssMain}>
      {/* 全体Window */}
      <AllWindow
        view={view}
        restrooms={restrooms}
        allRestroomDatas={allRestroomDatas}
        ranking={ranking}
        warningByType={warningByType}
        toDate={toDate}
        setToDate={setToDate}
        setMapCenter={setMapCenterWrapper}
      />
      <div className="map-view-area">
        {/* マップ */}
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${GOOGLEMAP_API_KEY}` }}
          defaultCenter={{ lat: mapDefaultOption.lat, lng: mapDefaultOption.lon }}
          defaultZoom={mapDefaultOption.zoom}
          center={{ lat: mapCenter.lat, lng: mapCenter.lon }}
          zoom={mapZoomLevel}
          onChange={handleChange}
          // onDragEnd={handleDragEnd}
          options={{
            clickableIcons: false,
            disableDoubleClickZoom: true,
            fullscreenControl: false,
            zoomControl: true,
            scaleControl: true,
            draggable: true,
            keyboardShortcuts: false,
            draggableCursor: 'auto',
          }}
          yesIWantToUseGoogleMapApiInternals
        >
          {/* トイレ単位マーカー */}
          {markersRestroom.map((item) => {
            // 通常表示
            if (isZoomRestroom) {
              return restroomMarker(item, isZoomRestroom);
            }
            // エリアに存在しないトイレを表示する
            if (isZoomWard) {
              if (allAreaDatas.wardRestroomIds.some((id) => id === item.restroom?.restroomId)) {
                return restroomMarker(item, isZoomWard);
              }
            }
            if (isZoomCity) {
              if (allAreaDatas.cityRestroomIds.some((id) => id === item.restroom?.restroomId)) {
                return restroomMarker(item, isZoomCity);
              }
            }
            if (isZoomPref) {
              if (allAreaDatas.prefRestroomIds.some((id) => id === item.restroom?.restroomId)) {
                return restroomMarker(item, isZoomPref);
              }
            }

            return '';
          })}
          {/* 行政区単位 */}
          {markerArea.ward.map((item) => (
            <Marker
              {...{ lat: item.lat, lng: item.lng }}
              IsCaution={item.IsCaution}
              key={`marker_ward_${item.areaId}`}
              onClick={item.onClick}
              restroom={item.restroom}
              IsMap
              IsBalloon
              IsToiletWindow={false}
              IsTopType
              mapZoomLevel={mapZoomLevel}
              IsLink={false}
              FixFontSize="20px"
              FixMarkerSize="150px"
              GetSubtotalsByRestroomIds={GetSubtotalsByRestroomIds}
              GetWarningIconByRestroomIds={GetWarningIconByRestroomIds}
              IsView={isZoomWard}
              toDate={toDate}
              restroomIds={item.restroomIds}
              areaId={item.areaId}
              mapBounds={mapBounds}
              isDetail={false}
            />
          ))}
          {/* 市・特別区単位 */}
          {markerArea.city.map((item) => (
            <Marker
              {...{ lat: item.lat, lng: item.lng }}
              IsCaution={item.IsCaution}
              key={`marker_city_${item.areaId}`}
              onClick={item.onClick}
              restroom={item.restroom}
              IsMap
              IsBalloon
              IsToiletWindow={false}
              IsTopType
              mapZoomLevel={mapZoomLevel}
              IsLink={false}
              FixFontSize="20px"
              FixMarkerSize="150px"
              GetSubtotalsByRestroomIds={GetSubtotalsByRestroomIds}
              GetWarningIconByRestroomIds={GetWarningIconByRestroomIds}
              IsView={isZoomCity}
              toDate={toDate}
              restroomIds={item.restroomIds}
              areaId={item.areaId}
              mapBounds={mapBounds}
              isDetail={false}
            />
          ))}
          {/* 県単位 */}
          {markerArea.pref.map((item) => (
            <Marker
              {...{ lat: item.lat, lng: item.lng }}
              IsCaution={item.IsCaution}
              key={`marker_pref_${item.areaId}`}
              onClick={item.onClick}
              restroom={item.restroom}
              IsMap
              IsBalloon
              IsToiletWindow={false}
              IsTopType
              mapZoomLevel={mapZoomLevel}
              IsLink={false}
              FixFontSize="20px"
              FixMarkerSize="150px"
              GetSubtotalsByRestroomIds={GetSubtotalsByRestroomIds}
              GetWarningIconByRestroomIds={GetWarningIconByRestroomIds}
              IsView={isZoomPref}
              toDate={toDate}
              restroomIds={item.restroomIds}
              areaId={item.areaId}
              mapBounds={mapBounds}
              isDetail={false}
            />
          ))}
        </GoogleMapReact>
      </div>
      <div
        className="map-option-buttons current-location"
        role="button"
        tabIndex={0}
        onClick={() => handleClickCurrentLocation()}
      >
        <Icon name="crosshairs" size="small" className="map-option-buttons-inner" />
      </div>
    </section>
  );
};
