/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsx jsx */
/**
 * 詳細ポップアップ画面
 */
import React, { FC, useState, useRef, useEffect } from 'react';
import { jsx, css } from '@emotion/core';
import {
  imageIcons,
  usageIcon,
  alertIcon,
  alertColor,
  AlertIconType,
  Usage,
  usageIconWhite,
  DISP_TOILET_WINDOW_ZOOM_LEVEL,
} from 'utils/AppConfig';
import iconMan from 'images/icon/toilet_window/man.png';
import iconWomen from 'images/icon/toilet_window/women.png';
import iconMulti from 'images/icon/toilet_window/multi.png';
import { UseCount, WashTransition, FlowRateMin } from 'components/Graphs';
import { Loader, Icon } from 'semantic-ui-react';
import { TopType } from 'custom_hook/useTopType';
import bgLeft from 'images/background/toilet_window/wash/left.png';
import bgCenter from 'images/background/toilet_window/wash/center.png';
import iconTotal from 'images/icon/toilet_window/total.png';
import iconNow from 'images/icon/toilet_window/now.png';
import bgFlowRate from 'images/background/toilet_window/flow-rate.png';
import { GetImageUrl, GetStatusIcon, GetSubTotalToUseCount, GetSubTotalToWashRate } from 'utils/Common';
import { CreateClipHgSubtotalInput } from 'API';
import { ViewWarning } from 'utils/Warnings';
import InfiniteScroll from 'react-infinite-scroller';
import { RestroomItems } from 'custom_hook/useRestroom';
import dayjs from 'dayjs';

// 座標
export type Pos = {
  x: number;
  y: number;
};

// 座標をリセットするズームレベル
const ZOOMLEVEL_POS_RESET = 5;

// 座標デフォルト
export const DEFAULT_POS: Pos = {
  x: 10,
  y: -254,
};

const heightWindowMaintenance = '280px';
const cssMain = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 270px;
  padding: 6px;
  pointer-events: auto;
  cursor: default;
  user-select: none;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;

  .status-icon {
    max-height: 100%;
    padding-right: 0.5em;
  }

  .move {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    user-select: auto;
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
`;

const bottomMargin = '5px';
const heightTitle = '15px';
const heightDatas = '50px';
const marginTitle = '8px';
const cssToiletWindowUsageStatus = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  h3 {
    height: ${heightTitle};
    padding: 0;
    margin: 0 7px ${marginTitle} 0;
    font-size: ${heightTitle};
    color: #fff;
  }

  .graph {
    position: relative;
    width: 100%;
    height: 105px;
    /* height: calc(100% - ${heightTitle} - ${heightDatas} - 12px - ${bottomMargin}); */
  }

  .datas {
    position: relative;
    display: table;
    width: 100%;
    height: ${heightDatas};
    margin-bottom: 7px;
    table-layout: fixed;

    ul {
      display: table-row;
    }

    li {
      position: relative;
      display: table-cell;
      text-align: left;

      img {
        max-width: 100%;
        max-height: 20px;
      }

      span {
        font-size: 12px;
        color: #fff;
      }

      .num {
        font-family: 'DSEG7ModernMini-Regular', sans-serif;
      }

      .unit {
        font-size: 13px;
      }
    }

    .icon {
      width: 25px;
      text-align: center;
    }
  }
`;

const heightSingleDatas = '25px';
const cssToiletWindowWashStatus = css`
  display: inline-block;
  width: 100%;
  height: 100%;

  h3 {
    height: ${heightTitle};
    padding: 0;
    margin: 0 12px ${marginTitle} 0;
    font-size: ${heightTitle};
    color: #fff;
  }

  .washstatus-graph {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 131px;
    padding: 0;
    margin: 0;
    font-size: 12px;
    list-style: none;
    background: linear-gradient(172deg, rgba(18, 49, 81, 0.7), rgba(19, 70, 157, 0.7));
    border: solid 1px #fff;
    border-radius: 5px;

    .text {
      position: relative;
      font-size: 11px;
      font-weight: bold;
      color: #fff;

      img {
        position: absolute;
        top: -3px;
        right: 13px;
      }
    }
  }

  .datas {
    position: relative;
    display: table;
    width: 100%;
    height: ${heightSingleDatas};
    margin-bottom: 7px;
    table-layout: fixed;

    ul {
      display: table-row;
    }

    li {
      position: relative;
      display: table-cell;
      text-align: left;

      span {
        flex-grow: 1;
        font-family: 'DSEG7ModernMini-Regular', sans-serif;
        font-size: 11px;
        color: #fff;
        text-align: right;
      }

      .unit {
        font-size: 10px;
      }

      .image-box {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 5px;
        line-height: calc(${heightSingleDatas} - (5px * 2));

        img {
          display: inline-block;
          height: 15px;
        }

        .icon-total {
          width: 36px;
          object-fit: contain;
        }
      }

      .left {
        background: url(${bgLeft}) center center no-repeat;
        background-size: 100% 100%;
      }

      .center {
        background: url(${bgCenter}) center center no-repeat;
        background-size: 100% 100%;
      }
    }

    li + li {
      padding-left: 1px;
    }

    .icon {
      width: 25px;
      text-align: center;
    }
  }
`;

const cssToiletWindowMaintenance = css`
  display: inline-block;
  width: 100%;
  height: 100%;

  .maintenance-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    h3 {
      height: ${heightTitle};
      padding: 0;
      margin: 0 12px 0 0;
      font-size: ${heightTitle};
      color: #fff;
    }

    .flow-rate {
      padding-top: 8px;
      pointer-events: auto;

      .graph-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100px;
        padding: 2px 5px;
        margin-top: 5px;
        background: linear-gradient(172deg, rgba(18, 49, 81, 1), rgba(19, 70, 157, 1));
        border: solid 1px #fff;
        border-radius: 5px;

        .title {
          height: 20px;
          font-size: 10px;
          color: #fff;
          text-align: center;
        }

        .graph {
          flex-grow: 1;
        }
      }

      .count {
        position: relative;
        width: calc(100% - 16px);
        height: 25px;
        padding: 1px 20px;
        background: radial-gradient(#001d57, #000f1f);

        &::after {
          position: absolute;
          right: -14px;
          margin: auto;
          content: '';
          border-color: transparent transparent transparent #000f1f;
          border-style: solid;
          border-width: 12px 0 12px 14px;
        }

        img {
          height: 100%;
        }

        span {
          position: absolute;
          top: 8px;
          right: 25px;
          font-size: 13px;
          color: #8bfff8;
        }

        p {
          display: inline;
        }

        .num {
          font-family: 'DSEG7ModernMini-Regular', sans-serif;
        }

        .delimit {
          font-size: 15px;
        }
      }
    }

    .scroll {
      flex-grow: 1;
      padding-top: 5px;
      pointer-events: auto;
    }

    .scroll .outer {
      padding: 5px;
      background: linear-gradient(172deg, rgba(18, 49, 81, 0.7), rgba(19, 70, 157, 0.7));
      border: solid 1px #fff;
      border-radius: 5px;
    }

    .scroll .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: 5px;
      overflow-y: auto;
      font-size: 14px;
      color: #fff;

      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0 5px 0;
        margin: 0;
        list-style: none;
      }

      ul + ul {
        padding-top: 5px;
        border-top: 1px solid #fff;
      }

      .number {
        position: relative;
        display: inline-block;
        width: 2em;
        height: 14px;
        margin-right: 13px;
        background-color: #fff;

        &::after {
          position: absolute;
          top: 0;
          right: -7px;
          z-index: 10;
          width: 0;
          height: 0;
          margin: auto;
          content: '';
          border-color: transparent transparent transparent #fff;
          border-style: solid;
          border-width: 7px 0 7px 7px;
        }

        .number-color {
          position: absolute;
          padding-left: 1px;
          font-size: 15px;
          font-weight: bold;
          line-height: 15px;
          text-shadow: none;
        }
      }

      .icon {
        width: 40px;
        height: 40px;
      }

      .info {
        flex: 1;
        padding-left: 10px;

        img {
          display: inline-block;
          height: 1.2em;
          padding-left: 7px;
        }
      }

      .number-color.dirt {
        color: ${alertColor.dirt};
      }

      .number-color.repair {
        color: ${alertColor.repair};
      }

      .number-color.warning {
        color: ${alertColor.warning};
      }

      .icon.dirt {
        background: url(${alertIcon.dirt}) center center no-repeat;
        background-origin: content-box;
        background-size: contain;
      }

      .icon.repair {
        background: url(${alertIcon.repair}) center center no-repeat;
        background-origin: content-box;
        background-size: contain;
      }

      .icon.warning {
        background: url(${alertIcon.warning}) center center no-repeat;
        background-origin: content-box;
        background-size: contain;
      }
    }

    .scroll .content.color-dirt {
      ::-webkit-scrollbar-thumb {
        background: ${alertColor.dirt};
      }
    }

    .scroll .content.color-repair {
      ::-webkit-scrollbar-thumb {
        background: ${alertColor.repair};
      }
    }

    .scroll .content.color-warning {
      ::-webkit-scrollbar-thumb {
        background: ${alertColor.warning};
      }
    }
  }
`;

const cssToiletWindowImage = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  h3 {
    height: ${heightTitle};
    padding: 0;
    margin: 0 12px ${marginTitle} 0;
    font-size: ${heightTitle};
    color: #fff;
  }

  .toilet-image-content {
    height: calc(100% - ${heightTitle} - ${marginTitle});

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .error {
      margin-top: 65px;
      color: #fff;
      text-align: center;
    }
  }
`;

const cssToiletWindowUpdateDatetime = css`
  padding-top: 4px;
  font-family: sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #fff;
`;

const cssToiletWindowIsAlertMailIcon = css`
  position: absolute;
  top: 6px;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  color: #fff;

  .icon {
    padding: 0;
    margin: 0;
  }

  .off {
    opacity: 0.3;
  }
`;

/**
 * 画像表示
 *
 * @param {RestroomItems} restroom トイレデータ
 * @param {AlertIconType} iconType アラート種別
 */
type ToiletWindowImageProps = {
  restroom: RestroomItems;
  iconType: AlertIconType;
};
const ToiletWindowImage: FC<ToiletWindowImageProps> = ({ restroom, iconType }) => {
  const [status, setStatus] = useState<'loading' | 'error' | 'success'>('loading');
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const unmounted = useRef(false);

  const imageKey = restroom.images[0]?.key;

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    const fn = async (): Promise<void> => {
      const signedUrl = await GetImageUrl(imageKey);
      const img = new Image();
      img.onload = () => {
        if (!unmounted.current) {
          setStatus('success');
          setImageUrl(signedUrl);
        }
      };
      img.onerror = () => {
        if (!unmounted.current) {
          setStatus('error');
        }
      };
      img.src = `${signedUrl}`;
    };

    void fn();
  }, [imageKey]);

  return (
    <section css={cssToiletWindowImage}>
      <h3>
        <img alt="icon" src={GetStatusIcon(iconType)} className="status-icon" />
        {restroom.name}
      </h3>
      <div className="toilet-image-content">
        {status === 'loading' && (
          <div className="loading">
            <Loader active size="massive" inline="centered" />
          </div>
        )}
        {status === 'error' && <p className="error">画像を表示できません</p>}
        {status === 'success' && <img src={imageUrl} alt="toilet" />}
      </div>
    </section>
  );
};

/**
 * アラートメール送信アイコン
 *
 * @param {RestroomItems} restroom トイレデータ
 */
type ToiletWindowIsAlertMailIconProps = {
  restroom: RestroomItems;
};
const ToiletWindowIsAlertMailIcon: FC<ToiletWindowIsAlertMailIconProps> = ({ restroom }) => {
  return (
    <section css={cssToiletWindowIsAlertMailIcon}>
      <Icon className={`icon ${!restroom.isNotify && `off`}`} name="envelope outline" />
    </section>
  );
};

/**
 * 位置取得時間
 *
 * @param {RestroomItems} restroom トイレデータ
 */
type ToiletWindowUpdateDatetimeProps = {
  restroom: RestroomItems;
};
const ToiletWindowUpdateDatetime: FC<ToiletWindowUpdateDatetimeProps> = ({ restroom }) => {
  if (!restroom.latestLocationTime) {
    return <div />;
  }

  return (
    <section css={cssToiletWindowUpdateDatetime}>
      <Icon name="clock outline" />
      <span>最新位置取得時間：</span>
      <span>{dayjs(restroom.latestLocationTime).format('YYYY/MM/DD')}</span>
    </section>
  );
};

/**
 * メンテナンス
 *
 * @param {RestroomItems} restroom トイレデータ
 * @param {ViewWarning[]} warnings warningデータ
 * @param {AlertIconType} iconType アラート種別
 * @param {CreateClipHgSubtotalInput[]} subTotals SubTotalデータ
 */
type ToiletWindowMaintenanceProps = {
  restroom: RestroomItems;
  warnings: ViewWarning[];
  iconType: AlertIconType;
  subTotals: CreateClipHgSubtotalInput[];
};
const ToiletWindowMaintenance: FC<ToiletWindowMaintenanceProps> = ({ restroom, warnings, iconType, subTotals }) => {
  const unmounted = useRef(false);
  const [total, setTotal] = useState(0);
  const [scrollList, setScrollList] = useState<ViewWarning[]>([]);
  const [isFetchList, setIsFetchList] = useState(false);
  const isViewFlowrate = !!restroom.isPremium;
  const LoadCount = isViewFlowrate ? 3 : 5;

  const scrollContentHeight = `calc(${heightWindowMaintenance} - (${heightTitle} + 20px + 25px + 10px + ${
    isViewFlowrate ? '100px' : '0px'
  } + 10px))`;

  // スクロールデータ読み込み
  const LoadMore = (index: number) => {
    if (isFetchList) {
      return;
    }
    if (warnings === undefined) {
      return;
    }
    if (!unmounted.current) {
      setIsFetchList(true);
    }
    const start = (index - 1) * LoadCount;
    const end = start + LoadCount;

    if (!unmounted.current) {
      setScrollList(warnings.slice(0, end));
    }
  };

  // ホイール処理
  const handleWheel = (e: React.WheelEvent) => {
    e.stopPropagation();
  };

  // 無限スクロールに表示するデータがあるかどうか
  useEffect(() => {
    if (warnings === undefined) {
      return;
    }
    if (scrollList.length > 0) {
      if (!unmounted.current) {
        setIsFetchList(scrollList.length >= warnings.length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollList]);

  // 元データ変更時
  useEffect(() => {
    if (warnings === undefined) {
      return;
    }

    if (warnings.length <= 0) {
      if (!unmounted.current) {
        setScrollList([]);
      }
    } else if (!unmounted.current) {
      setScrollList(warnings.slice(0, LoadCount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warnings]);

  // 合計値更新
  useEffect(() => {
    const newTotal = subTotals.reduce((tmpTotal, item) => {
      return tmpTotal + ((item.maleFlowRate ?? 0) + (item.femaleFlowRate ?? 0) + (item.multiFlowRate ?? 0));
    }, 0);

    if (!unmounted.current) {
      setTotal(Math.floor(newTotal));
    }
  }, [subTotals]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <section css={cssToiletWindowMaintenance}>
      <div className="maintenance-content">
        <h3>
          <img alt="icon" src={GetStatusIcon(iconType)} className="status-icon" />
          {restroom.name}
        </h3>
        {isViewFlowrate && (
          <article className="flow-rate">
            <div className="count">
              <img src={bgFlowRate} alt="back" />
              <span>
                <p className="num">{total.toLocaleString()}</p> <p className="delimit">L</p>
              </span>
            </div>
            <div className="graph-content">
              <div className="title">
                <span>使用水量 24 時間推移</span>
              </div>
              <div className="graph">
                <FlowRateMin restroomId={restroom.restroomId} subTotals={subTotals} />
              </div>
            </div>
          </article>
        )}
        {scrollList.length > 0 && (
          <article className="scroll" onWheel={handleWheel}>
            <div className="outer">
              <div className={`content color-${iconType}`} style={{ maxHeight: `${scrollContentHeight}` }}>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={LoadMore}
                  hasMore={!isFetchList}
                  useWindow={false}
                  loader={<div key={0}>loading</div>}
                >
                  {scrollList.map((item) => {
                    return (
                      <ul key={`ToiletWindowMaintenance-${item.type}${item.warningId}`}>
                        <li className={`icon ${item.type}`} />
                        <li className="info">
                          {item.datetime}
                          {item.usage && <img className="usage-icon" alt="icon" src={usageIconWhite[item.usage]} />}
                          <br />
                          <div className="number">
                            <span className={`number-color ${item.type}`}>{item.warningNumber}</span>
                          </div>
                          {item.title}
                        </li>
                      </ul>
                    );
                  })}
                </InfiniteScroll>
              </div>
            </div>
          </article>
        )}
      </div>
    </section>
  );
};

/**
 * 手洗い状況管理
 *
 * @param {RestroomItems} restroom トイレデータ
 * @param {CreateClipHgSubtotalInput[]} subTotals SubTotalデータ
 * @param {AlertIconType} iconType アラート種別
 * @param {Usage[]} usages 使用全用途
 */
type ToiletWindowWashStatusView = {
  total: number | null | undefined;
  male: number | null | undefined;
  female: number | null | undefined;
  multi: number | null | undefined;
};
type ToiletWindowWashStatusProps = {
  restroom: RestroomItems;
  subTotals: CreateClipHgSubtotalInput[];
  iconType: AlertIconType;
  usages: Usage[];
};
const ToiletWindowWashStatus: FC<ToiletWindowWashStatusProps> = ({ restroom, subTotals, iconType, usages }) => {
  const unmounted = useRef(false);
  const [data, setData] = useState<ToiletWindowWashStatusView>();

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  // データ更新
  useEffect(() => {
    const newData: ToiletWindowWashStatusView = {
      male: GetSubTotalToWashRate(subTotals, 1, usages),
      female: GetSubTotalToWashRate(subTotals, 2, usages),
      multi: GetSubTotalToWashRate(subTotals, 3, usages),
      total: GetSubTotalToWashRate(subTotals),
    };

    if (!unmounted.current) {
      setData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotals]);

  return (
    <section css={cssToiletWindowWashStatus}>
      <h3>
        <img alt="icon" src={GetStatusIcon(iconType)} className="status-icon" />
        {restroom.name}
      </h3>
      <div className="datas">
        {data ? (
          <ul>
            <li style={{ width: '85px' }}>
              <div className="image-box left">
                <img className="icon-total" alt="icon" src={iconTotal} />
                <span style={{ fontSize: '12px' }}>{data.total}%</span>
              </div>
            </li>
            <li>
              {usages.some((v) => v === 1) && (
                <div className="image-box center">
                  <img alt="icon" src={usageIcon[1]} />
                  <span>{data.male}%</span>
                </div>
              )}
            </li>
            <li>
              {usages.some((v) => v === 2) && (
                <div className="image-box center">
                  <img alt="icon" src={usageIcon[2]} />
                  <span>{data.female}%</span>
                </div>
              )}
            </li>
            <li>
              {usages.some((v) => v === 3) && (
                <div className="image-box center">
                  <img alt="icon" src={usageIcon[3]} />
                  <span>{data.multi}%</span>
                </div>
              )}
            </li>
          </ul>
        ) : (
          <Loader active size="medium" />
        )}
      </div>
      <ul className="washstatus-graph">
        <li className="text" style={{ textAlign: 'center', height: '18px' }}>
          <div>3時間毎手洗い率推移</div>
        </li>
        <li style={{ flexGrow: 1 }}>
          <div style={{ paddingRight: '10px', height: '100%' }}>
            <WashTransition usages={usages} restroomId={restroom.restroomId} subTotals={subTotals} height="15%" />
          </div>
        </li>
        <li className="text" style={{ fontSize: '10px', height: '15px' }}>
          <div style={{ paddingLeft: '23px' }}>
            [ 時間 ]
            <img alt="icon" src={iconNow} />
          </div>
        </li>
      </ul>
    </section>
  );
};

/**
 * トイレ利用状況
 *
 * @param {RestroomItems} restroom トイレデータ
 * @param {CreateClipHgSubtotalInput[]} subTotals SubTotalデータ
 * @param {AlertIconType} iconType アラート種別
 */
type ToiletWindowUsageStatusView = {
  temperature: number | null | undefined;
  humidity: number | null | undefined;
  co2: number | null | undefined;
  male: number;
  female: number;
  multi: number;
};
type ToiletWindowUsageStatusProps = {
  restroom: RestroomItems;
  subTotals: CreateClipHgSubtotalInput[];
  iconType: AlertIconType;
};
const ToiletWindowUsageStatus: FC<ToiletWindowUsageStatusProps> = ({ restroom, subTotals, iconType }) => {
  const unmounted = useRef(false);
  const [data, setData] = useState<ToiletWindowUsageStatusView>();

  // アイコン＋数値のセル
  const CreateCell = (count: number | null | undefined, icon: string, unit: string, isInt?: boolean) => {
    const cell = (() => {
      if (!count) {
        return ' - ';
      }

      return isInt ? Math.floor(count) : Math.floor(count * 10) / 10;
    })();

    return (
      <React.Fragment>
        <li className="icon">
          <img alt="icon" src={icon} />
        </li>
        <li>
          <span className="num">{cell}</span>
          <span className="unit">{unit}</span>
        </li>
      </React.Fragment>
    );
  };

  // 温湿度
  const GetTemperatureHumidity = (val: number | null | undefined): number | null => {
    if (val === null || val === undefined) {
      return null;
    }

    return Math.round(val);
  };

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (!subTotals) {
      return;
    }

    // 直近のCO2を取得する
    const search = subTotals.slice(0, subTotals.length).reverse();

    // CO2濃度は用途別で保存されている
    let co2: number | null | undefined = null;
    const carbonDioxideData = search.find(
      (item) => item.maleCarbonDioxide || item.femaleCarbonDioxide || item.multiCarbonDioxide,
    );
    if (carbonDioxideData) {
      if (carbonDioxideData.maleCarbonDioxide) {
        co2 = carbonDioxideData.maleCarbonDioxide;
      }
      if (carbonDioxideData.femaleCarbonDioxide) {
        if ((co2 ?? 0) < carbonDioxideData.femaleCarbonDioxide) {
          co2 = carbonDioxideData.femaleCarbonDioxide;
        }
      }
      if (carbonDioxideData.multiCarbonDioxide) {
        if ((co2 ?? 0) < carbonDioxideData.multiCarbonDioxide) {
          co2 = carbonDioxideData.multiCarbonDioxide;
        }
      }
    }

    const newData: ToiletWindowUsageStatusView = {
      temperature: GetTemperatureHumidity(search.find((item) => item.latestTemperature)?.latestTemperature),
      humidity: GetTemperatureHumidity(search.find((item) => item.latestHumidity)?.latestHumidity),
      co2,
      male: GetSubTotalToUseCount(subTotals, 1),
      female: GetSubTotalToUseCount(subTotals, 2),
      multi: GetSubTotalToUseCount(subTotals, 3),
    };

    if (!unmounted.current) {
      setData(newData);
    }
  }, [subTotals]);

  return (
    <section css={cssToiletWindowUsageStatus}>
      <h3>
        <img alt="icon" src={GetStatusIcon(iconType)} className="status-icon" />
        {restroom.name}
      </h3>
      <div className="datas">
        {data ? (
          <React.Fragment>
            <ul>
              {CreateCell(data.temperature, imageIcons.Temperature, '℃')}
              {CreateCell(data.humidity, imageIcons.Humidity, '％')}
              {CreateCell(data.co2, imageIcons.Co2, 'ppm', true)}
            </ul>
            <ul>
              {CreateCell(data.male, iconMan, '人', true)}
              {CreateCell(data.female, iconWomen, '人', true)}
              {CreateCell(data.multi, iconMulti, '人', true)}
            </ul>
          </React.Fragment>
        ) : (
          <Loader active size="medium" />
        )}
      </div>
      <div className="graph">
        <UseCount subTotals={subTotals} />
      </div>
    </section>
  );
};

/**
 * グラフ
 *
 * @param {RestroomItems} restroom トイレデータ
 * @param {number} topleft 表示位置
 * @param {TopType} topType TOP種別
 * @param {HTMLDivElement} parent 親オブジェクト
 * @param {function} SavePos 位置保存処理
 * @param {CreateClipHgSubtotalInput[]} subTotals SubTotalデータ
 * @param {ViewWarning[]} warnings warningデータ
 * @param {AlertIconType} iconType アラート種別
 * @param {number} mapZoomLevel 地図のズームレベル
 */
export type ToiletWindowProps = {
  restroom: RestroomItems;
  topleft: Pos;
  topType: TopType;
  parent: HTMLDivElement | null;
  SavePos: (savePos: Pos) => void;
  subTotals: CreateClipHgSubtotalInput[];
  warnings: ViewWarning[];
  iconType: AlertIconType;
  usages: Usage[];
  mapZoomLevel: number | undefined;
  dispTwZoomLevel?: number;
};
export const ToiletWindow: FC<ToiletWindowProps> = ({
  restroom,
  topType,
  topleft,
  SavePos,
  subTotals,
  warnings,
  iconType,
  usages,
  mapZoomLevel,
  dispTwZoomLevel = DISP_TOILET_WINDOW_ZOOM_LEVEL,
}) => {
  const [pos, setPos] = useState<Pos>(topleft);
  const [isLoading, setIsLoading] = useState(false);
  const [initVisible, setInitVisible] = useState<boolean>(!(topType === 'maintenance'));
  const [isImage, setIsImage] = useState(false);
  const moveSwitch = useRef(false);
  const moveStart = useRef<Pos | null>(null);
  const unmounted = useRef(false);
  const ref = useRef<HTMLTableSectionElement>(null);
  const isPosReset = useRef(true);

  // 表示画像
  const imageKey = restroom.images[0]?.key;

  // ドラッグ
  const Drag = (e: { clientX: number; clientY: number }) => {
    if (!unmounted.current && moveSwitch.current && moveStart.current !== null) {
      setPos({
        x: pos.x + e.clientX - moveStart.current.x,
        y: pos.y + e.clientY - moveStart.current.y,
      });
    }
  };

  // ドロップ
  const Drop = (e: { clientX: number; clientY: number }) => {
    if (!moveSwitch.current) {
      return;
    }

    if (!unmounted.current && moveStart.current !== null) {
      const newPosX = pos.x + e.clientX - moveStart.current.x;
      const newPosY = pos.y + e.clientY - moveStart.current.y;

      // クリック
      if (pos.x === newPosX && pos.y === newPosY) {
        if (imageKey) {
          setIsImage(!isImage);
        }
      }
      // 移動
      else {
        setIsLoading(true);
        SavePos({
          x: newPosX,
          y: newPosY,
        });
      }
    }

    window.removeEventListener('mousemove', Drag);
    window.removeEventListener('mouseup', Drop);
    isPosReset.current = true;
    moveSwitch.current = false;
    moveStart.current = null;

    setTimeout(() => {
      if (!unmounted.current) {
        setIsLoading(false);
      }
    }, 250);
  };

  // ドラッグ開始
  const DragStart = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (moveStart.current === null) {
      moveStart.current = { x: e.clientX, y: e.clientY };
    }
    moveSwitch.current = true;
    window.addEventListener('mousemove', Drag);
    window.addEventListener('mouseup', Drop);

    e.stopPropagation();
  };

  useEffect(() => {
    return () => {
      moveSwitch.current = false;
      unmounted.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // refを取得するラグ対応
  useEffect(() => {
    setTimeout(() => {
      if (!unmounted.current) {
        setInitVisible(true);
      }
    }, 250);

    return () => {
      window.removeEventListener('mousemove', Drag);
      window.removeEventListener('mouseup', Drop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 座標リセット
  useEffect(() => {
    if (mapZoomLevel === undefined) {
      return;
    }
    if (mapZoomLevel > ZOOMLEVEL_POS_RESET) {
      return;
    }
    if (!isPosReset.current) {
      return;
    }

    SavePos(DEFAULT_POS);
    isPosReset.current = false;
    if (!unmounted.current) {
      setPos(DEFAULT_POS);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapZoomLevel]);

  const cssMainUpdate = css`
    ${cssMain}
    visibility: ${initVisible ? 'visible' : 'hidden'};
  `;

  const top = `${pos.y}px`;
  /*
  // メンテナンス画面が広がる分だけずらす
  let top = `${pos.y}px`;
  if (topType === 'maintenance' && ref.current !== null) {
    top = `calc(${pos.y}px + (${heightWindow} - ${ref.current.clientHeight}px))`;
  }
  */

  // ズームレベルによって非表示
  if (mapZoomLevel !== undefined && mapZoomLevel < dispTwZoomLevel) {
    return <div />;
  }

  return (
    <section ref={ref} css={cssMainUpdate} style={{ top: `${top}`, left: `${pos.x}px` }}>
      {isLoading && (
        <div className="loading">
          <Loader active size="massive" inline="centered" />
        </div>
      )}
      {topType !== 'maintenance' && !isLoading && <div className="move" onMouseDown={DragStart} />}
      {(() => {
        if (isImage && imageKey && topType !== 'maintenance') {
          return <ToiletWindowImage iconType={iconType} restroom={restroom} />;
        }
        switch (topType) {
          case 'wash':
            return (
              <ToiletWindowWashStatus iconType={iconType} restroom={restroom} subTotals={subTotals} usages={usages} />
            );
          case 'maintenance':
            return (
              <ToiletWindowMaintenance
                iconType={iconType}
                restroom={restroom}
                warnings={warnings}
                subTotals={subTotals}
              />
            );
          default:
            return <ToiletWindowUsageStatus iconType={iconType} restroom={restroom} subTotals={subTotals} />;
        }
      })()}
      <ToiletWindowUpdateDatetime restroom={restroom} />
      <ToiletWindowIsAlertMailIcon restroom={restroom} />
    </section>
  );
};
