/* eslint-disable no-await-in-loop */
import React, { FC, useState, useRef, useEffect } from 'react';
import Amplify from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import awsExports from 'aws-exports';
import dayjs from 'dayjs';
import { ListWarningsByRestroomIdQueryVariables, ListWarningsByRestroomIdQuery, ModelSortDirection } from 'API';
import { WarningList } from 'components/WarningList';
import { listWarningsByRestroomId } from 'graphql/queries';
import { Usage, usageReg } from 'utils/AppConfig';
import { RestroomItems } from 'custom_hook/useRestroom';
import { ConvertClipHgWarningInput } from 'utils/Warnings';
import { LastestLog } from 'utils/Log';
import { ApiGraphqlOperationWrapper, ZeroPadding, getRestroom } from 'utils/Common';
import Toilet from 'utils/Toilet';
import isBetween from 'dayjs/plugin/isBetween';
import { useAuth, User } from 'custom_hook/useAuth';

dayjs.extend(isBetween);

Amplify.configure(awsExports);

/**
 * ワーニングの並び替えキー発行
 *
 * @param {ConvertClipHgWarningInput} warning ワーニングデータ
 * @param {CreateClipHgToiletInput} [toilet]  トイレデータ
 * @return {string} 並び替えキー
 */
const GetWarningSortkey = (warning: ConvertClipHgWarningInput): string => {
  const priority = 10000 - warning.warningNumber;
  // 発生日時 + アイコン状態
  let ret = `${warning.sendDateTime}_${priority}`;

  // 便器種類
  if (warning.type !== undefined && warning.type !== null) {
    const type = ZeroPadding(warning.type, 3);
    ret = `${ret}_${type}`;
  }

  // トイレ名
  ret = `${ret}_${warning.toiletName ?? ''}`;

  // 対象時間帯
  ret = `${ret}_${warning.sendDateTime}`;

  // トイレID
  ret = `${ret}_${warning.restroomId}`;

  return ret;
};

/**
 * デバイスに紐づくワーニングを取得
 *
 * @param {string} restroomId トイレID
 * @param {number} from 検索日時from
 * @param {string} to 検索日時to
 * @return {Promise<ConvertClipHgWarningInput[]>}
 */
const QueryFromWarning = async (restroomId: string, from: string, to: string): Promise<ConvertClipHgWarningInput[]> => {
  const ret: ConvertClipHgWarningInput[] = [];
  let nextToken: string | null | undefined = null;

  //  取得範囲:2日前～本日 ソート:時間降順
  const conditions: ListWarningsByRestroomIdQueryVariables = {
    restroomId,
    sendDateTime: {
      between: [from, to],
    },
    sortDirection: ModelSortDirection.DESC,
    nextToken: null,
  };

  do {
    conditions.nextToken = nextToken;

    const result = await ApiGraphqlOperationWrapper<
      ListWarningsByRestroomIdQueryVariables,
      GraphQLResult<ListWarningsByRestroomIdQuery>
    >(conditions, listWarningsByRestroomId);

    nextToken =
      undefined === result.data?.listWarningsByRestroomId?.nextToken
        ? null
        : result.data?.listWarningsByRestroomId?.nextToken;

    const warningItems = (result.data?.listWarningsByRestroomId?.items as unknown) as ConvertClipHgWarningInput[];

    for (let i = 0; i < warningItems.length; i += 1) {
      const item = warningItems[i];
      item.sortKey = GetWarningSortkey(item);
      ret.push(item);
    }
  } while (nextToken !== null);

  return ret;
};

/**
 * ワーニングデータを編集して取得
 *
 * @param {string} restroomId トイレID
 * @param {User | undefined} user ユーザー認証データ
 * @param {string} to 検索日時to
 * @param {string} from 検索日時from
 * @return {*}  {Promise<ConvertClipHgWarningInput[]>}
 */
// ワーニング番号検索用
type SearchWarning = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};
// １０分間に３回以上受信した場合のみ表示するワーニング
const COUNT_WARNING_LIMIT = 3;
type CountWarning = {
  [warningNumber in string]: {
    [sendDatetime in string]: {
      item: ConvertClipHgWarningInput; // 実際に表示するワーニングデータ
      count: number; // １０分以内カウンタ
      is: boolean; // すでに表示しているかどうか
    };
  };
};
export const GetWarningItems = async (
  restroomId: string,
  user: User | undefined,
  to?: string,
  from?: string,
): Promise<ConvertClipHgWarningInput[]> => {
  const useFrom = from ?? dayjs(to).subtract(1, 'days').toString();
  const warningItems = await QueryFromWarning(
    restroomId,
    dayjs(useFrom).format('YYYY-MM-DD HH:mm:ss').toString(),
    dayjs(to).format('YYYY-MM-DD HH:mm:ss').toString(),
  );

  // ワーニングデータの編集
  const ret: ConvertClipHgWarningInput[] = [];
  // 10分間に3回以上受信時のみ表示
  const I2cWarnings: CountWarning = {
    103: {},
    104: {},
    105: {},
    108: {},
    116: {},
    117: {},
    118: {},
  };
  const warning204LastTime = ['', '', '', ''];
  const checkUsage = new RegExp(usageReg);
  // Wifi詳細表示ユーザーでない場合に非表示
  const noAdminHiddens: SearchWarning = {
    206: {},
    207: {},
    211: {},
  };
  // 防犯検知表示ユーザーでない場合に非表示
  const ViewWarningSecurity: SearchWarning = {
    201: {},
  };

  // 防犯検知有効
  let isViewSecurityWarning = true;
  if (user?.username) {
    const userRestroom = await getRestroom(restroomId, user.username);
    if (userRestroom) {
      isViewSecurityWarning = userRestroom.isHiddenSecurityWarning ?? false;
    }
  }

  for (let i = warningItems.length - 1; i >= 0; i -= 1) {
    const warning = warningItems[i];
    const { usage } = warning;

    // Wifi詳細表示ユーザーでない場合に非表示
    if (!user?.isAdmin && noAdminHiddens[warning.warningNumber]) {
      // eslint-disable-next-line no-continue
      continue;
    }
    // 防犯検知表示ユーザーでない場合に非表示
    if (isViewSecurityWarning && ViewWarningSecurity[warning.warningNumber]) {
      // eslint-disable-next-line no-continue
      continue;
    }

    // 10分間に3回以上受信時のみ表示
    if (I2cWarnings[warning.warningNumber]) {
      // 該当ワーニング番号に含まれる日付を全チェック
      let isNew = true;
      const dates = Object.keys(I2cWarnings[warning.warningNumber]);
      for (let j = 0; j < dates.length; j += 1) {
        const srcDate = dates[j];
        const target = I2cWarnings[warning.warningNumber][srcDate];
        const fromDate = dayjs(srcDate);
        const toDate = dayjs(srcDate).add(10, 'minutes');
        const now = dayjs(warning.sendDateTime);

        // 範囲内の日時はcountを進める
        if (now.isBetween(fromDate, toDate)) {
          // 表示データに登録
          target.count += 1;
          if (target.count >= COUNT_WARNING_LIMIT && !target.is) {
            ret.push(target.item);
            target.is = true;
          }
          isNew = false;
          break;
        }
      }

      // 新規登録
      if (isNew) {
        I2cWarnings[warning.warningNumber][warning.sendDateTime] = {
          item: warning,
          count: 1,
          is: false,
        };
      }
      // eslint-disable-next-line no-continue
      continue;
    }

    // 用途単位で1時間に1度だけ表示する
    if (warning.warningNumber === 204 && usage) {
      if (checkUsage.exec(usage.toString())) {
        if (warning204LastTime[usage].length > 0) {
          if (dayjs(warning204LastTime[usage]).add(1, 'hour').unix() >= dayjs(warning.sendDateTime).unix()) {
            // eslint-disable-next-line no-continue
            continue;
          }
        }
      }
      warning204LastTime[usage] = warning.sendDateTime;
    }

    ret.push(warning);
  }

  // 並び替え
  ret.sort((a, b) => {
    if (a.sortKey && b.sortKey) {
      if (a.sortKey < b.sortKey) return 1;
      if (a.sortKey > b.sortKey) return -1;
    }

    return 0;
  });

  return ret;
};

type WarningListContainerProps = {
  usage: Usage | undefined;
  restroom: RestroomItems | undefined;
  toDate?: string;
  fromDate?: string;
};

const WarningListContainer: FC<WarningListContainerProps> = ({ usage, restroom, toDate, fromDate }) => {
  const unmounted = useRef(false);
  const toiletAll = useRef(new Toilet());
  const [warnings, setWarning] = useState<ConvertClipHgWarningInput[] | null>(null);
  const [error, setError] = useState<string>('');
  const auth = useAuth();

  // トイレに紐づく便器データ更新
  const ToiletAllUpdate = async (useRrestroom: RestroomItems) => {
    if (!useRrestroom) {
      return;
    }
    if (!useRrestroom?.devices) {
      return;
    }

    // デバイスに紐づく全トイレデータ取得
    toiletAll.current.Reset();
    try {
      for (let i = 0; i < useRrestroom.devices.items.length; i += 1) {
        const device = useRrestroom.devices.items[i];
        await toiletAll.current.Add(device.deviceId);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('[getCongestionThreshold]', e);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[getCongestionThreshold] ${(e as { message: string })?.message}`);
    }
  };

  // トイレ単位の詳細データ
  const getWarnings = async (useRrestroom: RestroomItems) => {
    if (!unmounted.current) {
      setError('');
    }
    if (!unmounted.current) {
      setWarning(null);
    }

    // 便器データ更新
    await ToiletAllUpdate(useRrestroom);

    try {
      const result = await GetWarningItems(useRrestroom.restroomId, auth.user, toDate, fromDate);

      if (!unmounted.current) {
        setWarning(result);
      }
    } catch (e) {
      if (!unmounted.current) {
        setError('データベースでエラーが発生しました。');
      }
      // eslint-disable-next-line no-console
      console.error('[getWarnings]', e);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[getWarnings] ${(e as { message: string })?.message}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  // データ更新
  useEffect(() => {
    const effectFunc = async () => {
      if (restroom) {
        await getWarnings(restroom);
      }
    };

    void effectFunc();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restroom, usage, toDate]);

  if (usage === undefined) {
    return <div />;
  }

  return (
    <WarningList usage={usage} warnings={warnings} apiError={error} restroom={restroom} toiletAll={toiletAll.current} />
  );
};

export default WarningListContainer;
