/** @jsx jsx */
/**
 * マーカー
 */
import { FC, useEffect, useState, useRef } from 'react';
import { jsx, css } from '@emotion/core';
import { RestroomItems } from 'custom_hook/useRestroom';
import { Link } from 'react-router-dom';
import iconWinterSquare from 'images/icon/iconWinter_square.png';
import { GraphColor, alertIcon, AlertIconType, AUTO_UPDATE_TIME, Usage } from 'utils/AppConfig';
import { ToiletWindowContainer } from 'containers/ToiletWindow';
import { useTopType } from 'custom_hook/useTopType';
import { ToiletGraph } from 'components/Graphs';
import { CreateClipHgSubtotalInput } from 'API';
import {
  GetSubTotalToUseCount,
  GetSubtotalByRestroomId,
  GetSubTotalToWashRate,
  GetAlertIconTypeByWarningData,
} from 'utils/Common';
import { ViewWarning, GetWarningValue, CheckViewData } from 'utils/Warnings';
import { GetWarningItems } from 'containers/WarningList';
import { LastestLog } from 'utils/Log';
import Toilet from 'utils/Toilet';
import { MapBounds } from 'components/Top';
import { useAuth } from 'custom_hook/useAuth';

const markerInlineCss = css`
  display: inline-block;
  width: 100%;
  height: 100%;
  user-select: none;
`;

const cssAnyOneDisconnected = css`
  .graphwash-inner span,
  .label span,
  h3 {
    opacity: 0.6;
  }
`;

const markerCss = css`
  min-width: 50px;
  max-width: 100px;
  min-height: 50px;
  max-height: 100px;
  pointer-events: none;
  user-select: none;
  outline: 0;
`;

const cssMaintenance = css`
  position: relative;
  width: 70px;
  height: 70px;
  outline: 0;
`;

const cssGraphWash = css`
  position: relative;
  width: 70px;
  height: 70px;
  outline: 0;

  .graphwash-inner {
    width: 100%;
    height: 100%;
    padding: 2px 8px 0 8px;
    background: linear-gradient(172deg, rgba(18, 49, 81, 0.9), rgba(19, 70, 157, 0.9));
    border: solid 2px #fff;
    border-radius: 5px;

    ul {
      display: table;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }

    li {
      position: relative;
      display: table-row;
      text-align: center;

      span {
        font-family: 'DSEG7ModernMini-Regular', sans-serif;
        font-size: 12px;
        line-height: 19px;
        color: #fff;
      }
    }
  }
`;

const cssImage = css`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .image {
    width: 70%;
    height: 70%;
  }

  .image-box {
    width: 95%;
    height: 95%;
    border-radius: 50%;
  }

  .image.dirt {
    background: url(${alertIcon.dirt}) center center no-repeat;
    background-size: contain;
  }

  .image-box.dirt {
    background: radial-gradient(#b971cd, #8629b0);
  }

  .image.repair {
    background: url(${alertIcon.repair}) center center no-repeat;
    background-size: contain;
  }

  .image-box.repair {
    background: radial-gradient(#ff8102, #ee5802);
  }

  .image.warning {
    background: url(${alertIcon.warning}) center center no-repeat;
    background-size: contain;
  }

  .image-box.warning {
    background: radial-gradient(#fdff65, #bbdc00);
  }

  .image.good {
    background: url(${alertIcon.good}) center center no-repeat;
    background-size: contain;
  }

  .image-box.good {
    background: radial-gradient(#0799c8, #37d3e0);
  }
`;

const cssPieGraph = css`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .label {
    width: 70%;
    height: 70%;
    background: radial-gradient(#1045a0, #0e2e52);
    border-radius: 50%;
  }

  .winter {
    width: 100%;
    height: 100%;
    background: url(${iconWinterSquare}) center center no-repeat;
    background-size: contain;
    opacity: 0.4;
  }

  span {
    font-family: 'DSEG7ModernMini-Regular', sans-serif;
    color: #fff;
  }

  .pie-graph-label {
    position: absolute;
    top: 1.3em;
    left: 50%;
    color: #fff;
    transform: translateY(-50%) translateX(-50%);
  }
`;

const cssBalloon = css`
  position: relative;
  width: 100%;
  height: 100%;

  .point {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 3px;
    height: 3px;
    background-color: #f00;
    border-radius: 3px;
  }

  .triangle {
    position: absolute;
    width: 0;
    height: 0;

    &::after {
      position: absolute;
      width: 0;
      height: 0;
      content: '';
    }
  }

  .balloon-content {
    position: relative;
    z-index: 11;
    pointer-events: auto;
  }
`;

/**
 * 吹き出し
 *
 * @param {React.ReactNode} children content内に表示するDOM
 * @param {React.CSSProperties} contentStyle contentに追加するstyle
 * @param {boolean} isTriangle 三角を付けるかどうか
 * @param {number} trianglePosY 三角の位置調整
 * @param {object} content contentのサイズ
 * @param {object} triangle 吹き出し三角のサイズ
 * @param {object} colors ボーダーと背景の色
 *
 * 使い方
 * <Balloon
 *    content={{ width: '100px', height: '100px' }}
 *    colors={{ border: 'rgba(0,0,0,0.3)', background: '#FFF' }}
 *    contentStyle={{ borderRadius: '5px' }}
 *  >
 *    contentのなか
 *  </Balloon>
 */
type BalloonProps = {
  children?: React.ReactNode;
  contentStyle?: React.CSSProperties;
  isTriangle?: boolean;
  trianglePosY?: number;
  content?: {
    width?: string;
    height?: string;
  };
  triangle?: {
    height?: string;
    width?: string;
  };
  colors?: {
    border?: string;
    background?: string;
  };
};
export const Balloon: FC<BalloonProps> = ({
  children,
  contentStyle,
  isTriangle = false,
  trianglePosY = 3,
  content = { width: '100%', height: '100%' },
  triangle = { height: '15px', width: '11px' },
  colors = { border: 'transparent', background: 'transparent' },
}) => {
  const updateCss = css`
    ${cssBalloon}
    .triangle {
      top: calc((${triangle.height} + ${trianglePosY}px) * -1);
      left: -${triangle.width};
      border-top: ${triangle.height} solid ${colors.border};
      border-right: ${triangle.width} solid transparent;
      border-left: ${triangle.width} solid transparent;

      &::after {
        top: -${triangle.height};
        left: calc(-${triangle.width} + 1px);
        border-top: calc(${triangle.height} - 1px) solid ${colors.background};
        border-right: calc(${triangle.width} - 1px) solid transparent;
        border-left: calc(${triangle.width} - 1px) solid transparent;
      }
    }

    .balloon-content {
      ${isTriangle &&
      `
        top: calc(-${content.height} - ${triangle.height});
        left: calc(-${content.width} / 2);
      `}
      width: ${content.width};
      height: ${content.height};
      background-color: ${colors.background};
      border: 1px solid ${colors.border};
    }
  `;

  return (
    <div css={updateCss}>
      <div className="balloon-content" style={contentStyle}>
        {children}
      </div>
      {isTriangle && <div className="triangle" />}
    </div>
  );
};

/**
 * 手洗い状況管理グラフマーカー
 *
 * @param {GraphWashData} datas 表示データ
 */
type GraphWashData = {
  male: number | undefined | null;
  female: number | undefined | null;
  multi: number | undefined | null;
  total: number | undefined | null;
  areaId?: string;
};
type GraphWashProps = {
  data: GraphWashData | undefined;
};
const GraphWash: FC<GraphWashProps> = ({ data }) => {
  if (!data) {
    return <div />;
  }

  return (
    <section css={cssGraphWash}>
      <Balloon
        colors={{
          border: 'rgba(0,0,0,0.3)',
          background: `#fff`,
        }}
        contentStyle={{ borderRadius: '5px' }}
        isTriangle
        trianglePosY={1}
      >
        <div className="graphwash-inner">
          <ul>
            <li style={{ height: '100%' }}>
              <ToiletGraph
                Percent={{ Male: data.male, Female: data.female, Multi: data.multi }}
                IsIcons
                height="17px"
              />
            </li>
            <li style={{ height: '20px' }}>
              <span>{data.total}%</span>
            </li>
          </ul>
        </div>
      </Balloon>
    </section>
  );
};

/**
 * 円グラフ描画 サイズは100×100
 * 使い方
 * <img alt="pie" src={CreatePieChart([7, 4, 5])} />
 *
 * @param {number[]} items
 * @return {*}  {string}
 */
const CreatePieChart = (nums: number[]): string => {
  if (nums.length !== 3) {
    return '';
  }
  const items = [nums[2], nums[0], nums[1]];
  const colors = [GraphColor.male, GraphColor.female, GraphColor.multi];
  const size = 100;
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext('2d');
  const harfSize = size / 2;

  if (!context) {
    return '';
  }

  const total = items.reduce((prev, current) => prev + current, 0);
  const angles = items.map((item) => {
    if (!item) return 0;

    return 360 * (item / total);
  });

  const CreatePie = (startAngle: number, endAngle: number, color: string) => {
    context.beginPath();
    context.arc(
      harfSize,
      harfSize,
      harfSize,
      ((startAngle - 90) * Math.PI) / 180,
      ((endAngle - 90) * Math.PI) / 180,
      false,
    );
    context.lineTo(harfSize, harfSize);

    const gradient = context.createLinearGradient(0, 0, harfSize / 2, harfSize / 2);
    // gradient.addColorStop(0, 'rgb(255, 255, 255)'); // グラデーション
    gradient.addColorStop(0, color);
    context.fillStyle = gradient;

    context.fill();
  };

  let startAngle = 0;
  let endAngle = 0;
  for (let i = 0; i < angles.length; i += 1) {
    endAngle += angles[i];
    CreatePie(startAngle, endAngle, colors[i]);
    startAngle += angles[i];
  }

  return canvas.toDataURL();
};

export type MarkerProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  lat?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  lng?: number;
  IsCaution?: boolean;
  IsMap?: boolean;
  IsBalloon?: boolean;
  IsToiletWindow?: boolean;
  IsTopType?: boolean;
  FixFontSize?: string;
  FixMarkerSize?: string;
  IconType?: AlertIconType;
  onClick?: () => void;
  restroom?: RestroomItems;
  pieLabel?: string;
  pieData?: number[];
  mapZoomLevel?: number;
  IsLink?: boolean;
  GetSubtotalsByRestroomIds?: (restroomIds: string[]) => Promise<CreateClipHgSubtotalInput[]>;
  GetWarningIconByRestroomIds?: (restroomIds: string[]) => Promise<AlertIconType>;
  IsView?: boolean;
  toDate?: string;
  areaId?: string;
  restroomIds?: string[];
  mapBounds?: MapBounds;
  dispTwZoomLevel?: number;
  isDetail?: boolean;
};
/**
 * マーカー
 *
 * @param {number}        lat             表示座標
 * @param {number}        lng             表示座標
 * @param {boolean}       IsMap           マップ表示
 * @param {boolean}       IsBalloon       バルーン表示
 * @param {boolean}       IsToiletWindow  トイレWindow表示
 * @param {boolean}       IsTopType       TOP種別を参照するかどうか
 * @param {string}        FixFontSize     固定するフォントサイズ
 * @param {string}        FixMarkerSize   固定するマーカーサイズ
 * @param {function}      onClick         クリック処理
 * @param {RestroomItems} restroom        トイレデータ
 * @param {string}        pieLabel        円グラフ時に表示するラベル
 * @param {number[]}      pieData         円グラフのデータ。無い場合はrestroomから取得する
 * @param {number}        mapZoomLevel    地図のズームレベル
 * @param {boolean}       IsLink          リンクにするかどうか
 * @param {function} GetSubtotalsByRestroomIds トイレIDの配列からSubtotalデータ取得
 * @param {function} GetWarningIconByRestroomIds トイレIDの配列からWarningアイコン取得
 * @param {boolean}       IsView          表示するかどうか
 * @param {string}        toDate          検索日付to
 * @param {string}        areaId          エリアID
 * @param {string[]}      restroomIds     複数のトイレID
 * @param {MapBounds}     mapBounds       地図表示範囲
 * @param {number}        dispTwZoomLevel トイレウィンドウ表示ズームレベル
 * @param {boolean}       isDetail        詳細画面かどうか
 */
export const Marker: FC<MarkerProps> = ({
  lat,
  lng,
  IsMap = false,
  IsBalloon = false,
  IsToiletWindow = false,
  IsTopType = false,
  FixFontSize = undefined,
  FixMarkerSize = undefined,
  onClick = undefined,
  restroom = undefined,
  pieLabel = '',
  pieData,
  mapZoomLevel,
  IsLink = true,
  GetSubtotalsByRestroomIds,
  GetWarningIconByRestroomIds,
  IsView = true,
  toDate,
  areaId,
  restroomIds = [],
  mapBounds,
  dispTwZoomLevel,
  isDetail = true,
}) => {
  const unmounted = useRef(false);
  const intervalId = useRef<NodeJS.Timeout | number>(0);
  const [subTotals, setSubTotals] = useState<CreateClipHgSubtotalInput[]>([]);
  const [warnings, setWarnings] = useState<ViewWarning[]>();
  const [iconType, setIconType] = useState<AlertIconType>('good');
  const [Count, setCount] = useState(0);
  const [loadPieData, setLoadPieData] = useState<number[]>([0, 0, 0]);
  const [isLoading, setIsLoading] = useState(true);
  const [markerSize, setMarkerSize] = useState('100px');
  const [countFontSize, setCountFontSize] = useState('3em');
  const [washData, setWashData] = useState<GraphWashData>();
  const [usages, setUsages] = useState<Usage[]>([]);
  const [isMapBoundsView, setIsMapBoundsView] = useState<boolean>(true);
  const [isAnyOneDisconnected, setIsAnyOneDisconnected] = useState<boolean>(true);
  const topType = useTopType();
  const ref = useRef<HTMLDivElement>(null);
  const auth = useAuth();

  // 自治体単位のデータ更新
  const UpdateGovernmentDatas = async () => {
    if (
      GetSubtotalsByRestroomIds === undefined ||
      GetSubtotalsByRestroomIds === null ||
      GetWarningIconByRestroomIds === undefined ||
      GetWarningIconByRestroomIds === null
    ) {
      return;
    }

    // 使用回数
    const subtotalByGovernment = await GetSubtotalsByRestroomIds(restroomIds);
    const newLoadPieData = [
      GetSubTotalToUseCount(subtotalByGovernment, 2),
      GetSubTotalToUseCount(subtotalByGovernment, 3),
      GetSubTotalToUseCount(subtotalByGovernment, 1),
    ];
    if (!unmounted.current) {
      setLoadPieData(newLoadPieData);
      setCount(newLoadPieData[0] + newLoadPieData[1] + newLoadPieData[2]);
    }

    // 手洗い状況管理表示データ
    const newWashData: GraphWashData = {
      male: GetSubTotalToWashRate(subtotalByGovernment, 1),
      female: GetSubTotalToWashRate(subtotalByGovernment, 2),
      multi: GetSubTotalToWashRate(subtotalByGovernment, 3),
      total: GetSubTotalToWashRate(subtotalByGovernment),
      areaId,
    };
    if (!unmounted.current) {
      setWashData(newWashData);
    }

    // Warningアイコン
    const newIconType = await GetWarningIconByRestroomIds(restroomIds);
    if (!unmounted.current) {
      setIconType(newIconType);
    }
  };

  // 円グラフ
  const PieChart = () => {
    const chartNumbers = pieData === undefined ? loadPieData : pieData;

    return (
      <Balloon
        colors={{
          border: 'rgba(0,0,0,0.3)',
          background: `#fff`,
        }}
        contentStyle={{ borderRadius: `calc(50%)`, padding: '3px' }}
        isTriangle={IsBalloon}
      >
        <article ref={ref} css={cssPieGraph}>
          <img alt="pie" className="center" style={{ width: '100%' }} src={CreatePieChart(chartNumbers)} />
          <div className="label center">
            {restroom?.isWinter && <div className="winter center" />}
            {pieLabel.length > 0 && <p className="pie-graph-label">{pieLabel}</p>}
            <span className="center" style={{ fontSize: `${countFontSize}` }}>
              {Count}
            </span>
          </div>
        </article>
      </Balloon>
    );
  };

  // メンテナンス
  const Maintenance = () => {
    return (
      <section css={cssMaintenance}>
        <Balloon
          colors={{
            border: 'rgba(0,0,0,0.3)',
            background: `#fff`,
          }}
          contentStyle={{ borderRadius: `calc(50%)`, padding: '3px' }}
          isTriangle
        >
          <article ref={ref} css={cssImage}>
            <div className={`image-box center ${iconType}`}>
              <div className={`image center ${iconType}`} />
            </div>
          </article>
        </Balloon>
      </section>
    );
  };

  // 表示マーカー選択
  const CreateMarker = () => {
    if (topType.topType === 'maintenance' && IsTopType) {
      return <Maintenance />;
    }
    if (topType.topType === 'wash' && IsTopType) {
      return <GraphWash data={washData} />;
    }

    // 円グラフ
    return <PieChart />;
  };

  // 更新処理
  const UpdateData = async () => {
    if (!restroom) {
      return;
    }
    try {
      const PromiseAll = [];

      // 集計データ
      PromiseAll.push(
        GetSubtotalByRestroomId(restroom.restroomId, toDate, isDetail).then((newSubTotals) => {
          if (!unmounted.current) {
            setSubTotals(newSubTotals);
          }

          // 手洗い状況管理表示データ
          const newWashData: GraphWashData = {
            male: GetSubTotalToWashRate(newSubTotals, 1, usages),
            female: GetSubTotalToWashRate(newSubTotals, 2, usages),
            multi: GetSubTotalToWashRate(newSubTotals, 3, usages),
            total: GetSubTotalToWashRate(newSubTotals),
          };
          if (!unmounted.current) {
            setWashData(newWashData);
          }
        }),
      );

      // ワーニングデータ
      PromiseAll.push(
        GetWarningItems(restroom.restroomId, auth.user, toDate).then((warningItems) => {
          let newIconType: AlertIconType = 'good';
          const newWarnings: ViewWarning[] = [];

          warningItems.forEach((item) => {
            const value = GetWarningValue(item);
            if (CheckViewData(restroom, item)) {
              if (value) {
                newWarnings.push(value);
                const tmpIconType = GetAlertIconTypeByWarningData(item, newIconType);
                switch (tmpIconType) {
                  case 'dirt':
                    if (newIconType === 'good') {
                      newIconType = tmpIconType;
                    }
                    break;
                  case 'warning':
                    if (newIconType === 'good' || newIconType === 'dirt') {
                      newIconType = tmpIconType;
                    }
                    break;
                  default:
                    newIconType = tmpIconType;
                    break;
                }
              }
            }
          });

          if (!unmounted.current) {
            setWarnings(newWarnings);
          }
          if (!unmounted.current) {
            setIconType(newIconType);
          }
        }),
      );

      await Promise.all(PromiseAll);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('[Marker_UpdateData]', e);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[Marker_UpdateData] ${(e as { message: string })?.message}`);
    }
  };

  useEffect(() => {
    if (restroom && !pieData) {
      const newLoadPieData = [
        GetSubTotalToUseCount(subTotals, 2),
        GetSubTotalToUseCount(subTotals, 3),
        GetSubTotalToUseCount(subTotals, 1),
      ];
      if (!unmounted.current) {
        setLoadPieData(newLoadPieData);
        setCount(newLoadPieData[0] + newLoadPieData[1] + newLoadPieData[2]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotals]);

  // 初期表示崩れ対応
  useEffect(() => {
    if (!unmounted.current) {
      setIsLoading(false);
    }

    return () => {
      unmounted.current = true;
    };
  }, []);

  // グラフデータの読み込み
  useEffect(() => {
    const fn = () => {
      void UpdateData();
      void UpdateGovernmentDatas();
    };
    fn();

    clearInterval(intervalId.current as number);
    intervalId.current = setInterval(fn, AUTO_UPDATE_TIME);

    return () => {
      clearInterval(intervalId.current as number);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usages, toDate]);

  // 人数に対応したマーカーとフォントのサイズ変更
  useEffect(() => {
    let MarkerSize = '50px';
    let fontSize = 1.5;
    if (Count >= 100 && Count < 200) {
      MarkerSize = '75px';
      fontSize = 1.5;
    } else if (Count >= 200 && Count < 999) {
      MarkerSize = '100px';
      fontSize = 2.0;
    } else if (Count >= 1000) {
      MarkerSize = '100px';
      fontSize = 1.5;
    }

    if (FixFontSize !== undefined && !IsMap) {
      MarkerSize = '100%';
    }

    if (FixMarkerSize !== undefined) {
      MarkerSize = FixMarkerSize;
    }

    if (!unmounted.current) {
      setCountFontSize(FixFontSize === undefined ? `${fontSize}em` : FixFontSize);
      setMarkerSize(MarkerSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Count, IsMap, FixFontSize]);

  // pieData変更に伴うCountの更新
  useEffect(() => {
    let newCount = 0;
    if (pieData) {
      pieData.forEach((data) => {
        newCount += data;
      });

      if (!unmounted.current) {
        setCount(newCount);
      }
    }
  }, [pieData]);

  // restroomが存在する場合は紐づくデータを取得
  useEffect(() => {
    if (restroom === null || restroom === undefined) {
      return;
    }

    // restroomに紐づくデータの更新処理
    const Update = async () => {
      const PromiseAll = [];
      // 使用用途
      const toilet = new Toilet();
      for (let i = 0; i < restroom.devices?.items.length; i += 1) {
        const device = restroom.devices?.items[i];
        PromiseAll.push(toilet.Add(device.deviceId));
      }
      await Promise.all(PromiseAll);

      const newUsages = toilet.GetUsages();
      const newIsAnyOneDisconnected = toilet.IsAnyOneDisconnected();
      if (!unmounted.current) {
        setUsages(newUsages);
        setIsAnyOneDisconnected(newIsAnyOneDisconnected);
      }
    };

    const fn = () => void Update();
    fn();
  }, [restroom]);

  // 地図の表示範囲変更
  useEffect(() => {
    if (mapBounds === undefined || lat === undefined || lng === undefined) {
      return;
    }

    // 表示範囲で無ければ非表示
    let newIsMapBoundsView = false;
    if (lat < mapBounds.ne.lat && lat > mapBounds.sw.lat && lng < mapBounds.ne.lon && lng > mapBounds.sw.lon) {
      newIsMapBoundsView = true;
    }

    if (!unmounted.current) {
      setIsMapBoundsView(newIsMapBoundsView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapBounds]);

  if (isLoading) {
    return <div />;
  }

  if (!IsView) {
    return <div />;
  }

  if (!isMapBoundsView) {
    return <div />;
  }

  // マップに描画しない場合はリンク無し、トイレWiondowなし
  if (!IsMap) {
    return (
      <section css={markerInlineCss} style={{ width: `${markerSize}`, height: `${markerSize}` }}>
        <CreateMarker />
      </section>
    );
  }

  return (
    <section
      css={
        isAnyOneDisconnected
          ? css`
              ${markerCss}
              ${cssAnyOneDisconnected}
            `
          : markerCss
      }
      className={`${isAnyOneDisconnected && `anyone-disconnected`}`}
      style={{ width: `${markerSize}`, height: `${markerSize}` }}
    >
      {IsToiletWindow && warnings && (
        <ToiletWindowContainer
          subTotals={subTotals}
          parent={ref.current}
          topType={topType.topType}
          restroom={restroom}
          warnings={warnings}
          iconType={iconType}
          usages={usages}
          mapZoomLevel={mapZoomLevel}
          dispTwZoomLevel={dispTwZoomLevel}
        />
      )}
      {IsLink ? (
        <Link onClick={onClick} to={{ pathname: '/detail', state: { restroom } }}>
          <CreateMarker />
        </Link>
      ) : (
        <CreateMarker />
      )}
    </section>
  );
};

/**
 * トイレデータをマーカーデータに変換
 *
 * @param {RestroomItems} restroom トイレデータ
 * @return {MarkerProps} マーカーデータ
 */
export const ConvertRestroomToMarker = (restroom: RestroomItems): MarkerProps => {
  const ret: MarkerProps = {
    lat: restroom.location.lat,
    lng: restroom.location.lon,
    restroom,
  };

  return ret;
};
