/**
 * 検索日付to
 */
import { createContext, useContext } from 'react';

export type ChengeState = 'top' | 'detail';

export type ToDateParams = {
  top: string;
  detail: string;
};

type ToDateContextParams = {
  toDates: ToDateParams;
  setToDate: (newToDate: string, state?: ChengeState) => void;
};
export const ToDateContext = createContext<ToDateContextParams>({} as ToDateContextParams);
export const useToDate = (): ToDateContextParams => useContext(ToDateContext);
