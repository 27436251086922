/**
 * アプリケーション設定ファイル
 */
import iconMen from 'images/icon/men.png';
import iconWomen from 'images/icon/women.png';
import iconMulti from 'images/icon/multipurpose.png';
import iconCO2 from 'images/icon/CO2.png';
import iconHumidity from 'images/icon/humidity.png';
import iconLavatory from 'images/icon/lavatory.png';
import iconOstomate from 'images/icon/ostomate.png';
import iconTemperature from 'images/icon/temperature.png';
import iconToiletBowl from 'images/icon/toiletBowl.png';
import iconUrinal from 'images/icon/urinal.png';
import icon01d from 'images/icon/weather/01d.png';
import icon02d from 'images/icon/weather/02d.png';
import icon03d from 'images/icon/weather/03d.png';
import icon04d from 'images/icon/weather/04d.png';
import icon09d from 'images/icon/weather/09d.png';
import icon10d from 'images/icon/weather/10d.png';
import icon11d from 'images/icon/weather/11d.png';
import icon13d from 'images/icon/weather/13d.png';
import icon50d from 'images/icon/weather/50d.png';
import iconWhiteMan from 'images/icon/toilet_usage/white_man.png';
import iconWhiteWomen from 'images/icon/toilet_usage/white_women.png';
import iconWhiteMulti from 'images/icon/toilet_usage/white_multi.png';
import iconDirt from 'images/icon/alert/dirt.png';
import iconRepair from 'images/icon/alert/repair.png';
import iconWarning from 'images/icon/alert/warning.png';
import iconGood from 'images/icon/alert/good.png';

// バージョン
export const VERSION = '1.6.3';

// 自動更新間隔
export const AUTO_UPDATE_TIME = 1000 * 60 * 3;

// 天気更新間隔
export const AUTO_UPDATE_WEATHER = 1000 * 60 * 60 * 3;

// トイレWindowが非表示になるズームレベルのデフォルト値
export const DISP_TOILET_WINDOW_ZOOM_LEVEL = 16;

// ユーザーカスタム属性から初期座標が取得できなかった場合のデフォルト座標
export const DEFAULT_COORDINATE = '35.683532,139.752854';

/** @type {string} GoogleAPIのキー */
export const GOOGLEMAP_API_KEY = (() => {
  switch (window.location.hostname) {
    case 'localhost':
      return 'AIzaSyABywy_s7IOI7wCgdddG6cIY9ubZlIV_bU';
    default:
      return 'AIzaSyCIiA3xD4k9OwBsM8ClJkwKQcESZtn60ZM';
  }
})();

// タイマー色
export const TIMER_COLOR = '#ff457a';
export const TIMER_COLOR_NOW = '#55ffff';

// グラフの色
const CHART_COLOR_MALE = '#43a0ff';
const CHART_COLOR_FEMALE = '#d51f57';
const CHART_COLOR_MULTI = '#91d207';
const CHART_COLOR_TOTAL = '#e15d15';

// グラフ色の連想配列
export const GraphColor = {
  male: CHART_COLOR_MALE,
  female: CHART_COLOR_FEMALE,
  multi: CHART_COLOR_MULTI,
  total: CHART_COLOR_TOTAL,
};

// グラフ色の用途別
export const GraphColorByUsage = [CHART_COLOR_MALE, CHART_COLOR_MALE, CHART_COLOR_FEMALE, CHART_COLOR_MULTI];

// 種類
export type ToiletType = 1 | 2 | 3 | 4 | 5 | 6;
export const toiletTypeText = ['', '小便器', '大便器', '手洗器', 'Co2', 'ｵｽﾄﾒｲﾄ', 'データなしペイロード'];

// 画像アイコン
type ImageIconType = 'Co2' | 'Humidity' | 'Lavatory' | 'Ostomate' | 'Temperature' | 'ToiletBowl' | 'Urinal';
export const imageIcons: {
  [key in ImageIconType]: string;
} = {
  Co2: iconCO2,
  Humidity: iconHumidity,
  Lavatory: iconLavatory,
  Ostomate: iconOstomate,
  Temperature: iconTemperature,
  ToiletBowl: iconToiletBowl,
  Urinal: iconUrinal,
};

// 使用用途
export type Usage = 1 | 2 | 3;
export const usageText = ['', '男性', '女性', '多目的'];
export const usageReg = '[123]';

// 用途アイコン
export const usageIcon = [iconMen, iconMen, iconWomen, iconMulti];
export const usageIconWhite = [iconWhiteMan, iconWhiteMan, iconWhiteWomen, iconWhiteMulti];

// 背景色
export const usegeBackColor = ['', '#daf0fc', '#ffeeff', '#17c53c'];

// アイコンの色
export const usageIconColor = ['', '#222aff', '#ff156b', 'rgb(3 121 28)'];

// データ一覧の色
export const usageListColor: {
  Title: string;
  Column: string;
}[] = [
  {
    Title: '',
    Column: '',
  },
  {
    Title: '#3b9cff',
    Column: '#222aff',
  },
  {
    Title: '#ff80c0',
    Column: '#ff3d84',
  },
  {
    Title: '#17c53c',
    Column: '#17c53c',
  },
];

// 警告番号色
export const warningColors = {
  warning_100: {
    backColor: { from: '#ffd165', to: '#ffd165' },
    textColor: '#ffd165',
  },
  warning_200: {
    backColor: { from: '#f0ff6b', to: '#f0ff6b' },
    textColor: '#f0ff6b',
  },
  warning_300: {
    backColor: { from: '#D190DC', to: '#D190DC' },
    textColor: '#D190DC',
  },
};

// 使用数に応じた背景色
export const numberBackColors = [
  [],
  [
    { from: '#FFFFFF', to: '#FFF' },
    { from: '#ecebff', to: '#ecebff' },
    { from: '#c7cfff', to: '#c7cfff' },
    { from: '#92a6fc', to: '#92a6fc' },
    { from: '#4775ff', to: '#4775ff' },
    { from: '#1f00d6', to: '#1f00d6' },
  ],

  [
    { from: '#FFFFFF', to: '#FFF' },
    { from: '#ffe0dd', to: '#ffe0dd' },
    { from: '#ffa0a5', to: '#ffa0a5' },
    { from: '#ff7a8d', to: '#ff7a8d' },
    { from: '#ff477e', to: '#ff477e' },
    { from: '#f2006f', to: '#f2006f' },
  ],

  [
    { from: '#FFFFFF', to: '#FFF' },
    { from: '#a4eaa5', to: '#a4eaa5' },
    { from: '#63db61', to: '#63db61' },
    { from: '#0edd6b', to: '#0edd6b' },
    { from: '#0cb458', to: '#0cb458' },
    { from: '#136426', to: '#136426' },
  ],
];
// 使用数に応じた文字色
export const numberTextColors = [
  [],
  ['#000', '#000', '#000', '#000', '#000', '#FFF'],
  ['#000', '#000', '#000', '#000', '#000', '#FFF'],
  ['#000', '#000', '#000', '#000', '#000', '#FFF'],
];

// アラートアイコン
export type AlertIconType = 'dirt' | 'repair' | 'warning' | 'good';
export const alertIcon: {
  [key in AlertIconType]: string;
} = {
  dirt: iconDirt,
  repair: iconRepair,
  warning: iconWarning,
  good: iconGood,
};
export const alertColor: {
  [key in AlertIconType]: string;
} = {
  dirt: '#7512a5',
  repair: '#ff7e02',
  warning: '#c1bf02',
  good: '#33cede',
};

// 天気アイコン
export type weatherIconType =
  | '01n'
  | '02n'
  | '03n'
  | '04n'
  | '09n'
  | '10n'
  | '11n'
  | '13n'
  | '50n'
  | '01d'
  | '02d'
  | '03d'
  | '04d'
  | '09d'
  | '10d'
  | '11d'
  | '13d'
  | '50d';
export const weatherIcons: {
  [key in weatherIconType]: string;
} = {
  '01n': icon01d,
  '01d': icon01d,
  '02n': icon02d,
  '02d': icon02d,
  '03n': icon03d,
  '03d': icon03d,
  '04n': icon04d,
  '04d': icon04d,
  '09n': icon09d,
  '09d': icon09d,
  '10n': icon10d,
  '10d': icon10d,
  '11n': icon11d,
  '11d': icon11d,
  '13n': icon13d,
  '13d': icon13d,
  '50n': icon50d,
  '50d': icon50d,
};
