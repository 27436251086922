/**
 * アニメーション定義
 */
// フェード
export const fadeIn = {
  end: {
    opacity: 0,
    transition: {
      duration: 0.25,
    },
  },
  start: {
    opacity: 0.5,
    transition: {
      duration: 0.25,
    },
  },
  init: {
    opacity: 0,
  },
};

export const modal = {
  end: {
    scale: 0,
    translateX: '-50%',
    translateY: '-50%',
    transition: {
      duration: 0.25,
    },
  },
  start: {
    scale: 1,
    translateX: '-50%',
    translateY: '-50%',
    transition: {
      duration: 0.25,
    },
  },
  init: {
    scale: 0,
    translateX: '-50%',
    translateY: '-50%',
  },
};

// フェードで透明度なし
export const fadeInView = {
  end: {
    opacity: 0,
    transition: {
      duration: 0.25,
    },
  },
  start: {
    opacity: 1,
    transition: {
      duration: 0.25,
    },
  },
  init: {
    opacity: 0,
  },
};

// スライド
export const slideIn = {
  end: {
    x: '-100%',
    transition: {
      duration: 0.25,
    },
  },
  start: {
    x: 0,
    transition: {
      duration: 0.25,
    },
  },
  init: {
    x: '-100%',
  },
};

// スライド
export const slideInRight = {
  end: {
    x: '120%',
    transition: {
      duration: 0.2,
    },
  },
  start: {
    x: 0,
    transition: {
      duration: 0.2,
    },
  },
  init: {
    x: '120%',
  },
};
