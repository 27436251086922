/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';
import { weatherIcons, weatherIconType } from 'utils/AppConfig';

const cssWeatherFolder = css`
  position: relative;
  display: inline-block;
  width: 105px;
  height: 65px;
  pointer-events: auto;
  user-select: none;

  span {
    position: absolute;
    left: 52px;
    width: 51px;
    color: #fff;
    text-align: center;
  }

  .temperature {
    top: 18px;
    font-size: 10px;
  }

  .humidity {
    top: 41px;
    font-size: 11px;
  }

  .icon {
    position: absolute;
    top: 26px;
    left: 4px;
    width: 28px;
    height: auto;
  }
`;

/**
 * 天気フォルダー
 *
 * @param {string} folder フォルダ画像
 * @param {number} tmpMin 最低気温
 * @param {number} tmpMax 最高気温
 * @param {number} humidity 湿度
 * @param {weatherIconType} icon 天気タイプ
 */
type WeatherFolderProps = {
  folder: string;
  tempMin: number;
  tempMax: number;
  humidity: number;
  icon: weatherIconType;
};
export const WeatherFolder: FC<WeatherFolderProps> = ({ folder, tempMin, tempMax, humidity, icon }) => {
  const cssWeatherFolderUpdate = css`
    ${cssWeatherFolder}
    background: url(${folder}) no-repeat center center;
    background-size: 100% auto;
  `;

  return (
    <section css={cssWeatherFolderUpdate}>
      <span className="temperature">{`${Math.round(tempMin)}-${Math.round(tempMax)}℃`}</span>
      <span className="humidity">{`${Math.round(humidity)}%`}</span>
      <img className="icon" alt="icon" src={weatherIcons[icon]} />
    </section>
  );
};
