/** @jsx jsx */
/**
 * メニュー
 */
import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { jsx, css } from '@emotion/core';
import { VERSION } from 'utils/AppConfig';
import { Overlay } from 'components/Overlay';
import { motion, AnimatePresence } from 'framer-motion';
import { slideInRight } from 'utils/AnimationVariants';
import { Auth } from 'aws-amplify';
import { useSystemSettingOpen } from 'custom_hook/useSystemSettingOpen';

const cssMenu = css`
  position: absolute;
  top: 78px;
  right: 15px;
  z-index: 1001;
  width: 200px;
  padding: 10px 25px;
  background: rgba(2, 29, 60, 0.7);

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    font-size: 17px;
    color: #fff;
    list-style: none;
    user-select: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 42px;
      padding: 11px 0;
      cursor: pointer;

      a {
        width: 100%;
        height: 100%;
        text-align: center;
        text-decoration: none;
      }

      a:link,
      a:visited,
      a:hover,
      a:active {
        color: #fff;
      }

      button {
        width: 100%;
        height: 100%;
        padding: 0;
        font-family: 'DSEG7ModernMini-Regular', sans-serif;
        color: inherit;
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
        appearance: none;
      }
    }

    li + li {
      border-top: solid 1px #fff;
    }
  }

  .no-link {
    cursor: auto;
  }

  .alertmail {
    color: rgb(255 255 255);
    opacity: 0.2;
  }

  .alertmail.on {
    opacity: 0.9;
  }

  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
`;

/**
 * メニュー
 *
 */
export interface MenuHandler {
  setIsView: (newIsView?: boolean) => void;
}
type MenuProps = {
  initView?: boolean;
};
export const Menu = forwardRef<MenuHandler, MenuProps>(({ initView = false }, ref) => {
  const [isView, setIsView] = useState<boolean>(initView);
  const unmounted = useRef(false);
  const systemSetting = useSystemSettingOpen();

  const handleOverlayClick = () => {
    if (!unmounted.current) {
      setIsView(false);
    }
  };

  // サインアウト
  const SignOut = async () => {
    await Auth.signOut();
    window.location.href = '/';
  };

  // システム設定ボタン
  const handleClickSystemSettingOpen = () => {
    systemSetting.toggle();
    if (!unmounted.current) {
      setIsView(false);
    }
  };

  useImperativeHandle(ref, () => ({
    setIsView: (newIsView?: boolean) => {
      if (!unmounted.current) {
        setIsView(newIsView === undefined ? !isView : newIsView);
      }
    },
  }));

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <React.Fragment>
      <Overlay isOpen={isView} isTransparent onClick={handleOverlayClick} />
      <AnimatePresence initial={false}>
        {isView && (
          <motion.div css={cssMenu} initial="init" animate="start" exit="end" variants={slideInRight}>
            <ul>
              <li>
                <a target="_blank" href="/content/SocialToiletSystem操作マニュアルWEB.pdf">
                  操作マニュアル
                </a>
              </li>
              <li>
                <button type="button" aria-label="systemsetting" onClick={handleClickSystemSettingOpen}>
                  システム設定
                </button>
              </li>
              <li>
                <span>FAQ</span>
              </li>
              <li>
                <button type="button" aria-label="sign-out" onClick={() => SignOut()}>
                  サインアウト
                </button>
              </li>
              <li className="no-link">Ver.{VERSION}</li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
});
