import { createContext, useContext } from 'react';

export type SystemSettingOpenParams = {
  isShow: boolean;
  setIsShow: (newIsShow: boolean) => void;
  toggle: () => void;
};

export const SystemSettingOpenContext = createContext<SystemSettingOpenParams>({} as SystemSettingOpenParams);
export const useSystemSettingOpen = (): SystemSettingOpenParams => useContext(SystemSettingOpenContext);
