import React, { FC, useEffect, useState, useRef } from 'react';
import Amplify from 'aws-amplify';
import awsExports from 'aws-exports';
import { GraphQLResult } from '@aws-amplify/api';
import { listCalcDatasByRestroomId, listClipHgSubtotals, listUseCountsByRestroomId } from 'graphql/queries';
import {
  ListCalcDatasByRestroomIdQueryVariables,
  ListCalcDatasByRestroomIdQuery,
  CreateClipHgCalcDataInput,
  ModelSortDirection,
  CreateClipHgSubtotalInput,
  ListClipHgSubtotalsQuery,
  ListClipHgSubtotalsQueryVariables,
  ListUseCountsByRestroomIdQueryVariables,
  ListUseCountsByRestroomIdQuery,
  CreateClipHgUseCountInput,
  CreateClipHgToiletInput,
} from 'API';
import { Detailinfo, UseCounts, SearchDatetimes } from 'components/Detailinfo';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { useRestroom } from 'custom_hook/useRestroom';
import { ToiletType, Usage } from 'utils/AppConfig';
import { GetWarningItems } from 'containers/WarningList';
import { ConvertClipHgWarningInput, GetWarningRange } from 'utils/Warnings';
import { useLocation } from 'react-router';
import { Location } from 'utils/Location';
import { Redirect } from 'react-router-dom';
import { LastestLog } from 'utils/Log';
import { ApiGraphqlOperationWrapper, IsNumber } from 'utils/Common';
import Toilet from 'utils/Toilet';
import { useToDate } from 'custom_hook/useToDate';
import { useAuth } from 'custom_hook/useAuth';

Amplify.configure(awsExports);
dayjs.extend(isToday);

// calcDataのCo2キー
const CALCDATA_CO2_KEY = 'Co2';

// calcDataの時間キー固定文字列
const KEY_TEXT_TIMELINE = 'Time';

// 詳細画面データ連想配列のキー固定文字列
const KEY_TEXT = 'toilet';

// コンポーネントに渡すデータの中身
type CalcData = {
  sortkey: string;
  title: {
    name: string;
    warning: number;
    isConnected: boolean;
  };
  total: { count: number };
  values: {
    [name: string]: {
      count: number;
      warning: number;
      key: string;
      carbonDioxide?: string;
      temperature?: string;
      humidity?: string;
    };
  };
  co2Warnings: {
    carbonDioxide: number;
    temperature: number;
    humidity: number;
  };
  searchDateFrom?: string;
};

// コンポーネントに渡すデータ
export const SEARCH_DATE_FROM_KEY = 'searchDateFrom';
export type CalcDatas = {
  [id: string]: CalcData;
};

// calcDataの初期化データ
const InitCalcData: CalcData = {
  sortkey: '',
  title: {
    name: '',
    warning: 0,
    isConnected: false,
  },
  total: { count: 0 },
  values: {},
  co2Warnings: {
    carbonDioxide: 0,
    temperature: 0,
    humidity: 0,
  },
};

// calcDataの時間別データの初期化データ
const InitCalcDataVAlues = {
  count: 0,
  warning: 0,
  carbonDioxide: '-',
  temperature: '-',
  humidity: '-',
};

/**
 * nullとundefinedのチェック
 *
 * @return {boolean}  nullでもundefinedでも無ければtrue
 */
const IsUse = <T,>(val: T | undefined | null): boolean => {
  if (val === undefined) {
    return false;
  }
  if (val === null) {
    return false;
  }

  return true;
};

/**
 * calcデータのCo2の値を取得する
 *
 * @param {(number | undefined | null)} val calcからの生データ
 * @param {(string | undefined | null)} srcdefault valが使用できない場合のデフォルト値
 * @return {string}  Co2の値
 */
const GetCo2Value = (val: number | undefined | null, srcdefault: string | undefined | null): string => {
  const dstDefault = srcdefault === undefined || srcdefault === null ? '-' : srcdefault;

  if (val === undefined || val === null) {
    return dstDefault;
  }

  return Math.round(val).toString();
};
const GetCo2ValueByMax = (val: number | undefined | null, srcdefault: string | undefined | null): string => {
  const dstDefault = srcdefault === undefined || srcdefault === null ? '-' : srcdefault;

  if (val === undefined || val === null || !IsNumber(val.toString())) {
    return dstDefault;
  }

  // デフォルト値が - の場合はvalを返す
  if (!IsNumber(dstDefault)) {
    return Math.floor(val).toString();
  }

  // 大きい方を返す
  const newVal = Math.floor(val);

  return newVal > parseInt(dstDefault, 10) ? newVal.toString() : dstDefault;
};

/**
 * 詳細画面データのキーを取得
 * toilet_usage[用途]_type[種類]_number[便器番号]_dev[デバイスID]
 *
 * @param {Usage}       usage    用途
 * @param {ToiletType}  type     種類
 * @param {number}      num      便器番号
 * @param {string}      deviceId デバイスID
 * @return {string}     詳細画面データ連想配列のキー
 */
export const getDetailDataKey = (
  usage: Usage | number,
  type?: ToiletType | number | null,
  num?: number | null,
  deviceId?: string | null,
): string => {
  if (IsUse<string>(deviceId) && IsUse<number>(num) && IsUse<number>(type)) {
    return `${KEY_TEXT}_usage${usage}_type${type}_number${num}_dev${deviceId}`;
  }
  if (IsUse<number>(num) && IsUse<number>(type)) {
    return `${KEY_TEXT}_usage${usage}_type${type}_number${num}`;
  }
  if (IsUse<number>(type)) {
    return `${KEY_TEXT}_usage${usage}_type${type}`;
  }

  return `${KEY_TEXT}_usage${usage}`;
};

/**
 * 詳細画面タイムラインデータのキーを取得
 *
 * @param {Usage} usage 用途
 */
export const getDetailDataKeyByTimeline = (usage: Usage | number): string => {
  return `${KEY_TEXT_TIMELINE}_usage${usage}`;
};

/**
 * 詳細画面Co2データのキーを取得
 *
 * @param {Usage} usage 用途
 */
export const getDetailDataKeyByCo2 = (usage: Usage | number): string => {
  return `${CALCDATA_CO2_KEY}_usage${usage}`;
};

/**
 * デバイスIDから詳細画面データのキーを取得
 *
 * @param {string} deviceId デバイスID
 * @param {CalcDatas} datas 詳細画面データ
 * @return {string[]} 詳細画面データのキー配列
 */
const SearchDetailDataKeyByDeviceId = (deviceId: string, datas: CalcDatas): string[] => {
  const ret: string[] = [];
  const serach = new RegExp(`${KEY_TEXT}_.*_dev${deviceId}`);

  // eslint-disable-next-line array-callback-return
  Object.keys(datas).map((key) => {
    if (serach.exec(key)) {
      ret.push(key);
    }
  });

  return ret;
};

/**
 * 時間データのキーを取得
 * value[2桁のゼロ埋めされた時間]
 *
 * @param {number}  date 日時
 * @return {string} 時間データのキー
 */
export const getCalcValueKey = (date: string): string => {
  const key = dayjs(date).format('YYYYMMDDHH');

  return `value_${key}`;
};

/**
 * CalcDatasキー検索
 *
 * @param {calcDatas}   datas
 * @param {Usage}       usage    使用用途
 * @param {ToiletType}  type     種類
 * @return {string[]}  calcDatasのキー
 */
export const searchCalcDatasKeys = (datas: CalcDatas, usage: Usage, type?: ToiletType): string[] => {
  const search = getDetailDataKey(usage, type);
  const temp: {
    key: string;
    sortkey: string;
  }[] = [];
  Object.keys(datas).forEach((key) => {
    if (key.indexOf(search) >= 0) {
      temp.push({
        key,
        sortkey: datas[key].sortkey,
      });
    }
  });

  // sortKeyで並び替え
  return temp
    .sort((a, b) => {
      if (a.sortkey < b.sortkey) return -1;
      if (a.sortkey > b.sortkey) return 1;

      return 0;
    })
    .map((item) => {
      return item.key;
    });
};

/**
 * 詳細画面コンテナ
 */
const DetailinfoContainer: FC = () => {
  const unmounted = useRef(false);
  const [detailDatas, setDetailDatas] = useState<CalcDatas | null>(null);
  const [isRedirectTop, setIsRedirectTop] = useState(false);
  const [usages, setUsages] = useState<Usage[]>([]);
  const restroom = useRestroom();
  const location = useLocation() as Location;
  const [error, setError] = useState<string>('');
  const toDate = useToDate();
  const [toiletByRestroom, setToiletByRestroom] = useState<CreateClipHgToiletInput[]>([]);
  const auth = useAuth();

  // 検索日時取得
  const GetSearchDatetime = (): SearchDatetimes => {
    const to = dayjs(toDate.toDates.detail).format('YYYY-MM-DD HH:59:59');
    const from = dayjs(to).subtract(23, 'hours').format('YYYY-MM-DD HH:00:00');
    const subTotalToByWeek = dayjs(to).subtract(1, 'days').format('YYYYMMDD');
    const subTotalToByDay = dayjs(to).subtract(1, 'hours').format('YYYYMMDDHH');
    const subTotalToByDay3 = dayjs(to).subtract(1, 'hours').format('YYYYMMDDHH');
    const subTotalToByMonth = dayjs(to).subtract(1, 'days').format('YYYYMMDD');

    return {
      calc: { to, from },
      subtotal: {
        week: {
          to: subTotalToByWeek,
          from: dayjs(subTotalToByWeek).subtract(6, 'days').format('YYYYMMDD'),
        },
        day: {
          to: subTotalToByDay,
          from: dayjs(subTotalToByDay).subtract(23, 'hours').format('YYYYMMDDHH'),
        },
        day3: {
          to: subTotalToByDay3,
          from: dayjs(subTotalToByDay3).subtract(23, 'hours').subtract(2, 'days').format('YYYYMMDDHH'),
        },
        month: {
          to: subTotalToByMonth,
          from: dayjs(subTotalToByMonth).subtract(29, 'days').format('YYYYMMDD'),
        },
      },
    };
  };

  const [dates, setDates] = useState<SearchDatetimes>(GetSearchDatetime());

  // デバイスに紐づくcalcデータを取得
  const QueryFromCalc = async (
    restroomId: string,
    srcDates?: SearchDatetimes,
  ): Promise<CreateClipHgCalcDataInput[]> => {
    const useDates = srcDates ?? dates;
    let ret: CreateClipHgCalcDataInput[] = [];
    let nextToken: string | null | undefined = null;

    // deviceId:デバイスID 取得範囲:本日 ソート:昇順
    const conditions: ListCalcDatasByRestroomIdQueryVariables = {
      restroomId,
      sendDateTime: {
        between: [useDates.calc.from, useDates.calc.to],
      },
      limit: 1440,
      sortDirection: ModelSortDirection.ASC,
      nextToken: null,
    };

    try {
      do {
        conditions.nextToken = nextToken;

        // eslint-disable-next-line no-await-in-loop
        const result = await ApiGraphqlOperationWrapper<
          ListCalcDatasByRestroomIdQueryVariables,
          GraphQLResult<ListCalcDatasByRestroomIdQuery>
        >(conditions, listCalcDatasByRestroomId);

        nextToken =
          undefined === result.data?.listCalcDatasByRestroomId?.nextToken
            ? null
            : result.data?.listCalcDatasByRestroomId?.nextToken;

        const items = (result.data?.listCalcDatasByRestroomId?.items as unknown) as CreateClipHgCalcDataInput[];
        ret = [...ret, ...items];
      } while (nextToken !== null);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('[QueryFromCalc]', e);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[QueryFromCalc] ${(e as { message: string })?.message}`);
    }

    return ret;
  };

  // 日付変更
  useEffect(() => {
    const newDates = GetSearchDatetime();
    if (!unmounted.current) {
      setDates(newDates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate.toDates.detail]);

  // トイレデータを最新にする
  useEffect(() => {
    // リロード対応
    const func = async () => {
      if (!restroom.restroom) {
        if (location?.state) {
          if (location.state.restroom) {
            const ret = await restroom.Update(location.state.restroom.restroomId);
            if (!ret) {
              if (!unmounted.current) {
                setIsRedirectTop(true);
              }
            }
          } else {
            restroom.setRestroom(location.state.restroom);
          }
        } else if (!unmounted.current) {
          setIsRedirectTop(true);
        }
      } else {
        const ret = await restroom.Update();
        if (!ret) {
          if (!unmounted.current) {
            setIsRedirectTop(true);
          }
        }
      }
    };

    // ログアウト不要でisAdminを使用するためAuth再読み込み
    auth.updateUser();

    void func();

    return () => {
      unmounted.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isRedirectTop) {
    return <Redirect to="/" />;
  }

  // トイレ単位の詳細データ
  const getToiletDetail = async (srcDates?: SearchDatetimes) => {
    if (!unmounted.current) {
      setError('');
    }
    if (restroom?.restroom === undefined) {
      return;
    }

    if (error.length > 0) {
      return;
    }

    const useDates = srcDates ?? dates;

    // 警告番号が更新可能かどうか
    const IsWarningUpdate = (srcWarningNumber: number, warning: ConvertClipHgWarningInput) => {
      if (warning === undefined || warning === null) {
        return false;
      }

      // 日付チェック
      const searchDate = dayjs(warning.sendDateTime).unix();
      if (!(dayjs(useDates.calc.from).unix() <= searchDate && dayjs(useDates.calc.to).unix() >= searchDate)) {
        return false;
      }

      if (srcWarningNumber === 0) {
        return true;
      }

      if (srcWarningNumber > 0) {
        if (srcWarningNumber > warning.warningNumber) {
          return true;
        }
      }

      return false;
    };

    const PromiseAll = [];

    // 便器データ
    const toilet = new Toilet();
    for (let i = 0; i < restroom.restroom.devices?.items.length; i += 1) {
      const device = restroom.restroom.devices?.items[i];
      PromiseAll.push(toilet.Add(device.deviceId));
    }

    // calcデータ
    let calcItems: CreateClipHgCalcDataInput[] = [];
    PromiseAll.push(
      QueryFromCalc(restroom.restroom.restroomId).then((result) => {
        calcItems = result;
      }),
    );

    // ワーニング
    let warningItems: ConvertClipHgWarningInput[] = [];
    PromiseAll.push(
      GetWarningItems(restroom.restroom.restroomId, auth.user, useDates.calc.to, useDates.calc.from).then((result) => {
        warningItems = result;
      }),
    );

    await Promise.all(PromiseAll);

    // トイレ全データ
    const toilets = toilet.GetArrayAll();

    // DBデータから表示データ作成
    let toiletDatas: CalcDatas = {};

    // 検索日時の保持
    toiletDatas[SEARCH_DATE_FROM_KEY] = { ...InitCalcData };
    toiletDatas[SEARCH_DATE_FROM_KEY].searchDateFrom = dayjs(useDates.calc.from).format('YYYY-MM-DD HH:mm:ss');

    // デバイスとトイレデータから初期データを作成
    for (let i = 0; i < toilets.length; i += 1) {
      const key = getDetailDataKey(toilets[i].usage, toilets[i].type, toilets[i].number, toilets[i].deviceId);
      toiletDatas = { ...toiletDatas, ...{ [key]: { ...InitCalcData } } };
      toiletDatas[key].sortkey = toilets[i].name.replace(/(\d+)/g, (m) => m.padStart(30, '0')); // toilets[i].name;
      toiletDatas[key].title = {
        ...{ name: toilets[i].name, warning: 0, isConnected: toilets[i].isConnected ?? false },
      };

      // 時間別データの作成
      for (let j = 0; j < 24; j += 1) {
        const valKey = getCalcValueKey(dayjs(useDates.calc.from).add(j, 'hours').toString());
        toiletDatas[key].values = {
          ...toiletDatas[key].values,
          ...{ [valKey]: { ...{ ...InitCalcDataVAlues, key: `${key}_${valKey}` } } },
        };
      }
    }

    // Co2は別で作成
    for (let usage = 1; usage < 3 + 1; usage += 1) {
      const Co2Key = getDetailDataKeyByCo2(usage);
      toiletDatas = { ...toiletDatas, ...{ [Co2Key]: { ...InitCalcData } } };
      // 時間別データの作成
      for (let j = 0; j < 24; j += 1) {
        const valKey = getCalcValueKey(dayjs(useDates.calc.from).add(j, 'hours').toString());
        toiletDatas[Co2Key].values = {
          ...toiletDatas[Co2Key].values,
          ...{
            [valKey]: {
              ...{
                ...InitCalcDataVAlues,
                key: `${Co2Key}_${valKey}`,
              },
            },
          },
        };
      }

      // タイムラインデータ
      const timelineKey = getDetailDataKeyByTimeline(usage);
      if (!toiletDatas[timelineKey]) {
        toiletDatas = { ...toiletDatas, ...{ [timelineKey]: { ...InitCalcData } } };
        for (let j = 0; j < 24; j += 1) {
          const valKey = getCalcValueKey(dayjs(useDates.calc.from).add(j, 'hours').toString());
          toiletDatas[timelineKey].values = {
            ...toiletDatas[timelineKey].values,
            ...{ [valKey]: { ...{ ...InitCalcDataVAlues, key: `time_${timelineKey}_${valKey}` } } },
          };
        }
      }
    }

    // 作成した初期データにcalcを入れる
    for (let i = 0; i < calcItems.length; i += 1) {
      const calc = calcItems[i];

      // Co2データ
      if (calc.type === 4) {
        // Co2データ更新共通処理
        const UpdateCo2 = (usage: Usage, isCo2: boolean) => {
          const Co2Key = getDetailDataKeyByCo2(usage);
          const valueKey = getCalcValueKey(calc.sendDateTime);
          const nowValue = toiletDatas[Co2Key].values[valueKey];

          toiletDatas[Co2Key].values[valueKey] = {
            ...{
              count: 0,
              carbonDioxide: isCo2
                ? GetCo2ValueByMax(calc.carbonDioxide, nowValue.carbonDioxide)
                : nowValue.carbonDioxide,
              temperature: isCo2 ? nowValue.temperature : GetCo2Value(calc.temperature, nowValue.temperature),
              humidity: isCo2 ? nowValue.humidity : GetCo2Value(calc.humidity, nowValue.humidity),
              warning: nowValue.warning,
              key: nowValue.key,
            },
          };
        };

        // 温湿度は全てのusageに格納
        for (let usage = 1; usage < 3 + 1; usage += 1) {
          UpdateCo2(usage as Usage, false);
        }

        // CO2にはusageが含まれる
        if (
          calc.carbonDioxide !== undefined &&
          calc.carbonDioxide !== null &&
          calc.usage !== undefined &&
          calc.usage !== null
        ) {
          UpdateCo2(calc.usage as Usage, true);
        }
      }
      // 通常データ
      else if (
        (calc.type === 1 || calc.type === 2 || calc.type === 3 || calc.type === 5) &&
        IsUse<number>(calc.number) &&
        calc.usage !== undefined &&
        calc.usage !== null
      ) {
        const key = getDetailDataKey(calc.usage, calc.type, calc.number, calc.deviceId);

        if (toiletDatas[key]) {
          // sendDatetimeでvalueのkeyを作る
          const valueKey = getCalcValueKey(calc.sendDateTime);
          const count = calc.flushCount ? calc.flushCount : 0;
          const nowValue = toiletDatas[key].values[valueKey];

          toiletDatas[key].values[valueKey] = {
            ...{
              count: nowValue.count + count,
              warning: nowValue.warning,
              key: nowValue.key,
            },
          };
          toiletDatas[key].total = {
            ...{
              count: toiletDatas[key].total.count + count,
            },
          };
        }
      }
    }

    // ワーニングデータから使用する用途を取得
    const GetUsages = (warning: ConvertClipHgWarningInput): Usage[] => {
      const ret: Usage[] = [];
      // 用途が登録されている
      if (warning.usage) {
        ret.push(warning.usage as Usage);
        // デバイスIDが登録されている
      } else if (warning.deviceId) {
        const co2toilets = toilet.GetUsagesByDeviceId(warning.deviceId);
        for (let j = 0; j < co2toilets.length; j += 1) {
          ret.push(co2toilets[j]);
        }
        // 両方登録されていない場合は全て
      } else {
        ret.push(1);
        ret.push(2);
        ret.push(3);
      }

      return ret;
    };

    // ワーニングデータを追加
    for (let i = 0; i < warningItems.length; i += 1) {
      const warning = warningItems[i];
      const range = GetWarningRange(warning.warningNumber);
      const warningUsages = GetUsages(warning);

      // デバイス単位
      if (range === 'device' && warning.deviceId) {
        const keyList = SearchDetailDataKeyByDeviceId(warning.deviceId, toiletDatas);
        for (let j = 0; j < keyList.length; j += 1) {
          const key = keyList[j];
          // タイトル
          if (IsWarningUpdate(toiletDatas[key].title.warning, warning)) {
            toiletDatas[key].title = {
              ...{
                name: toiletDatas[key].title.name,
                warning: warning.warningNumber,
                isConnected: toiletDatas[key].title.isConnected,
              },
            };
          }

          /* 時間別利用回数に背景色を設定する場合はこのコメントを外す
          // 時間データ
          const valKey = getCalcValueKey(parseInt(dayjs(warning.sendDateTime).format('HH').toString(), 10));
          const nowValue = toiletDatas[key].values[valKey];
          if (IsWarningUpdate(nowValue.warning, warning)) {
            toiletDatas[key].values[valKey] = {
              ...{
                count: nowValue.count,
                key: nowValue.key,
                warning: warning.warningNumber,
              },
            };
          }
          */

          // タイムラインデータ
          const valKey = getCalcValueKey(warning.sendDateTime);
          for (let usageIndex = 0; usageIndex < warningUsages.length; usageIndex += 1) {
            const timelineKey = getDetailDataKeyByTimeline(warningUsages[usageIndex]);
            if (IsWarningUpdate(toiletDatas[timelineKey].values[valKey]?.warning, warning)) {
              const nowTime = toiletDatas[timelineKey].values[valKey];
              toiletDatas[timelineKey].values[valKey] = {
                ...{
                  count: nowTime.count,
                  key: nowTime.key,
                  warning: warning.warningNumber,
                },
              };
            }
          }
        }
      }

      // Co2
      // Co2はデータがまとめて来るため警告番号に応じて処理する
      else if (range === 'co2') {
        for (let usageIndex = 0; usageIndex < warningUsages.length; usageIndex += 1) {
          const usage = warningUsages[usageIndex];
          const timelineKey = getDetailDataKeyByTimeline(usage);
          const Co2Key = getDetailDataKeyByCo2(usage);

          const { co2Warnings } = toiletDatas[Co2Key];
          // ppm
          if (warning.warningNumber === 202) {
            // タイトル
            if (IsWarningUpdate(co2Warnings.carbonDioxide, warning)) {
              toiletDatas[Co2Key].co2Warnings = {
                ...{
                  carbonDioxide: warning.warningNumber,
                  temperature: co2Warnings.temperature,
                  humidity: co2Warnings.humidity,
                },
              };
            }
          } else if (warning.warningNumber === 303) {
            // タイトル
            if (IsWarningUpdate(co2Warnings.temperature, warning)) {
              toiletDatas[Co2Key].co2Warnings = {
                ...{
                  carbonDioxide: co2Warnings.carbonDioxide,
                  temperature: warning.warningNumber,
                  humidity: co2Warnings.humidity,
                },
              };
            }
          }

          /* 時間別利用回数に背景色を設定する場合はこのコメントを外す
          // 時間データ
          const valKey = getCalcValueKey(warning.sendDateTime);
          const nowValue = toiletDatas[Co2Key].values[valKey];
          if (IsWarningUpdate(nowValue.warning, warning)) {
            toiletDatas[Co2Key].values[valKey] = {
              ...{
                carbonDioxide: nowValue.carbonDioxide,
                humidity: nowValue.humidity,
                temperature: nowValue.temperature,
                key: nowValue.key,
                count: nowValue.count,
                warning: warning.warningNumber,
              },
            };
          }
          */

          // タイムラインデータ
          const valKey = getCalcValueKey(warning.sendDateTime);
          if (IsWarningUpdate(toiletDatas[timelineKey].values[valKey]?.warning, warning)) {
            const nowTime = toiletDatas[timelineKey].values[valKey];
            toiletDatas[timelineKey].values[valKey] = {
              ...{
                count: nowTime.count,
                key: nowTime.key,
                warning: warning.warningNumber,
              },
            };
          }
        }
      }

      // 便器単位
      else if (range === 'toilet' && warning.usage !== undefined && warning.usage !== null) {
        const type = IsUse<number>(warning.type) ? warning.type : 0;
        const number = IsUse<number>(warning.number) ? warning.number : 0;
        const key = getDetailDataKey(warning.usage, type, number, warning.deviceId);
        const timelineKey = getDetailDataKeyByTimeline(warning.usage);

        // タイトル
        if (toiletDatas[key]) {
          if (IsWarningUpdate(toiletDatas[key].title.warning, warning)) {
            toiletDatas[key].title = {
              ...{
                name: toiletDatas[key].title.name,
                warning: warning.warningNumber,
                isConnected: toiletDatas[key].title.isConnected,
              },
            };
          }
        }
        /* 時間別利用回数に背景色を設定する場合はこのコメントを外す
        // 時間データ
        const valKey = getCalcValueKey(warning.sendDateTime);
        const nowValue = toiletDatas[key].values[valKey];
        if (IsWarningUpdate(nowValue.warning, warning)) {
          toiletDatas[key].values[valKey] = {
            ...{
              count: nowValue.count,
              key: nowValue.key,
              warning: warning.warningNumber,
            },
          };
        }
        */

        // タイムラインデータ
        if (toiletDatas[key]) {
          const valKey = getCalcValueKey(warning.sendDateTime);
          if (IsWarningUpdate(toiletDatas[timelineKey].values[valKey]?.warning, warning)) {
            const nowTime = toiletDatas[timelineKey].values[valKey];
            toiletDatas[timelineKey].values[valKey] = {
              ...{
                count: nowTime.count,
                key: nowTime.key,
                warning: warning.warningNumber,
              },
            };
          }
        }
      }
      // 用途単位
      // ヒートマップには表示せずタイムラインのみ表示する
      else if (range === 'usage' && warning.usage !== undefined && warning.usage !== null) {
        const timelineKey = getDetailDataKeyByTimeline(warning.usage);

        // タイムラインデータ
        const valKey = getCalcValueKey(warning.sendDateTime);
        if (IsWarningUpdate(toiletDatas[timelineKey].values[valKey]?.warning, warning)) {
          const nowTime = toiletDatas[timelineKey].values[valKey];
          toiletDatas[timelineKey].values[valKey] = {
            ...{
              count: nowTime.count,
              key: nowTime.key,
              warning: warning.warningNumber,
            },
          };
        }
      }
    }

    // データ更新
    if (!unmounted.current) {
      setDetailDatas(toiletDatas);
    }

    // 使用用途更新
    const tmp = toilets.map((item) => item.usage) as Usage[];
    const newUsage = tmp?.filter((value, index) => {
      return tmp.indexOf(value) === index;
    });
    newUsage.sort();
    if (!unmounted.current) {
      setUsages(newUsage);
    }

    // トイレデータ保持
    if (!unmounted.current) {
      setToiletByRestroom(toilets);
    }
  };

  // subTotalデータ
  const getSubTotalDatas = async (fromYYYYMMDDHH?: string, toYYYYMMDDHH?: string) => {
    const from = fromYYYYMMDDHH || dates.subtotal.day.from;
    const to = toYYYYMMDDHH || dates.subtotal.day.to;
    let ret: CreateClipHgSubtotalInput[] = [];
    let nextToken: string | null | undefined = null;

    // deviceId:デバイスID 取得範囲:時間降順
    const conditions: ListClipHgSubtotalsQueryVariables = {
      restroomId: restroom.restroom?.restroomId,
      period: {
        between: [from, to],
      },
      sortDirection: ModelSortDirection.ASC,
      nextToken: null,
    };

    try {
      do {
        conditions.nextToken = nextToken;

        // eslint-disable-next-line no-await-in-loop
        const result = await ApiGraphqlOperationWrapper<
          ListClipHgSubtotalsQueryVariables,
          GraphQLResult<ListClipHgSubtotalsQuery>
        >(conditions, listClipHgSubtotals);

        nextToken =
          undefined === result.data?.listClipHgSubtotals?.nextToken
            ? null
            : result.data?.listClipHgSubtotals?.nextToken;

        const items = (result.data?.listClipHgSubtotals?.items as unknown) as CreateClipHgSubtotalInput[];
        ret = [...ret, ...items];
      } while (nextToken !== null);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('[getSubTotalDatas]', e);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[getSubTotalDatas] ${(e as { message: string })?.message}`);
    }

    return ret;
  };

  // 使用回数の計測値を取得（１日分）
  const getUseCountByDay = async (srcDates?: SearchDatetimes): Promise<UseCounts> => {
    const useDates = srcDates ?? dates;
    let nextToken: string | null | undefined = null;
    const ret: UseCounts = {
      male: {},
      female: {},
      multi: {},
    };

    // 時間別データの作成
    for (let j = 0; j < 24; j += 1) {
      const valKey = getCalcValueKey(dayjs(useDates.calc.from).add(j, 'hours').toString());
      ret.male[valKey] = 0;
      ret.female[valKey] = 0;
      ret.multi[valKey] = 0;
    }

    // deviceId:デバイスID 取得範囲:時間降順
    const conditions: ListUseCountsByRestroomIdQueryVariables = {
      restroomId: restroom.restroom?.restroomId,
      useDateTime: {
        between: [useDates.calc.from, useDates.calc.to],
      },
      sortDirection: ModelSortDirection.ASC,
      nextToken: null,
    };

    try {
      do {
        conditions.nextToken = nextToken;

        // eslint-disable-next-line no-await-in-loop
        const result = await ApiGraphqlOperationWrapper<
          ListUseCountsByRestroomIdQueryVariables,
          GraphQLResult<ListUseCountsByRestroomIdQuery>
        >(conditions, listUseCountsByRestroomId);

        nextToken =
          undefined === result.data?.listUseCountsByRestroomId?.nextToken
            ? null
            : result.data?.listUseCountsByRestroomId?.nextToken;

        const items = (result.data?.listUseCountsByRestroomId?.items as unknown) as CreateClipHgUseCountInput[];

        items.forEach((item) => {
          const valKey = getCalcValueKey(item.useDateTime);
          switch (item.usage) {
            case 1:
              ret.male[valKey] += 1;
              break;
            case 2:
              ret.female[valKey] += 1;
              break;
            case 3:
              ret.multi[valKey] += 1;
              break;
            default:
              break;
          }
        });
      } while (nextToken !== null);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('[getUseCountByDay]', e);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[getUseCountByDay] ${(e as { message: string })?.message}`);
    }

    return ret;
  };

  return (
    <Detailinfo
      getToiletDetail={getToiletDetail}
      getSubTotal={getSubTotalDatas}
      getUseCountByDay={getUseCountByDay}
      datas={detailDatas}
      apiError={error}
      usages={usages}
      dates={dates}
      setToDate={toDate.setToDate}
      toiletByRestroom={toiletByRestroom}
    />
  );
};

export default DetailinfoContainer;
