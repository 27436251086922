/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
/**
 * 詳細画面
 */
import { FC, useEffect, useRef, useState } from 'react';
import { jsx, css } from '@emotion/core';
import { Marker } from 'components/Marker';
import { useRestroom, RestroomItems } from 'custom_hook/useRestroom';
import ImpossibleError from 'components/Error';
import {
  CalcDatas,
  searchCalcDatasKeys,
  getCalcValueKey,
  getDetailDataKeyByCo2,
  getDetailDataKeyByTimeline,
  SEARCH_DATE_FROM_KEY,
} from 'containers/Detailinfo';
import dayjs from 'dayjs';
import WarningListContainer from 'containers/WarningList';
import { GetWarningIcon } from 'utils/Warnings';
import {
  numberBackColors,
  numberTextColors,
  Usage,
  toiletTypeText,
  usageIcon,
  imageIcons,
  warningColors,
  ToiletType,
  usageReg,
  AUTO_UPDATE_TIME,
  GraphColorByUsage,
  usageText,
  TIMER_COLOR_NOW,
  TIMER_COLOR,
} from 'utils/AppConfig';
import { CreateClipHgSubtotalInput, CreateClipHgToiletInput } from 'API';
import { LastestLog } from 'utils/Log';
import iconLogo from 'images/ClipLogoWhite.svg';
import WeatherFolderContainer from 'containers/WeatherFolder';
// import logoOtaru from 'images/background/otaru_logo_alpha50.png';
import BarInCount from 'components/parts/BarInCount';
import iconWinterMode from 'images/icon/detail/winter_mode.png';
import iconClock from 'images/icon/detail/clock.png';
import iconPredict from 'images/icon/detail/predict_text.svg';
import iconGraphSampleMan from 'images/icon/detail/graph_sample/man.png';
import iconGraphSampleWomen from 'images/icon/detail/graph_sample/women.png';
import iconGraphSampleMulti from 'images/icon/detail/graph_sample/multi.png';
import { DetailCo2AndCount, WashRate, WashUseRate, WeekendCompare, WaterFlow } from 'components/Graphs';
import { Link } from 'react-router-dom';
import iconWaterflowMan from 'images/icon/detail/waterflow/man.png';
import iconWaterflowWomen from 'images/icon/detail/waterflow/women.png';
import iconWaterflowMulti from 'images/icon/detail/waterflow/multi.svg';
import iconWaterflowTotal from 'images/icon/detail/waterflow/total.png';
import { CheckKl, getSubTotalDatasByAverage } from 'utils/Common';
import iconGraphSampleWashrateTotal from 'images/icon/detail/graph_sample/washrate/total.svg';
import iconGraphSampleWashrateMan from 'images/icon/detail/graph_sample/washrate/man.svg';
import iconGraphSampleWashrateWomen from 'images/icon/detail/graph_sample/washrate/women.svg';
import iconGraphSampleWashrateMulti from 'images/icon/detail/graph_sample/washrate/multi.svg';
import iconWashrateSampleWashToilet from 'images/icon/detail/washrate/wash_toilet.svg';
import iconWashrateSampleToiletOnly from 'images/icon/detail/washrate/toilet_only.svg';
import iconWashrateSampleWashOnly from 'images/icon/detail/washrate/wash_only.svg';
import { Loader, Icon, SemanticICONS } from 'semantic-ui-react';
import { Overlay } from 'components/Overlay';
import { AmplifyS3Image } from '@aws-amplify/ui-react';

// ローカルストレージキー
const localStorageKeys = {
  restroomId: 'ldr',
  usage: 'ldu',
};

// 検索日時
type SearchDatetime = {
  from: string;
  to: string;
};
export type SearchDatetimes = {
  calc: SearchDatetime;
  subtotal: {
    day: SearchDatetime;
    week: SearchDatetime;
    day3: SearchDatetime;
    month: SearchDatetime;
  };
};

// 計測値
export type UseCounts = {
  male: {
    [key in string]: number;
  };
  female: {
    [key in string]: number;
  };
  multi: {
    [key in string]: number;
  };
};

type SampleColorData = {
  start?: number;
  end?: number;
  text?: string;
  backColor?: { from: string; to: string };
  textColor: string;
};
/**
 * サンプル用のカラーデータを取得
 *
 * @param {number} min 最小値
 * @param {number} max 最大値
 * @param {Usage} usage 用途
 * @return {SampleColorData[]}  カラーデータ
 */
const GetSampleColorDatas = (
  minCount: number | undefined,
  maxCount: number | undefined,
  usage: Usage,
): SampleColorData[] => {
  const useBackColors = numberBackColors[usage];
  const useTextColors = numberTextColors[usage];
  const len = useBackColors.length - 2;
  const ret = Array<SampleColorData>();
  const max = maxCount === undefined ? 25 : maxCount;

  ret.push({
    text: '0',
    backColor: useBackColors[0],
    textColor: useTextColors[0],
  });

  const step = Math.floor((max - 1) / len);
  let start = minCount || 0;
  let end = 0;
  for (let i = 0; i < len; i += 1) {
    end = i + 1 >= len ? max - 1 : start + step;
    ret.push({
      start: start + 1,
      end,
      text: `${start + 1}～${end}`,
      backColor: useBackColors[i + 1],
      textColor: useTextColors[i + 1],
    });
    start = end;
  }

  ret.push({
    start: max,
    text: max.toString(),
    backColor: useBackColors[useBackColors.length - 1],
    textColor: useTextColors[useTextColors.length - 1],
  });

  return ret;
};

/**
 * 警告番号から対応する背景色と文字色を取得する
 *
 * @param {number} warningNumber 警告番号
 * @param {SampleColorData} defalutColor デフォルトの色
 * @return {SampleColorData}  色データ
 */
const GetColumnColorByWarning = (
  warningNumber: number,
  srcColor: SampleColorData = {
    backColor: { from: '#FFF', to: '#FFF' },
    textColor: '#000',
  },
): SampleColorData => {
  const defColor: SampleColorData = {
    backColor: { from: '#FFF', to: '#FFF' },
    textColor: '#000',
  };
  const defaultColorData = { ...defColor, ...srcColor };

  if (warningNumber <= 0) {
    return defaultColorData;
  }
  if (warningNumber < 200) {
    return warningColors.warning_100;
  }
  if (warningNumber < 300) {
    return warningColors.warning_200;
  }

  return warningColors.warning_300;
};

/**
 * 数値か警告番号から対応する背景色と文字色を取得する
 *
 * @param {number} count 指定数値
 * @param {number} warningNumber 警告番号
 * @param {SampleColorData[]} datas GetSampleColorDatasで取得したデータ
 * @return {SampleColorData}  色データ
 */
const GetColumnColor = (count: number, warningNumber: number, datas: SampleColorData[]): SampleColorData => {
  let ret = datas[0];

  // 警告番号が優先
  if (warningNumber > 0) {
    return GetColumnColorByWarning(warningNumber, ret);
  }

  if (count <= 0) {
    return ret;
  }

  const lastData = datas[datas.length - 1];
  if (typeof lastData.start !== 'undefined') {
    if (count >= lastData.start) {
      return lastData;
    }
  }

  for (let i = 0; i < datas.length; i += 1) {
    const data = datas[i];
    if (data.start && data.end) {
      if (count >= data.start && count <= data.end) {
        ret = data;
        break;
      }
    }
  }

  return ret;
};

/**
 * グラデーションCSS出力
 *
 * @param {string} from 開始色
 * @param {string} to 終了色
 */
const CsslinearGradientByInline = (gradientColor?: { from: string; to: string }) => {
  if (gradientColor === undefined) {
    return '';
  }

  return css`
    background: -webkit-gradient(linear, left top, right top, from(${gradientColor.from}), to(${gradientColor.to}));
    background: -webkit-linear-gradient(left, ${gradientColor.from}, ${gradientColor.to});
    background: -moz-linear-gradient(left, ${gradientColor.from}, ${gradientColor.to});
    background: -o-linear-gradient(left, ${gradientColor.from}, ${gradientColor.to});
    background: linear-gradient(to right, ${gradientColor.from}, ${gradientColor.to});
  `;
};

type HeatmapViewDatas = { keys: string[]; title: string; icon: string; type: ToiletType };
type TabData = {
  usage: Usage | undefined;
  useKeys: HeatmapViewDatas[];
};
/*
// ポップアップで表示するワーニングのパラメータ
type WarningPopup = {
  title: string;
  icon: string;
  text: string;
  warningNumber: number;
};

const cssPopupAlertOverlay = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
`;
const cssPopupAlert = css`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1004;
  display: table;
  width: 50%;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  transform: translate(-50%, -50%);

  .dialog_header {
    display: table-row;
    height: 3em;

    img {
      height: 2.5em;
      vertical-align: middle;
    }

    span {
      margin-left: 10px;
      font-size: 1.2em;
      font-weight: 1000;
    }
  }

  .dialog_content {
    display: table-row;
  }

  .space {
    display: table-row;
    height: 15px;
  }

  .dialog_buttons {
    display: table-row;
    width: 100%;
    height: 2em;
    text-align: right;
  }
`;

const cssButton = css`
  position: relative;
  display: inline-block;
  padding: 0.5em 2.5em;
  font-size: 1em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.1em;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #cacbcd;
  border-radius: 7px;
  outline: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
`;
*/
/**
 * ポップアップアラート管理
 *
 * @param {RestroomItems} restroom      トイレデータ
 * @param {Usage}         usage         使用用途
 * @param {function}      GetWarning105 105ワーニングを取得するメソッド
 * @param {function}      GetWarning110 110ワーニングを取得するメソッド
 */
/*
type PopupAlertPorps = {
  restroom: RestroomItems | undefined;
  usage: Usage;
  GetWarning105: (usage: Usage) => Promise<string[]>;
  GetWarning110: (usage: Usage) => Promise<string[]>;
};
export const PopupAlert: FC<PopupAlertPorps> = ({ restroom, usage, GetWarning105, GetWarning110 }) => {
  const unmounted = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const warningPopupIntervalId = useRef<NodeJS.Timeout | number>(0);
  const [warningPopup, setWarningPopup] = useState<{
    title: string;
    icon: string;
    text: string;
    warningNumber: number;
  } | null>(null);

  const handleWarningPopupClose = () => {
    if (!unmounted.current) {
      setIsOpen(false);
    }
  };

  // マウント判定
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  // ワーニング105, 110検知
  useEffect(() => {
    const CheckWarning = async () => {
      if (restroom && usage !== undefined) {
        let popup: WarningPopup | null = null;
        if (!unmounted.current) {
          setIsLoading(true);
        }
        const deviceIds105 = await GetWarning105(usage);
        const deviceIds110 = await GetWarning110(usage);
        if (!unmounted.current) {
          setIsLoading(false);
        }

        if (deviceIds110.length > 0) {
          const warning = GetWarningValueByIds(deviceIds110, 110);
          popup = {
            title: warning.title,
            icon: GetWarningIcon(110),
            text: warning.text,
            warningNumber: 110,
          };
        }

        if (deviceIds105.length > 0) {
          const warning = GetWarningValueByIds(deviceIds105, 105);
          popup = {
            title: warning.title,
            icon: GetWarningIcon(105),
            text: warning.text,
            warningNumber: 105,
          };
        }

        if (popup) {
          if (!unmounted.current) {
            setWarningPopup(popup);
            setIsOpen(true);
          }
        } else if (!unmounted.current) {
          setIsOpen(false);
        }
      }
    };

    const fn = () => void CheckWarning();
    fn();

    clearInterval(warningPopupIntervalId.current as number);
    warningPopupIntervalId.current = setInterval(fn, 60000);

    return () => {
      clearInterval(warningPopupIntervalId.current as number);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usage]);

  return (
    <React.Fragment>
      <AnimatePresence>
        {isOpen && (
          <motion.div css={cssPopupAlert} aria-label="none" initial="init" animate="start" exit="end" variants={modal}>
            <div className="dialog_header">
              <img alt="icon" src={warningPopup?.icon} />
              <span>{warningPopup?.title}</span>
              <hr />
            </div>
            <div className="space" />
            <div className="dialog_content">{warningPopup?.text}</div>
            <div className="space" />
            <div className="dialog_buttons">
              <hr />
              <div
                css={cssButton}
                aria-label="close button"
                role="button"
                tabIndex={0}
                onKeyDown={handleWarningPopupClose}
                onClick={handleWarningPopupClose}
              >
                OK
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <Overlay zIndex={1002} isOpen={isOpen} isBlack />
      {isLoading && (
        <div css={cssPopupAlertOverlay}>
          <Loader active size="massive" inline="centered" />
        </div>
      )}
    </React.Fragment>
  );
};
*/
const cssHeatmap = css`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  user-select: none;

  .timeline {
    position: sticky !important;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 0 !important;
    margin: 0 !important;
    background: rgba(4, 40, 76, 1) !important;
    border: none !important;
    border-radius: 0 !important;

    .header {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        height: calc(100% + 15px) !important;
        transform: translateY(calc(-50% + 9px)) translateX(-50%);
      }
    }

    .fixed-column {
      position: relative;
      background: transparent !important;
      border: none !important;
    }

    .count-cell {
      position: relative;
      align-items: center !important;
      justify-content: flex-end !important;
      color: #fff !important;
      background: transparent !important;

      span {
        padding-right: 16px;
        font-size: 10px !important;
      }

      .line {
        position: absolute;
        right: 0;
        width: 7px;
        border-top: solid 1px #fff;
      }

      .warning-icon {
        position: absolute;
        left: 4px;
        height: 100%;

        .warning-icon-img {
          height: 100%;
          max-height: 22px;
        }
      }
    }

    .total-line {
      position: absolute;
      top: 0;
      width: 65%;
      border-top: solid 1px #fff;
    }
  }

  .heatmap {
    position: relative;
    display: inline-block;
    flex: 0 0 auto;
    height: 100%;
    padding: 0 6px;
    background: linear-gradient(90deg, #002b7b, #091324);
    border: solid 1px #fff;
    border-radius: 5px;

    .central {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .heatmap-inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 5px 0;
    }

    .header {
      height: 36px;
      padding: 3px 0;
      color: #fff;

      img {
        height: 100%;
      }
    }

    .lists {
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      ul {
        display: flex;
        flex-direction: column;
        column-gap: 5px;
        justify-content: space-between;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
        }

        .fixed-column {
          height: 25px;
          color: #fff;
          background: linear-gradient(90deg, #003a9a, #0b0e18);
          border: solid 1px #4d94b2;
        }

        .fixed-column.name {
          margin-bottom: 5px;
        }

        .fixed-column.name.unconnected {
          color: rgb(180, 180, 180) !important;
          opacity: 0.5 !important;
        }

        .fixed-column.total {
          margin-top: 5px;
          font-family: 'DSEG7ModernMini-Regular', sans-serif;
        }

        .count-cell {
          flex-grow: 1;
          font-family: 'DSEG7ModernMini-Regular', sans-serif;
          font-weight: bold;
          color: #000;
          background-color: #fff;

          span {
            position: absolute;
            font-size: 85%;
          }
        }

        .count-cell + .count-cell {
          margin-top: 3px;
        }
      }

      ul + ul {
        padding-left: 5px;
      }
    }

    .heatmap-inner + .heatmap-inner {
      padding-left: 5px;
    }
  }

  .heatmap.space + .heatmap.space {
    margin-left: 10px;
  }
`;
/**
 * ヒートマップ
 *
 * @param {TabData} tabData タブデータ
 * @param {CalcDatas} datas データ
 * @param {RestroomItems} restroom トイレデータ
 * @param {number} useCountDatas 計測値の24時間分の配列
 * @param {boolean} isNow 現在かどうか
 */
type HeatmapProps = {
  tabData: TabData;
  datas: CalcDatas | null;
  restroom: RestroomItems;
  useCountDatas: number[];
  isNow: boolean;
};
const Heatmap: FC<HeatmapProps> = ({ tabData, datas, restroom, useCountDatas, isNow }) => {
  const { useKeys, usage } = tabData;

  if (usage === undefined) {
    return <div />;
  }

  const cellColors = GetSampleColorDatas(restroom?.useMin, restroom?.useMax, usage);
  const co2Key = getDetailDataKeyByCo2(usage);

  type Co2DataKey = 'carbonDioxide' | 'humidity' | 'temperature';
  const co2Datas: {
    key: Co2DataKey;
    title: string;
    name: string;
    icon: string;
    imgSize: string;
  }[] = [
    { key: 'temperature', title: '温度', name: '℃', icon: imageIcons.Temperature, imgSize: '100%' },
    { key: 'humidity', title: '湿度', name: '％', icon: imageIcons.Humidity, imgSize: '100%' },
    { key: 'carbonDioxide', title: 'CO2<br />濃度', name: 'ppm', icon: imageIcons.Co2, imgSize: '70%' },
  ];

  if (datas === null || Object.keys(datas).length <= 0) {
    return <div />;
  }

  // 日時データ取得用の検索開始時間
  const searchFromDatetime = datas[SEARCH_DATE_FROM_KEY].searchDateFrom;
  if (searchFromDatetime === undefined) {
    return <div />;
  }

  // タイムラインデータ格納キー
  const timelineKey = getDetailDataKeyByTimeline(usage);
  const columnCount = (() => {
    let ret = 4; // 予測値 + Co2
    useKeys.forEach((item) => {
      ret += item.keys.length;
    });

    return ret;
  })();

  return (
    <section css={cssHeatmap} style={{ width: `calc(90px * ${columnCount})` }}>
      {/** タイムライン */}
      <article className="heatmap timeline">
        <div className="heatmap-inner">
          <div className="header central">
            <img alt="icon" src={iconClock} />
          </div>
          <div className="lists">
            <ul>
              <li className="fixed-column name" />
              {(() => {
                const ret = [];
                for (let cell = 0; cell < 24; cell += 1) {
                  const keyDate = dayjs(searchFromDatetime).add(cell, 'hours').toString();
                  const keyCell = `HeatmapData-timeline-list-cell-${cell}`;
                  const valkey = getCalcValueKey(keyDate);
                  const timeValue = datas[timelineKey]?.values[valkey];
                  const cellColor = GetColumnColorByWarning(timeValue.warning, { textColor: '#fff' });
                  const viewCell = dayjs(keyDate).format('HH').padStart(2, '0');

                  ret.push(
                    <li key={keyCell} className="count-cell">
                      <div className="warning-icon">
                        {timeValue.warning > 0 && (
                          <img className="warning-icon-img" alt="icon" src={GetWarningIcon(timeValue.warning)} />
                        )}
                      </div>
                      <span style={{ color: `${cellColor.textColor}` }}>{viewCell}:00</span>
                      <div className="line" />
                    </li>,
                  );
                }

                return ret;
              })()}
              <li className="fixed-column total">
                <div className="total-line" />
                合計
              </li>
            </ul>
          </div>
        </div>
      </article>

      {/** 回数一覧 */}
      {useKeys.map((item, index) => {
        if (item.keys.length <= 0) {
          return '';
        }
        const keyRestroom = `HeatmapData-toilet-${item.type}-${index}`;

        return (
          <article key={keyRestroom} className="heatmap space">
            <div className="heatmap-inner">
              <div className="header central">
                <img alt="icon" src={item.icon} />
                {item.title}
              </div>
              <div className="lists">
                {item.keys.map((keyToilet, keyIndex) => {
                  if (!datas[keyToilet]) {
                    const message = `[Heatmap] データの取得に失敗しました。存在しないキーです:${keyToilet}`;
                    // eslint-disable-next-line no-console
                    console.error(message);
                    LastestLog(message);

                    return '';
                  }

                  const { title, total, values } = datas[keyToilet];
                  const cellColor = GetColumnColorByWarning(title.warning, {
                    textColor: '#fff',
                  });
                  const keyList = `HeatmapData-list-items-${keyIndex}-${title.name}-${total}`;

                  return (
                    <ul key={keyList}>
                      {/* 名前 */}
                      <li
                        className={`fixed-column name ${!title.isConnected && isNow && 'unconnected'}`}
                        style={{ color: `${cellColor.textColor}` }}
                      >
                        {/* アイコンを表示する場合はコメントアウトを外す
                        title.warning > 0 && (
                          <img style={{ height: '1em' }} alt="icon" src={GetWarningIcon(title.warning)} />
                        ) */}
                        {title.name}
                      </li>
                      {/* セル */}
                      {(() => {
                        const ret = [];
                        for (let hour = 0; hour < 24; hour += 1) {
                          const valkey = getCalcValueKey(dayjs(searchFromDatetime).add(hour, 'hours').toString());
                          const { count, warning, key } = values[valkey];
                          const colorData = GetColumnColor(count, warning, cellColors);
                          const cssCell = CsslinearGradientByInline(colorData.backColor);

                          ret.push(
                            <li
                              key={key}
                              css={cssCell}
                              style={{
                                color: `${colorData.textColor}`,
                              }}
                              className="count-cell"
                            >
                              <span>{count}</span>
                            </li>,
                          );
                        }

                        return ret;
                      })()}
                      <li className="fixed-column total">{total.count}</li>
                    </ul>
                  );
                })}
              </div>
            </div>
          </article>
        );
      })}

      {/** 予測値 */}
      <article
        className="heatmap space"
        style={{ border: `solid 1px #fff`, background: `${GraphColorByUsage[usage]}` }}
      >
        <div className="heatmap-inner">
          <div className="header central">
            <img alt="icon" src={iconPredict} />
          </div>
          <div className="lists">
            <ul>
              <li className="fixed-column name" style={{ border: `solid 1px ${GraphColorByUsage[usage]}` }} />
              {useCountDatas.map((cell, cellIndex) => {
                const keyCell = `HeatmapData-predict-list-cell-${usage}-${cellIndex}-${cell}`;

                return (
                  <li key={keyCell} className="count-cell">
                    <span>{cell}</span>
                  </li>
                );
              })}
              <li className="fixed-column total" style={{ border: `solid 1px ${GraphColorByUsage[usage]}` }}>
                {useCountDatas.reduce((total, current) => total + current, 0)}
              </li>
            </ul>
          </div>
        </div>
      </article>

      {/** Co2 */}
      {datas[co2Key] && (
        <article className="heatmap space" style={{ display: 'flex', flexDirection: 'row', background: '#6b6b6b' }}>
          {co2Datas.map((list, index) => {
            const keyList = `HeatmapData-list-${list.title}-${index}`;

            return (
              <div key={keyList} className="heatmap-inner">
                <div className="header central">
                  <img alt="icon" src={list.icon} style={{ height: `${list.imgSize}` }} />
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: `${list.title}` }} style={{ display: 'inline-block' }} />
                </div>
                <div className="lists">
                  <ul>
                    {/* 名前 */}
                    {(() => {
                      const { co2Warnings } = datas[co2Key];
                      const Color = GetColumnColorByWarning(co2Warnings[list.key], { textColor: '#fff' });

                      return (
                        <li className="fixed-column name" style={{ color: `${Color.textColor}` }}>
                          {/* アイコンを表示する場合はコメントアウトを外す
                          co2Warnings[list.key] > 0 && (
                            <img style={{ height: '1em' }} alt="icon" src={GetWarningIcon(co2Warnings[list.key])} />
                          ) */}
                          {list.name}
                        </li>
                      );
                    })()}
                    {/* セル */}
                    {(() => {
                      const ret = [];
                      for (let hour = 0; hour < 24; hour += 1) {
                        const { values } = datas[co2Key];
                        const valkey = getCalcValueKey(dayjs(searchFromDatetime).add(hour, 'hours').toString());
                        const data = values[valkey];
                        const cell = data[list.key];
                        const keyCell = `${keyList}-${hour}-${cell}`;
                        const cellColor = GetColumnColorByWarning(data.warning);
                        const cssCell = CsslinearGradientByInline(cellColor.backColor);

                        ret.push(
                          <li
                            css={cssCell}
                            style={{
                              color: `${cellColor.textColor}`,
                              background: '#dbdbdb',
                            }}
                            key={keyCell}
                            className="count-cell"
                          >
                            <span>{cell}</span>
                          </li>,
                        );
                      }

                      return ret;
                    })()}
                    <li className="fixed-column total" />
                  </ul>
                </div>
              </div>
            );
          })}
        </article>
      )}
    </section>
  );
};

const cssDetailLeftContent = css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .date-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 45px;
    padding: 0 40px;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
    }

    li + li {
      padding-left: 10px;
    }

    .now-date {
      font-family: 'DSEG7ModernMini-Regular', sans-serif;
      font-size: 15px;
    }

    .large-icon {
      font-size: 13px;
    }

    .small-icon {
      font-size: 17px;
    }

    .disabled {
      color: #8a8d92;
      opacity: 0.1;
    }

    .no-link-cursor {
      cursor: auto !important;
    }
  }

  .weathers {
    padding: 10px 0;
  }

  .detail-info {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 150px;
    background-color: #08162a;
    border-radius: 5px;

    .using {
      position: absolute;
      top: 8px;
      right: 9px;
      padding: 1px 20px;
      border: solid 3px rgb(85 255 255);
      border-radius: 25px;
      opacity: 0.2;

      span {
        font-size: 15px;
        color: rgb(85 255 255);
        letter-spacing: 3px;
      }
    }

    .using.on {
      box-shadow: 0 0 5px rgb(85 255 255), 0 0 5px rgb(85 255 255) inset;
      opacity: 0.9;

      span {
        text-shadow: 0 0 5px rgb(85 255 255);
      }
    }

    .marker {
      width: 150px;
      padding: 10px;

      .square {
        position: relative;
        width: 100%;
        height: auto;

        &::before {
          display: block;
          padding-top: 100%;
          content: '';
        }

        .square-inner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .count-info {
      flex-grow: 1;
      padding-left: 10px;

      td {
        position: relative;
      }

      td .rows {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
      }

      td .winter-mode-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }

  .warning-list {
    flex-grow: 1;
    padding-top: 20px;
  }
`;

/**
 * 詳細画面左側コンテンツ
 * @param {function} GetUseCountByUsage 予測値合計取得
 * @param {UseCounts} useCountDatas 予測値データ
 * @param {RestroomItems} restroom トイレデータ
 * @param {Usage} usage 現在の用途
 * @param {Usage[]} usages 使用用途一覧
 * @param {SearchDatetimes} dates 検索日時
 * @param {function} setDate 日付変更
 * @param {CreateClipHgToiletInput[]} toiletByRestroom トイレ単位の便器データ
 * @param {boolean} isNow 現在かどうか
 */
type DetailLeftContentProps = {
  GetUseCountByUsage: (usage?: Usage) => number[];
  useCountDatas: UseCounts;
  restroom: RestroomItems | undefined;
  usage?: Usage;
  usages: Usage[];
  dates: SearchDatetimes;
  setDate: (flag?: string) => void;
  toiletByRestroom: CreateClipHgToiletInput[];
  isNow: boolean;
};
const DetailLeftContent: FC<DetailLeftContentProps> = ({
  GetUseCountByUsage,
  useCountDatas,
  restroom,
  usage,
  usages,
  dates,
  setDate,
  toiletByRestroom,
  isNow,
}) => {
  const unmounted = useRef(false);
  const [totalCounts, setTotalCounts] = useState<number[]>(new Array<number>(usageText.length).fill(0));
  const [using, setUsing] = useState<boolean>(false);
  const [isViewUsing, setIsViewUsing] = useState<boolean>(false);

  // 全体合計更新
  const UpdateTotalCount = () => {
    const updateTotalCounts = new Array<number>(usageText.length).fill(0);
    usages.forEach((val) => {
      const tmp = GetUseCountByUsage(val);
      updateTotalCounts[val] = tmp.reduce((prev, current) => prev + current, 0);
    });

    if (!unmounted.current) {
      setTotalCounts(updateTotalCounts);
    }
  };

  // 円グラフのデータ作成
  // 女性、多目的、男性の順
  const GetPieData = () => {
    return [totalCounts[2], totalCounts[3], totalCounts[1]];
  };

  // 日付変更ボタン
  const DateChangeButton = (iconName: SemanticICONS, flag: string, is: boolean, classes?: string) => {
    return (
      <li
        className={`${!is && 'no-link-cursor'}`}
        onClick={() => {
          if (is) {
            setDate(flag);
          }
        }}
      >
        <Icon name={iconName} size="small" className={`${classes} ${!is && 'disabled'}`} />
      </li>
    );
  };

  // マウント判定
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  // 使用中判定
  useEffect(() => {
    let newUsing = false;

    toiletByRestroom.forEach((toilet) => {
      if (toilet.usage === 3 && (toilet.isOccupied ?? false)) {
        newUsing = toilet.isOccupied ?? false;
      }
    });

    // 多目的＋大便器が１つの場合に使用中を表示
    const newIsViewUsing =
      toiletByRestroom.filter((toilet) => toilet.usage === 3 && toilet.type === 2).length === 1 && usage === 3;

    if (!unmounted.current) {
      if (isViewUsing !== newIsViewUsing) {
        setIsViewUsing(newIsViewUsing);
      }
      if (newUsing !== using) {
        setUsing(newUsing);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toiletByRestroom, usage]);

  // データ変更時
  useEffect(() => {
    UpdateTotalCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usage, usages, useCountDatas]);

  if (restroom === undefined) {
    return <div />;
  }

  const usageTmp: Usage[] = [];
  usages.forEach((val) => {
    if (val !== usage) {
      usageTmp.push(val);
    }
  });
  const otherUsages = usageTmp.filter((x, i, self) => {
    return self.indexOf(x) === i;
  });

  const timerColor = isNow ? TIMER_COLOR_NOW : TIMER_COLOR;

  return (
    <section css={cssDetailLeftContent}>
      {/* 日付変更ボタン */}
      <ul className="date-buttons" style={{ color: `${timerColor}` }}>
        <li style={{ flexGrow: 1 }} />
        {DateChangeButton('backward', 'prev_week', true, 'large-icon')}
        {DateChangeButton('caret left', 'prev', true, 'small-icon')}
        {(() => {
          const is = dayjs(dates.calc.to).format('YYYYMMDD') !== dayjs().format('YYYYMMDD');

          return (
            <li
              className={`${!is && 'no-link-cursor'}`}
              onClick={() => {
                if (is) {
                  setDate();
                }
              }}
            >
              <div className="now-date" style={{ textShadow: `0 0 10px ${timerColor}, 0 0 15px ${timerColor}` }}>
                <span>{dayjs(dates.calc.to).format('YYYY/MM/DD')}</span>
              </div>
            </li>
          );
        })()}
        {DateChangeButton(
          'caret right',
          'next',
          parseInt(dayjs(dates.calc.to).add(1, 'day').format('YYYYMMDD'), 10) <=
            parseInt(dayjs().format('YYYYMMDD'), 10),
          'small-icon',
        )}
        {DateChangeButton(
          'forward',
          'next_week',
          parseInt(dayjs(dates.calc.to).add(1, 'week').format('YYYYMMDD'), 10) <=
            parseInt(dayjs().format('YYYYMMDD'), 10),
          'large-icon',
        )}
        <li style={{ flexGrow: 1 }} />
      </ul>
      <div className="weathers">
        <WeatherFolderContainer />
      </div>
      <div className="detail-info">
        {isViewUsing && isNow && (
          <article className={`using ${using ? 'on' : ''}`}>
            <span>使用中</span>
          </article>
        )}
        <article className="marker">
          <div className="square">
            <div className="square-inner">
              <Marker FixFontSize="20px" restroom={restroom} pieLabel="Total" pieData={GetPieData()} />
            </div>
          </div>
        </article>
        <article className="count-info">
          <table style={{ width: '100%', height: '100%' }}>
            <tbody>
              <tr style={{ height: '50%' }}>
                <td colSpan={2}>
                  {usage && (
                    <BarInCount
                      icon={usageIcon[usage]}
                      count={totalCounts[usage]}
                      unit="人"
                      barStyle={{ backgroundColor: GraphColorByUsage[usage] }}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: '20%' }}>
                  {restroom.isWinter && <img className="winter-mode-icon" alt="icon" src={iconWinterMode} />}
                </td>
                <td>
                  {otherUsages.length > 0 && (
                    <div className="rows">
                      {otherUsages.map((item) => {
                        return (
                          <div key={`DetailLeftContent_count_info_usage${item}`} style={{ paddingBottom: '5px' }}>
                            <BarInCount
                              icon={usageIcon[item]}
                              count={totalCounts[item]}
                              unit="人"
                              barStyle={{ height: '20px', fontSize: '14px', backgroundColor: GraphColorByUsage[item] }}
                              isIconHeightToBar
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
      <div className="warning-list">
        <WarningListContainer usage={usage} restroom={restroom} toDate={dates.calc.to} fromDate={dates.calc.from} />
      </div>
    </section>
  );
};

const cssTabImage = css`
  position: absolute;
  top: 0;
  width: 452px;
  height: 46px;
  user-select: none;

  p {
    position: absolute;
    top: 16px;
    left: 21px;
    font-size: 18px;
    color: #fff;
  }

  .tab-image {
    height: 100%;
  }

  .tab-links {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;

    .select-usage {
      flex-grow: 1;
    }

    .link-width {
      width: 84px;
    }

    .link {
      z-index: 1;
      cursor: pointer;
    }
  }
`;

/**
 * タブ
 *
 * @param {TabData}       tabData   タブ情報
 * @param {string}        title     タブに表示するタイトル
 * @param {function}      TabClick  タブクリックイベント
 * @param {Usage[]}       usages    使用用途一覧
 */
type TabContentProps = {
  tabData: TabData;
  title: string;
  TabClick: (selectUsage: Usage) => void;
  usages: Usage[];
};
const TabContent: FC<TabContentProps> = ({ tabData, title, TabClick, usages }) => {
  // 用途に紐づくファイル名定義
  const usageNames = ['', 'man', 'women', 'multi'];
  const unmounted = useRef(false);
  const [image, setImage] = useState<string>('');

  /*
  // トイレの用途を取得
  useEffect(() => {
    const duplicate = useUsages.filter((x, i, self) => {
      return self.indexOf(x) === i;
    });
    duplicate.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;

      return 0;
    });
    if (!unmounted.current) {
      setUsages(duplicate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restroom]);
  */

  // タブ切り替え
  useEffect(() => {
    if (tabData.usage !== undefined && usages !== undefined) {
      let name = `${usageNames[tabData.usage]}`;
      usages.forEach((val) => {
        if (tabData.usage !== val) {
          name = `${name}_${usageNames[val]}`;
        }
      });
      if (!unmounted.current) {
        setImage(name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabData, usages]);

  // マウント判定
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  if (usages === undefined || usages.length <= 0 || image.length <= 0) {
    return <div css={cssTabImage} />;
  }

  return (
    <section css={cssTabImage}>
      <img alt="tab" css={cssTabImage} src={`/images/tab/${image}.png`} />
      <p>{title}</p>
      <ul className="tab-links">
        <li className="select-usage" />
        {((): JSX.Element[] => {
          const ret: JSX.Element[] = [];
          usages.forEach((val) => {
            if (tabData.usage !== val) {
              ret.push(
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                <li key={`TabImage-link-${val}`} className="link-width link" onClick={() => TabClick(val)} />,
              );
            }
          });

          const max = usageText.length - 1;
          for (let i = 0; i < max - usages.length; i += 1) {
            ret.push(<li key={`TabImage-link-dummy-${i}`} className="link-width" />);
          }

          return ret;
        })()}
      </ul>
    </section>
  );
};

const cssDetail = css`
  padding: 15px 30px;
  background-color: #152e42;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }

  .header {
    position: relative;
    width: 100%;
    height: 10px;

    .logo {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      height: 40px;
    }
  }

  .detail-content {
    position: relative;
    width: 100%;
    /* ヘッダ、全体の余白 */
    height: calc(100vh - 10px - 15px);
    padding: 45px 0 10px 0;
    border-radius: 0 10px 10px 10px;

    .logo-image {
      position: absolute;
      bottom: 0;
      left: -30px;
      height: 56vh;
    }

    .outer {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      padding: 15px;
      background: rgba(4, 40, 76, 0.8);
      border: solid 1px #fff;
      border-radius: 0 10px 10px 10px;

      .detail-left {
        position: relative;
        width: 370px;
      }

      .detail-right {
        position: relative;
        flex-grow: 1;

        .right-scroll {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 20px;
          overflow-x: auto;
        }
      }
    }
  }

  .detail-graph {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 20px;
    background: linear-gradient(166deg, rgba(6, 40, 75, 0.8) 56%, rgba(6, 89, 170, 0.8));
    border: solid 1px #fff;
    border-radius: 5px;

    h2 {
      padding: 5px 0 5px 2em;
      margin-bottom: 30px;
      font-size: 21px;
      font-weight: normal;
      color: #fff;
      background: linear-gradient(90deg, #50a8ba, #05748a);

      .datetime {
        padding-left: 20px;
        font-size: 17px;
      }
    }

    .graph-title {
      p {
        display: inline-block;
        width: 200px;
        padding: 2px 0;
        font-size: 15px;
        color: #fff;
        text-align: center;
        border: solid 1px #50a8ba;
      }
    }

    .DetailCo2AndCount {
      width: calc(100% - 2em);
      height: 100%;
      padding-left: 20px;
    }

    .add-title {
      display: flex;
      flex-direction: row;

      .label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 2em;
        color: #fff;

        .tate {
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
        }

        div {
          align-items: center;
          justify-content: center;
        }
      }

      .graph {
        flex-grow: 1;
      }
    }

    .samples {
      height: 26px;
      padding-left: 100px;

      .man {
        position: relative;
        top: 1px;
        height: 31px;
      }

      img {
        height: 100%;
      }

      img + img {
        padding-left: 25px;
      }
    }

    .WashRate {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: calc(100% - 60px);
      padding: 10px 20px 20px 20px;
      margin: 0 30px;
      background: rgba(0, 0, 0, 0.4);
    }

    .WashUseRateListScroll {
      position: relative;
      width: 100%;
      height: 48%;
      overflow-x: auto;
    }

    .WashUseRateList {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 1000px;
      height: 100%;
      padding: 0;
      margin: 0;
      margin: 0 30px;
      list-style: none;

      li {
        width: 50%;
      }
    }

    .WashUseRate {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 10px 20px 20px 20px;
      background: rgba(0, 0, 0, 0.4);
    }

    .WashRate .graph-sample,
    .WashUseRate .graph-sample {
      height: 45px;
      padding-left: 36px;
      margin: 10px 0;

      img {
        height: 100%;
      }

      img + img {
        padding-left: 7px;
      }
    }

    .WeekendCompare {
      display: flex;
      flex-direction: row;
      width: 80%;
      height: 100%;
      padding-left: 20px;
    }

    .border {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      pointer-events: none;

      .side {
        width: 10px;
        border-top: solid 1px #50a8ba;
        border-bottom: solid 1px #50a8ba;
      }

      .border-left {
        border-left: solid 1px #50a8ba;
      }

      .border-right {
        border-right: solid 1px #50a8ba;
      }
    }
  }

  .detail-graph + .detail-graph {
    margin-top: 10px;
  }
`;

const cssPhoto = css`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5em;
  margin: 0 0 1em 0;
  list-style: none;

  .photo {
    width: 25%;
    padding: 0 0.5em 0.5em;

    amplify-s3-image {
      --width: 100%;
      --height: auto;
    }
  }
`;

const cssWeekendCompareIcon = css`
  display: inline-block;
  width: 80px;
  user-select: none;

  ul {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .icon-circle {
    width: 30px;

    span {
      font-size: 12px;
      font-weight: bold;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      font-size: 11px;
      border-radius: 50%;
    }
  }

  .icon-bar {
    flex-grow: 1;
    margin-left: 7px;

    div {
      width: 100%;
      height: 10px;
    }
  }

  .color-year {
    background-color: #008748;

    span {
      color: #fff;
    }
  }

  .color-weekday {
    background-color: #fff;

    span {
      color: #000;
    }
  }

  .color-holiday {
    background-color: #ff6764;

    span {
      color: #fff;
    }
  }
`;

/**
 * 詳細画面全体
 *
 * @param {calcDatas} datas DBから取得したデータ
 * @param {function} getToiletDetail DBデータを取得するメソッド
 * @param {function} getSubTotal DBデータを取得するメソッド
 * @param {function} getUseCountByDay 計測値を取得するメソッド
 * @param {string} apiError APIエラー
 * @param {Usage[]} usages 使用する用途
 * @param {SearchDatetimes} dates 検索日時
 * @param {function} setToDate 検索日付を設定する
 * @param {CreateClipHgToiletInput[]} toiletByRestroom トイレ単位の便器データ
 */
type DetailinfoProps = {
  datas: CalcDatas | null;
  getToiletDetail: (srcDates?: SearchDatetimes) => Promise<void>;
  getSubTotal: (fromYYYYMMDD?: string, toYYYYMMDD?: string) => Promise<CreateClipHgSubtotalInput[]>;
  getUseCountByDay: (srcDates?: SearchDatetimes) => Promise<UseCounts>;
  apiError: string;
  usages: Usage[];
  dates: SearchDatetimes;
  setToDate: (newDate: string) => void;
  toiletByRestroom: CreateClipHgToiletInput[];
};
export const Detailinfo: FC<DetailinfoProps> = ({
  datas,
  getToiletDetail,
  getSubTotal,
  apiError,
  getUseCountByDay,
  usages,
  dates,
  setToDate,
  toiletByRestroom,
}) => {
  const unmounted = useRef(false);
  const updateRestroomIntervalId = useRef<NodeJS.Timeout | number>(0);
  const restroom = useRestroom();
  const [tabData, setTabData] = useState<TabData>({
    usage: undefined,
    useKeys: [],
  });
  const [subTotalDatas, setSubTotalDatas] = useState<CreateClipHgSubtotalInput[]>([]);
  const [subTotalDatasWeek, setSubTotalDatasWeek] = useState<CreateClipHgSubtotalInput[]>([]);
  const [subTotalDatasDay3, setSubTotalDatasDay3] = useState<CreateClipHgSubtotalInput[]>([]);
  const [subTotalDatasMonth, setSubTotalDatasMonth] = useState<CreateClipHgSubtotalInput[]>([]);
  const [subTotalDatasAverage, setSubTotalDatasAverage] = useState<CreateClipHgSubtotalInput[]>([]);
  const [useCountDatas, setUseCountDatas] = useState<UseCounts>({
    male: {},
    female: {},
    multi: {},
  });
  const [isKl, setIsKl] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(datas === null);

  // 日付セット
  const setDate = (flag?: string) => {
    let newToDate = dayjs().format('YYYY-MM-DD HH:mm:ss');
    switch (flag) {
      case 'next':
        newToDate = dayjs(dates.calc.to).add(1, 'day').format('YYYY-MM-DD HH:59:59');
        break;
      case 'next_week':
        newToDate = dayjs(dates.calc.to).add(7, 'day').format('YYYY-MM-DD HH:59:59');
        break;
      case 'next_month':
        newToDate = dayjs(dates.calc.to).add(1, 'month').format('YYYY-MM-DD HH:59:59');
        break;
      case 'prev':
        newToDate = dayjs(dates.calc.to).subtract(1, 'day').format('YYYY-MM-DD HH:59:59');
        break;
      case 'prev_week':
        newToDate = dayjs(dates.calc.to).subtract(7, 'day').format('YYYY-MM-DD HH:59:59');
        break;
      case 'prev_month':
        newToDate = dayjs(dates.calc.to).subtract(1, 'month').format('YYYY-MM-DD HH:59:59');
        break;
      default:
        break;
    }
    if (!unmounted.current) {
      setIsLoading(true);
      setToDate(newToDate);
    }
  };

  // 用途単位の計測回数取得
  const GetUseCountByUsage = (usage?: Usage): number[] => {
    const useUsage = usage ?? tabData.usage;
    const ret: number[] = [];
    const Get = (hour: number): number => {
      const valKey = getCalcValueKey(dayjs(dates.calc.from).add(hour, 'hours').toString());
      switch (useUsage) {
        case 1:
          return useCountDatas.male[valKey] || 0;
        case 2:
          return useCountDatas.female[valKey] || 0;
        case 3:
          return useCountDatas.multi[valKey] || 0;
        default:
          return 0;
      }
    };
    for (let i = 0; i < 24; i += 1) {
      ret.push(Get(i));
    }

    return ret;
  };

  // タブ更新
  const UpdateTab = (selectUsage: Usage | undefined) => {
    if (selectUsage === undefined || datas === null) {
      return;
    }

    // 種類毎にキー保存
    const keys: HeatmapViewDatas[] = [];
    keys.push({
      keys: searchCalcDatasKeys(datas, selectUsage, 1),
      title: toiletTypeText[1],
      icon: imageIcons.Urinal,
      type: 1,
    });
    keys.push({
      keys: searchCalcDatasKeys(datas, selectUsage, 2),
      title: toiletTypeText[2],
      icon: imageIcons.ToiletBowl,
      type: 2,
    });
    keys.push({
      keys: searchCalcDatasKeys(datas, selectUsage, 3),
      title: toiletTypeText[3],
      icon: imageIcons.Lavatory,
      type: 3,
    });
    keys.push({
      keys: searchCalcDatasKeys(datas, selectUsage, 5),
      title: toiletTypeText[5],
      icon: imageIcons.Ostomate,
      type: 5,
    });

    if (!unmounted.current) {
      setTabData({
        usage: selectUsage,
        useKeys: [...keys],
      });
    }
  };

  // データ更新
  const UpdateDatas = async (srcDates?: SearchDatetimes) => {
    if (!restroom?.restroom) {
      return;
    }

    const useDates = srcDates ?? dates;
    const PromiseAll = [];

    // 詳細画面データ
    await getToiletDetail(useDates);

    // 年間平均データ
    PromiseAll.push(
      getSubTotalDatasByAverage(restroom?.restroom.restroomId).then((result) => {
        if (!unmounted.current) {
          setSubTotalDatasAverage(result);
        }
      }),
    );

    // 合計データ1日分
    PromiseAll.push(
      getSubTotal(useDates.subtotal.day.from, useDates.subtotal.day.to).then((result) => {
        if (!unmounted.current) {
          setSubTotalDatas(result);
        }

        // KL更新
        let newIsKl = false;
        for (let i = 0; i < result.length; i += 1) {
          const item = result[i];
          if (CheckKl(item.maleFlowRate) || CheckKl(item.femaleFlowRate) || CheckKl(item.multiFlowRate)) {
            newIsKl = true;
            break;
          }
        }
        if (!unmounted.current) {
          setIsKl(newIsKl);
        }
      }),
    );

    // 計測回数
    PromiseAll.push(
      getUseCountByDay(useDates).then((result) => {
        if (!unmounted.current) {
          setUseCountDatas(result);
        }
      }),
    );

    // 合計データ３日
    PromiseAll.push(
      getSubTotal(useDates.subtotal.day3.from, useDates.subtotal.day3.to).then((result) => {
        if (!unmounted.current) {
          setSubTotalDatasDay3(result);
        }
      }),
    );

    // 合計データ１週間分
    PromiseAll.push(
      getSubTotal(useDates.subtotal.week.from, useDates.subtotal.week.to).then((result) => {
        if (!unmounted.current) {
          setSubTotalDatasWeek(result);
        }
      }),
    );

    // 合計データ30日分
    PromiseAll.push(
      getSubTotal(useDates.subtotal.month.from, useDates.subtotal.month.to).then((result) => {
        if (!unmounted.current) {
          setSubTotalDatasMonth(result);
        }
      }),
    );

    await Promise.all(PromiseAll);
  };

  // 平日休日年間別利用状況の例アイコン
  const WeekendGraphIcon = (text: string, category: 'year' | 'weekday' | 'holiday') => {
    return (
      <article css={cssWeekendCompareIcon}>
        <ul>
          <li className="icon-circle">
            <div className={`color-${category}`}>
              <span>{text}</span>
            </div>
          </li>
          <li className="icon-bar">
            <div className={`color-${category}`} />
          </li>
        </ul>
      </article>
    );
  };

  // マウント判定
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  // データ反映
  useEffect(() => {
    // タブ更新
    UpdateTab(tabData.usage);

    if (datas !== null && !unmounted.current) {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas]);

  // 用途設定
  useEffect(() => {
    // ローカルストレージから最後に表示した用途を取得
    let initUsage = tabData.usage;
    const restroomId = localStorage.getItem(localStorageKeys.restroomId);
    if (restroom?.restroom && restroomId) {
      if (atob(restroomId) === restroom.restroom.restroomId) {
        const usage = localStorage.getItem(localStorageKeys.usage);
        if (usage !== null) {
          const serach = new RegExp(usageReg);
          if (serach.exec(atob(usage))) {
            initUsage = parseInt(atob(usage), 10) as Usage;
          }
        }
      } else {
        localStorage.removeItem(localStorageKeys.restroomId);
        localStorage.removeItem(localStorageKeys.usage);
      }
    }

    // 初期用途設定
    let isUsage = true;
    for (let i = 0; i < usages.length; i += 1) {
      if (initUsage === usages[i]) {
        isUsage = false;
        break;
      }
    }
    // 初期用途でタブ更新
    UpdateTab(isUsage ? usages[0] : initUsage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usages]);

  // データ更新
  useEffect(() => {
    void UpdateDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restroom?.restroom, dates]);

  // トイレデータ時間更新
  useEffect(() => {
    clearInterval(updateRestroomIntervalId.current as number);
    updateRestroomIntervalId.current = setInterval(() => {
      void UpdateDatas(dates);
    }, AUTO_UPDATE_TIME);

    return () => {
      clearInterval(updateRestroomIntervalId.current as number);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restroom?.restroom, tabData.usage, dates]);

  // タブクリック
  const handleTabClick = (selectUsage: Usage) => {
    if (restroom?.restroom) {
      localStorage.setItem(localStorageKeys.restroomId, btoa(restroom?.restroom.restroomId));
      localStorage.setItem(localStorageKeys.usage, btoa(selectUsage.toString()));
    }

    // タブ更新
    UpdateTab(selectUsage);
  };

  if (apiError.length > 0) {
    return <ImpossibleError message={apiError} />;
  }

  if (restroom?.restroom === undefined) {
    return <div />;
  }

  const viewDateFrom = dayjs(dates.subtotal.day.from).format('M/D H:mm');
  const viewDateTo = dayjs(dates.subtotal.day.to).format('M/D H:59');
  const viewDateFromByWeek = dayjs(dates.subtotal.week.from).format('M/D');
  const viewDateToByWeek = dayjs(dates.subtotal.week.to).format('M/D');
  const viewDateFromByDay3 = dayjs(dates.subtotal.day3.from).format('M/D H:mm');
  const viewDateToByDay3 = dayjs(dates.subtotal.day3.to).format('M/D H:59');

  const isNow = !(
    parseInt(dayjs(dates.calc.to).add(1, 'day').format('YYYYMMDD'), 10) <= parseInt(dayjs().format('YYYYMMDD'), 10)
  );

  return (
    <section css={cssDetail}>
      {/* 2021/10/08 使用しない事になったが、一応残す
      <PopupAlert
        restroom={restroom.restroom}
        GetWarning105={GetWarning105}
        GetWarning110={GetWarning110}
        usage={tabData.usage}
      />
      */}
      {/* ファーストビューコンテンツ */}
      <article className="header">
        <Link to={{ pathname: '/' }}>
          <img alt="logo" src={iconLogo} className="logo" />
        </Link>
      </article>
      <article className="detail-content">
        {/* タブ */}
        <TabContent title={restroom.restroom.name} tabData={tabData} usages={usages} TabClick={handleTabClick} />
        <div className="outer">
          {/* 自治体アイコン */}
          {/* <img alt="logo" src={logoOtaru} className="logo-image" /> */}
          {/* 左 */}
          <div className="detail-left">
            <DetailLeftContent
              GetUseCountByUsage={GetUseCountByUsage}
              useCountDatas={useCountDatas}
              restroom={restroom?.restroom}
              usage={tabData.usage}
              usages={usages}
              dates={dates}
              setDate={setDate}
              toiletByRestroom={toiletByRestroom}
              isNow={isNow}
            />
          </div>
          {/* 右 */}
          <div className="detail-right">
            <div className="right-scroll">
              <Heatmap
                restroom={restroom?.restroom}
                datas={datas}
                tabData={tabData}
                useCountDatas={GetUseCountByUsage()}
                isNow={isNow}
              />
            </div>
          </div>
        </div>
      </article>
      {/* 写真 */}
      {restroom?.restroom.images.length > 0 && (
        <article className="detail-graph">
          <h2>写真</h2>
          <ul css={cssPhoto}>
            {restroom?.restroom.images.map((image) => (
              <li className="photo" key={image.key}>
                <AmplifyS3Image imgKey={image.key} />
              </li>
            ))}
          </ul>
        </article>
      )}
      {/* グラフ */}
      <article className="detail-graph" style={{ height: '500px' }}>
        <h2>
          利用人数と３密状況
          <span className="datetime">{`${viewDateFromByDay3} ～ ${viewDateToByDay3}`}</span>
        </h2>
        <div className="samples">
          <img alt="icon" className="man" src={iconGraphSampleMan} />
          <img alt="icon" src={iconGraphSampleWomen} />
          <img alt="icon" src={iconGraphSampleMulti} />
        </div>
        <div className="DetailCo2AndCount add-title">
          <div className="label" style={{ padding: '0 15px' }}>
            <div className="tate">利用人数</div>
            <div>[人]</div>
          </div>
          <div className="graph">
            <DetailCo2AndCount subTotalDatas={subTotalDatasDay3} usages={usages} />
          </div>
          <div className="label">
            <div className="tate">二酸化炭素濃度</div>
            <div>[ppm]</div>
          </div>
        </div>
      </article>
      <article className="detail-graph" style={{ height: '110vh', paddingBottom: '30px' }}>
        <h2>
          手洗い状況
          <span className="datetime">{`${viewDateFrom} ～ ${viewDateTo}`}</span>
        </h2>
        <div className="WashRate">
          {/* 途中までの枠線 */}
          <div className="border">
            <div className="side border-left" />
            <div style={{ flexGrow: 1 }} />
            <div className="side border-right" />
          </div>
          <div className="graph-title">
            <p>手洗い率</p>
          </div>
          <div className="graph-sample">
            <img alt="icon" src={iconGraphSampleWashrateTotal} />
            {usages.some((v) => v === 1) && <img alt="icon" src={iconGraphSampleWashrateMan} />}
            {usages.some((v) => v === 2) && <img alt="icon" src={iconGraphSampleWashrateWomen} />}
            {usages.some((v) => v === 3) && <img alt="icon" src={iconGraphSampleWashrateMulti} />}
          </div>
          <div style={{ flexGrow: 1 }}>
            <WashRate subTotalDatas={subTotalDatas} usages={usages} />
          </div>
        </div>
        <div style={{ height: '30px' }} />
        <div className="WashUseRateListScroll">
          <ul className="WashUseRateList">
            <li>
              <div className="WashUseRate" style={{ paddingBottom: '5px' }}>
                {/* 途中までの枠線 */}
                <div className="border">
                  <div className="side border-left" />
                  <div style={{ flexGrow: 1 }} />
                  <div className="side border-right" />
                </div>
                <div className="graph-title">
                  <p>手洗い利用割合（24時間）</p>
                </div>
                <div className="graph-sample" style={{ height: '60px' }}>
                  <img alt="icon" src={iconWashrateSampleWashToilet} />
                  <img alt="icon" src={iconWashrateSampleToiletOnly} />
                  <img alt="icon" src={iconWashrateSampleWashOnly} />
                </div>
                <div style={{ flexGrow: 1 }}>
                  <WashUseRate subTotalDatas={subTotalDatas} usages={usages} />
                </div>
              </div>
            </li>
            <li style={{ width: '30px' }} />
            <li>
              <div className="WashUseRate" style={{ paddingBottom: '5px' }}>
                {/* 途中までの枠線 */}
                <div className="border">
                  <div className="side border-left" />
                  <div style={{ flexGrow: 1 }} />
                  <div className="side border-right" />
                </div>
                <div className="graph-title">
                  <p>手洗い利用割合（30日）</p>
                </div>
                <div className="graph-sample" style={{ height: '60px' }}>
                  <img alt="icon" src={iconWashrateSampleWashToilet} />
                  <img alt="icon" src={iconWashrateSampleToiletOnly} />
                  <img alt="icon" src={iconWashrateSampleWashOnly} />
                </div>
                <div style={{ flexGrow: 1 }}>
                  <WashUseRate subTotalDatas={subTotalDatasMonth} usages={usages} />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </article>
      <article className="detail-graph" style={{ height: '400px' }}>
        <h2>
          平日休日年間別利用状況
          <span className="datetime">{`${viewDateFromByWeek} ～ ${viewDateToByWeek}`}</span>
        </h2>
        <div className="samples" style={{ position: 'relative' }}>
          {WeekendGraphIcon('平日', 'weekday')}
          <div style={{ width: '20px', display: 'inline-block' }} />
          {WeekendGraphIcon('休日', 'holiday')}
          <div style={{ width: '20px', display: 'inline-block' }} />
          {WeekendGraphIcon('年間', 'year')}
        </div>
        <div className="WeekendCompare add-title">
          <div className="label" style={{ padding: '0 15px' }}>
            <div className="tate">利用人数</div>
            <div>[人]</div>
          </div>
          <div className="graph">
            <WeekendCompare subTotalDatas={subTotalDatasWeek} subTotalDatasAverage={subTotalDatasAverage} />
          </div>
        </div>
      </article>
      {restroom.restroom.isPremium && (
        <article className="detail-graph" style={{ height: '400px' }}>
          <h2>
            使用水量
            <span className="datetime">{`${viewDateFrom} ～ ${viewDateTo}`}</span>
          </h2>
          <div className="samples" style={{ position: 'relative' }}>
            <img alt="icon" src={iconWaterflowTotal} style={{ position: 'absolute', height: '33px', top: '-6px' }} />
            <img style={{ paddingLeft: '110px' }} alt="icon" src={iconWaterflowMan} />
            <img alt="icon" src={iconWaterflowWomen} />
            <img alt="icon" src={iconWaterflowMulti} />
          </div>
          <div className="WeekendCompare add-title">
            <div className="label" style={{ padding: '0 15px' }}>
              <div className="tate">水量</div>
              <div>[{isKl ? 'KL' : 'L'}]</div>
            </div>
            <div className="graph">
              <WaterFlow subTotals={subTotalDatas} usages={usages} />
            </div>
          </div>
        </article>
      )}
      {/* ローディング */}
      {isLoading && (
        <div className="loading">
          <Loader active size="massive" inline="centered" />
        </div>
      )}
      <Overlay isOpen={isLoading} />
    </section>
  );
};
