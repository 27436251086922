/** @jsx jsx */
import { FC } from 'react';
import { jsx, css } from '@emotion/core';

const cssBarInCount = css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  .bar-in-count-icon {
    display: flex;
    align-items: end;
    justify-content: center;
    width: 40px;

    img {
      bottom: 0;
    }
  }

  .bar-in-count-num-bar {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5em;
    clip-path: polygon(28px 0, 100% 0, 100% 100%, 10px 100%);
    text-align: right;
    border-radius: 20px;
  }
`;

/**
 * バーに数値が入る表示
 *
 * @param {number} count 表示する数
 * @param {string} unit 数値の後につく単位
 * @param {string} icon アイコン
 * @param {CSSProperties} barStyle Barのstyle
 * @param {boolean} isIconHeightToBar trueでアイコンの高さがbarと同じになる
 */
type BarInCountProps = {
  count?: number;
  unit?: string;
  icon?: string;
  barStyle?: React.CSSProperties;
  isIconHeightToBar?: boolean;
};
const defaultStyleBar: React.CSSProperties = {
  height: '1.6em',
  fontFamily: `'DSEG7ModernMini-Regular', sans-serif`,
  fontSize: '1.2em',
  color: '#fff',
  paddingRight: '7px',
  backgroundColor: 'rgb(59, 142, 227)',
};
const BarInCount: FC<BarInCountProps> = ({
  icon = '',
  count = 0,
  unit = '',
  barStyle = {},
  isIconHeightToBar = false,
}) => {
  const styleNumBar = { ...defaultStyleBar, ...barStyle };
  const styleIcon = isIconHeightToBar ? { height: styleNumBar.height } : { width: '100%' };

  return (
    <section css={cssBarInCount}>
      {icon.length > 0 && (
        <div className="bar-in-count-icon">
          <img alt="icon" src={icon} style={styleIcon} />
        </div>
      )}
      <div className="bar-in-count-num-bar" style={styleNumBar}>
        <span>
          {count}
          {unit.length > 0 && (
            <span style={{ padding: '0 2px', fontSize: `calc(${styleNumBar.fontSize} / 1.5)` }}>{unit}</span>
          )}
        </span>
      </div>
    </section>
  );
};

export default BarInCount;
