/** @jsx jsx */
/**
 * オーバーレイ
 */
import { FC } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { jsx, css } from '@emotion/core';
import { fadeIn } from 'utils/AnimationVariants';

/** @type {number} z-indexデフォルト値 */
const Z_INDEX_DEFAULT = 1000;

const cssOverlay = css`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
`;

type OverlayProps = {
  isOpen: boolean;
  onClick?: () => void;
  isBlack?: boolean;
  zIndex?: number;
  isTransparent?: boolean;
  isParent?: boolean;
};
/**
 * オーバーレイ表示
 *
 * @param {boolean} isOpen  表示フラグ
 * @param {function} onClick クリックイベント
 * @param {boolean} isBlack 背景色黒
 * @param {number} zIndex z-index値
 * @param {boolean} isTransparent 透明
 * @param {boolean} isParent trueで親の範囲のみ
 */
export const Overlay: FC<OverlayProps> = ({
  isOpen,
  onClick,
  isBlack = false,
  zIndex,
  isTransparent = false,
  isParent = false,
}) => {
  const useZIndex = zIndex !== undefined ? zIndex : Z_INDEX_DEFAULT;
  const cssOverlayOverwrite = css`
    position: ${isParent ? 'absolute' : 'fixed'};
    ${cssOverlay}
    z-index: ${useZIndex};
    background-color: ${isBlack ? '#000' : '#fff'};
    ${isTransparent && 'opacity: 0 !important;'}
  `;
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          css={cssOverlayOverwrite}
          role="button"
          tabIndex={0}
          aria-label="none"
          onClick={handleClick}
          initial="init"
          animate="start"
          exit="end"
          variants={fadeIn}
        />
      )}
    </AnimatePresence>
  );
};
