import Amplify from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import awsExports from 'aws-exports';
import { ListClipHgToiletsQueryVariables, ListClipHgToiletsQuery, CreateClipHgToiletInput } from 'API';
import { listClipHgToilets } from 'graphql/queries';
import { ApiGraphqlOperationWrapper } from 'utils/Common';
import { LastestLog } from 'utils/Log';
import { Usage, ToiletType } from 'utils/AppConfig';

Amplify.configure(awsExports);

type ToiletDatas = {
  [deviceId in string]: {
    [key in string]: CreateClipHgToiletInput;
  };
};

/**
 * トイレ管理クラス
 */
class Toilet {
  // トイレデータ保持
  items: ToiletDatas = {};

  /**
   * itemsの初期化
   */
  Reset = (): void => {
    this.items = {};
  };

  /**
   * 取得したトイレデータから混雑しきい値取得
   * 用途ごとの便器数 * 10
   *
   * @param {Usage} usage 用途
   * @param {ToiletType} type 便器の種類
   * @return {*} number
   */
  GetThresholdByCongestion = (usage: Usage, type?: ToiletType): number => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    let ret = 0;

    // 用途ごとの便器数をカウント
    Object.keys(self.items).forEach((deviceId) => {
      Object.keys(self.items[deviceId]).forEach((key) => {
        const search =
          type === undefined || type === null ? self.GetKeyByUsage(usage) : self.GetKeyByUsageType(usage, type);
        if (key.indexOf(search) === 0) {
          ret += 1;
        }
      });
    });

    return ret * 10;
  };

  /**
   * トイレデータ取得
   *
   * @param {string} deviceId デバイスID
   * @param {number} usage デバイスID
   * @param {number} number デバイスID
   * @param {number} type デバイスID
   * @return {*} CreateClipHgToiletInput | null
   */
  Get = (
    deviceId: string | undefined | null,
    usage: number | undefined | null,
    number: number | undefined | null,
    type: number | undefined | null,
  ): CreateClipHgToiletInput | undefined => {
    if (!deviceId || !usage || number === undefined || number === null || type === undefined || type === null) {
      return undefined;
    }
    const key = this.GetKey(usage, number, type);

    return this.items[deviceId][key] || undefined;
  };

  /**
   * 全データを配列で取得
   */
  GetArrayAll = (): CreateClipHgToiletInput[] => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const ret: CreateClipHgToiletInput[] = [];

    // 用途ごとの便器数をカウント
    Object.keys(self.items).forEach((deviceId) => {
      Object.keys(self.items[deviceId]).forEach((key) => {
        ret.push(self.items[deviceId][key]);
      });
    });

    return ret;
  };

  /**
   * 取得済みデータに含まれるUsageを取得する
   *
   * @return {Usage[]}
   */
  GetUsages = (): Usage[] => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const usages: Usage[] = [];

    // 用途を全て取得
    Object.keys(self.items).forEach((deviceId) => {
      Object.keys(self.items[deviceId]).forEach((key) => {
        usages.push(self.items[deviceId][key].usage as Usage);
      });
    });

    // 重複除外＋昇順
    const ret = usages.filter((value, index) => {
      return usages.indexOf(value) === index;
    });
    ret.sort();

    return ret;
  };

  /**
   * どれか一つでもWiFi子機接続が切断されているかどうか
   *
   * @return {Usage[]}
   */
  IsAnyOneDisconnected = (): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    let ret = false;

    // １つでも切断されていればtrue
    Object.keys(self.items).forEach((deviceId) => {
      Object.keys(self.items[deviceId]).forEach((key) => {
        if (!self.items[deviceId][key].isConnected) {
          ret = true;
        }
      });
    });

    return ret;
  };

  /**
   * 取得済みデータから指定デバイスに含まれるUsageを取得する
   *
   * @param {string} deviceId デバイスID
   * @return {Usage[]}
   */
  GetUsagesByDeviceId = (deviceId: string): Usage[] => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    if (!self.items[deviceId]) {
      return [];
    }
    const tmp = Object.keys(self.items[deviceId]).map((key) => self.items[deviceId][key].usage) as Usage[];
    const usages = tmp?.filter((value, index) => {
      return tmp.indexOf(value) === index;
    });

    return usages;
  };

  /**
   * 取得済みデータから指定デバイスが存在するかどうか
   *
   * @param {string} deviceId デバイスID
   * @return {*} trueで存在する
   */
  IsDataByDeviceId = (deviceId: string): boolean => {
    return !!this.items[deviceId];
  };

  /**
   * 便器データキー取得
   *
   * @param {number} usage デバイスID
   * @param {number} number デバイスID
   * @param {number} type デバイスID
   * @return {*} 便器データキー
   */
  GetKey = (usage: number, number: number, type: number): string => {
    return `${this.GetKeyByUsageType(usage, type)}_${number}`;
  };

  GetKeyByUsageType = (usage: number, type: number): string => {
    return `${this.GetKeyByUsage(usage)}_${type}`;
  };

  GetKeyByUsage = (usage: number): string => {
    return `toilet_${usage}`;
  };

  /**
   * 便器データ取得（保持デバイスの初期化）
   *
   * @param {string} deviceId デバイスID
   * @return {*} boolean
   */
  Reload = async (deviceId: string): Promise<void> => {
    this.items = {};

    return this.Add(deviceId);
  };

  /**
   * 便器データ取得
   *
   * @param {string} deviceId デバイスID
   * @return {*} boolean
   */
  Add = async (deviceId: string | undefined | null): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    let nextToken: string | null | undefined = null;

    if (!deviceId) {
      return;
    }

    // 取得済みの場合は何もしない
    if (self.IsDataByDeviceId(deviceId)) {
      return;
    }

    try {
      // deviceId:デバイスID
      const conditions: ListClipHgToiletsQueryVariables = {
        deviceId,
        nextToken: null,
      };

      do {
        conditions.nextToken = nextToken;

        // eslint-disable-next-line no-await-in-loop
        const result = await ApiGraphqlOperationWrapper<
          ListClipHgToiletsQueryVariables,
          GraphQLResult<ListClipHgToiletsQuery>
        >(conditions, listClipHgToilets);

        nextToken = result.data?.listClipHgToilets?.nextToken || null;

        const toiletItems = (result.data?.listClipHgToilets?.items as unknown) as CreateClipHgToiletInput[];
        toiletItems.forEach((item) => {
          const key = self.GetKey(item.usage, item.number, item.type);
          if (!self.items[deviceId]) {
            self.items[deviceId] = {};
          }
          self.items[deviceId][key] = { ...item };
        });
      } while (nextToken);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('[Toilet_GetToilet]', e);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[Toilet_GetToilet] ${(e as { message: string })?.message}`);
    }
  };
}

export default Toilet;
