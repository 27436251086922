/** @jsx jsx */
/**
 * システム設定画面
 */
import { FC } from 'react';
import { jsx } from '@emotion/core';
import Amplify from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import awsExports from 'aws-exports';
import { SystemSetting } from 'components/SystemSetting';
import { useAuth } from 'custom_hook/useAuth';
import { RestroomItems } from 'custom_hook/useRestroom';
import {
  ListClipHgUserRestroomsQueryVariables,
  ListClipHgUserRestroomsQuery,
  ClipHgUserRestroom,
  UpdateClipHgUserRestroomMutationVariables,
  UpdateClipHgUserRestroomMutation,
} from 'API';
import { listClipHgUserRestrooms } from 'graphql/queries';
import { ApiGraphqlOperationWrapper } from 'utils/Common';
import { LastestLog } from 'utils/Log';
import { updateClipHgUserRestroom } from 'graphql/mutations';

Amplify.configure(awsExports);

/**
 * システム設定画面
 */
export const SystemSettingContainer: FC = () => {
  const auth = useAuth();

  // アラートメール送信切替
  const UpdateSendAlertMail = async (restrooms: RestroomItems[], isOn: boolean) => {
    if (!auth.user?.username || !restrooms) {
      return;
    }

    try {
      const PromiseAll = [];
      for (let i = 0; i < restrooms?.length; i += 1) {
        const { restroomId } = restrooms[i];

        const updateData = {
          user: auth.user?.username,
          restroomId,
          isNotify: isOn,
        };

        PromiseAll.push(
          ApiGraphqlOperationWrapper<
            UpdateClipHgUserRestroomMutationVariables,
            GraphQLResult<UpdateClipHgUserRestroomMutation>
          >({ input: updateData }, updateClipHgUserRestroom),
        );
      }

      await Promise.all(PromiseAll);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[UpdateSendAlertMail]', error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[UpdateSendAlertMail] ${(error as { message: string })?.message}`);
    }
  };

  // 防犯アラート表示切替
  const UpdateViewAlertSecurity = async (restrooms: RestroomItems[], isOn: boolean) => {
    if (!auth.user?.username || !restrooms) {
      return;
    }

    try {
      const PromiseAll = [];
      for (let i = 0; i < restrooms?.length; i += 1) {
        const { restroomId } = restrooms[i];

        const updateData = {
          user: auth.user?.username,
          restroomId,
          isHiddenSecurityWarning: isOn,
        };

        PromiseAll.push(
          ApiGraphqlOperationWrapper<
            UpdateClipHgUserRestroomMutationVariables,
            GraphQLResult<UpdateClipHgUserRestroomMutation>
          >({ input: updateData }, updateClipHgUserRestroom),
        );
      }

      await Promise.all(PromiseAll);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[UpdateViewAlertSecurity]', error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[UpdateViewAlertSecurity] ${(error as { message: string })?.message}`);
    }
  };

  // トイレ一覧取得
  const getRestroomsList = async (): Promise<RestroomItems[]> => {
    let ret: RestroomItems[] = [];
    const nextToken: string | null | undefined = null;

    // 1000件 昇順
    const conditions: ListClipHgUserRestroomsQueryVariables = {
      user: auth.user?.username,
      limit: 1000,
      nextToken: null,
    };

    try {
      do {
        conditions.nextToken = nextToken;

        // eslint-disable-next-line no-await-in-loop
        const result = await ApiGraphqlOperationWrapper<
          ListClipHgUserRestroomsQueryVariables,
          GraphQLResult<ListClipHgUserRestroomsQuery>
        >(conditions, listClipHgUserRestrooms);

        const tmp = (result.data?.listClipHgUserRestrooms?.items as unknown) as ClipHgUserRestroom[];

        for (let i = 0; i < tmp.length; i += 1) {
          const saveRestroom = ((tmp[i].restrooms?.items as unknown) as RestroomItems[]).map((item) => {
            // eslint-disable-next-line no-param-reassign
            item.isNotify = tmp[i].isNotify ?? false;
            // eslint-disable-next-line no-param-reassign
            item.isHiddenSecurityWarning = tmp[i].isHiddenSecurityWarning ?? false;

            return item;
          });
          ret = [...ret, ...saveRestroom];
        }
      } while (nextToken !== null);

      // 名前でソート
      ret.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[getRestroomsListError]', error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[getRestroomsListError] ${(error as { message: string })?.message}`);
    }

    return ret;
  };

  return (
    <SystemSetting
      getRestroomsList={getRestroomsList}
      UpdateSendAlertMail={UpdateSendAlertMail}
      UpdateViewAlertSecurity={UpdateViewAlertSecurity}
    />
  );
};
