/** @jsx jsx */
import React, { FC, useState, useRef, useEffect } from 'react';
import { jsx, css } from '@emotion/core';
import Amplify from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import awsExports from 'aws-exports';
import {
  ListClipHgUserRestroomsQueryVariables,
  ListClipHgUserRestroomsQuery,
  CreateClipHgSubtotalInput,
  ClipHgUserRestroom,
  ClipHgArea,
} from 'API';
import { Top, AllRestroomDatas, Ranking, RankingData, WarningData, WarningDataByType } from 'components/Top';
import {
  // nearbyRestrooms,
  listClipHgUserRestrooms,
} from 'graphql/queries';
import { RestroomItems } from 'custom_hook/useRestroom';
import { LastestLog } from 'utils/Log';
import {
  ApiGraphqlOperationWrapper,
  GetSubtotalByRestroomId,
  GetSubTotalToUseCount,
  GetSubTotalToWashRate,
  GetAlertIconTypeByWarningData,
  GetImageList,
  ConvertCoordinateByUser,
} from 'utils/Common';
import { AUTO_UPDATE_TIME, AlertIconType, DISP_TOILET_WINDOW_ZOOM_LEVEL } from 'utils/AppConfig';
import { GetWarningValue } from 'utils/Warnings';
import { GetWarningItems } from 'containers/WarningList';
import dayjs from 'dayjs';
import { Loader } from 'semantic-ui-react';
import { Overlay } from 'components/Overlay';
import isBetween from 'dayjs/plugin/isBetween';
import { useToDate } from 'custom_hook/useToDate';
import { useAuth } from 'custom_hook/useAuth';

dayjs.extend(isBetween);
Amplify.configure(awsExports);

// メンテナンス画面のワーニング範囲（日）
const MaintenanceWarningRange = 2;

/**
 * マップの初期データ
 */
export type MapInitData = {
  lat: number;
  lon: number;
  zoom: number;
  dispTwZoomLevel: number;
};

/**
 * 初期化されたランキングデータを取得
 */
const InitRanking = (): Ranking => {
  return {
    UseAsc: [],
    UseDesc: [],
    WashAsc: [],
    WashDesc: [],
  };
};

/**
 * 縮尺単位
 */
export type AreaRenge = 'pref' | 'city' | 'ward';

/**
 * エリアデータ
 */
type AreaData = {
  lat: number;
  lon: number;
  areaId: string;
  name: string;
  restrooms: RestroomItems[];
};
export type AreaDatas = {
  [key in string]: AreaData;
};

/**
 * 全ての縮尺単位のエリアデータ
 */
export type AllAreaDatas = {
  pref: AreaDatas;
  prefRestroomIds: string[];
  city: AreaDatas;
  cityRestroomIds: string[];
  ward: AreaDatas;
  wardRestroomIds: string[];
};

/**
 * 初期化されたトイレ全体データを取得
 */
const InitAllRestroomDatas = (): AllRestroomDatas => {
  return {
    Use: {
      male: 0,
      female: 0,
      multi: 0,
    },
    Wash: {
      male: {
        all: 0,
        wash: 0,
      },
      female: {
        all: 0,
        wash: 0,
      },
      multi: {
        all: 0,
        wash: 0,
      },
    },
  };
};

/**
 * 初期化されたアラートデータ種類別を取得
 */
const InitWarningByTpye = (): WarningDataByType => {
  return {
    dirt: [],
    repair: [],
    warning: [],
    wash: [],
  };
};

const cssTopContainer = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
`;

/**
 * TOP画面
 */
const TopContainer: FC = () => {
  const umnounted = useRef(false);
  const [restrooms, setRestrooms] = useState<RestroomItems[] | undefined>();
  const [allRestroomDatas, setAllRestroomDatas] = useState<AllRestroomDatas>(InitAllRestroomDatas());
  const [ranking, setRanking] = useState<Ranking>(InitRanking());
  const [warningByType, setWarningByType] = useState<WarningDataByType | undefined>();
  const updateIntervalId = useRef<NodeJS.Timeout | number>(0);
  const updateTimerId = useRef<NodeJS.Timeout | number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const toDate = useToDate();
  const [allAreaDatas, setAllAreaDatas] = useState<AllAreaDatas>({
    pref: {},
    prefRestroomIds: [],
    city: {},
    cityRestroomIds: [],
    ward: {},
    wardRestroomIds: [],
  });
  const [mapInit, setMapInit] = useState<MapInitData>();
  const auth = useAuth();

  // 日付変更
  const setToDateWrapper = (newToDate: string) => {
    if (!umnounted.current) {
      toDate.setToDate(newToDate, 'top');
    }
  };

  // トイレIDの配列からsubtotalを取得する
  const GetSubtotalsByRestroomIds = async (restroomIds: string[]): Promise<CreateClipHgSubtotalInput[]> => {
    if (restroomIds.length <= 0) {
      return [];
    }

    const PromiseAll = [];
    const ret: CreateClipHgSubtotalInput[] = [];
    for (let i = 0; i < restroomIds.length; i += 1) {
      PromiseAll.push(
        GetSubtotalByRestroomId(restroomIds[i], toDate.toDates.top).then((subtotal) => {
          ret.push(...subtotal);
        }),
      );
    }
    await Promise.all(PromiseAll);

    return ret;
  };

  // トイレIDの配列からWarningアイコンを取得する
  const GetWarningIconByRestroomIds = async (restroomIds: string[]): Promise<AlertIconType> => {
    let ret: AlertIconType = 'good';

    if (restroomIds.length <= 0) {
      return ret;
    }

    for (let i = 0; i < restroomIds.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const warningItems = await GetWarningItems(restroomIds[i], auth.user, toDate.toDates.top);
      for (let j = 0; j < warningItems.length; j += 1) {
        const value = GetWarningValue(warningItems[j]);
        if (value) {
          ret = GetAlertIconTypeByWarningData(warningItems[j], ret);
        }
        if (ret === 'repair') {
          break;
        }
      }
      if (ret === 'repair') {
        break;
      }
    }

    return ret;
  };

  // 種類別アラート一覧取得
  const getAllWarningByType = async () => {
    if (restrooms === undefined) {
      return;
    }
    const ret: WarningDataByType = InitWarningByTpye();

    const PromiseAll = [];

    for (let i = 0; i < restrooms.length; i += 1) {
      const restroom = restrooms[i];
      try {
        PromiseAll.push(
          GetWarningItems(
            restroom.restroomId,
            auth.user,
            toDate.toDates.top,
            dayjs(toDate.toDates.top)
              .subtract(MaintenanceWarningRange, 'days')
              .format('YYYY-MM-DD HH:mm:ss')
              .toString(),
          ).then((warningItems) => {
            warningItems.forEach((item) => {
              const warning = GetWarningValue(item);
              if (warning) {
                const data: WarningData = {
                  warningId: item.warningId,
                  date: item.sendDateTime,
                  warningNumber: item.warningNumber,
                  info: warning.title,
                  title: restroom.name,
                  usage: item.usage,
                  sortKey: item.sortKey ?? '',
                };

                if (item.type) {
                  if (item.type === 3) {
                    ret.wash.push(data);
                  }
                }
                if (item.warningNumber >= 300) {
                  ret.dirt.push(data);
                } else if (item.warningNumber >= 200) {
                  ret.warning.push(data);
                } else {
                  ret.repair.push(data);
                }
              }
            });
          }),
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[getAllWarningByType]', error);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        LastestLog(`[getAllWarningByType] ${(error as { message: string })?.message}`);
      }
    }

    await Promise.all(PromiseAll);

    const Sort = (a: WarningData, b: WarningData) => {
      if (a.sortKey && b.sortKey) {
        if (a.sortKey < b.sortKey) return 1;
        if (a.sortKey > b.sortKey) return -1;
      }

      return 0;
    };
    ret.wash.sort((a, b) => Sort(a, b));
    ret.dirt.sort((a, b) => Sort(a, b));
    ret.repair.sort((a, b) => Sort(a, b));
    ret.warning.sort((a, b) => Sort(a, b));

    if (!umnounted.current) {
      setWarningByType(ret);
    }
  };

  // ランキングデータ取得
  const getRankings = async () => {
    if (restrooms === undefined) {
      return;
    }
    const ret = InitRanking();

    try {
      // データ取得
      const PromiseAll = [];
      for (let i = 0; i < restrooms.length; i += 1) {
        PromiseAll.push(
          GetSubtotalByRestroomId(restrooms[i].restroomId, toDate.toDates.top).then((subtotals) => {
            const useCount = GetSubTotalToUseCount(subtotals);
            const washRate = GetSubTotalToWashRate(subtotals) ?? 0;
            const rankingDataByUse: RankingData = {
              name: restrooms[i].name,
              num: useCount,
              icon: false,
              sortKey: `${useCount.toString().padStart(30, '0')}_${restrooms[i].name}`,
            };

            const rankingDataByWash: RankingData = {
              name: restrooms[i].name,
              num: washRate,
              icon: false,
              sortKey: `${washRate.toString().padStart(30, '0')}_${restrooms[i].name}`,
            };

            ret.UseAsc.push(rankingDataByUse);
            ret.UseDesc.push(rankingDataByUse);
            ret.WashAsc.push(rankingDataByWash);
            ret.WashDesc.push(rankingDataByWash);
          }),
        );
      }

      await Promise.all(PromiseAll);

      // 並び替え
      const Asc = (a: RankingData, b: RankingData) => {
        if (a.sortKey < b.sortKey) return -1;
        if (a.sortKey > b.sortKey) return 1;

        return 0;
      };
      const Desc = (a: RankingData, b: RankingData) => {
        if (a.sortKey < b.sortKey) return 1;
        if (a.sortKey > b.sortKey) return -1;

        return 0;
      };

      ret.UseAsc = ret.UseAsc.sort((a, b) => Asc(a, b)).slice(0, 5);
      ret.UseDesc = ret.UseDesc.sort((a, b) => Desc(a, b)).slice(0, 5);
      ret.WashAsc = ret.WashAsc.sort((a, b) => Asc(a, b)).slice(0, 5);
      ret.WashDesc = ret.WashDesc.sort((a, b) => Desc(a, b)).slice(0, 5);

      if (!umnounted.current) {
        setRanking(ret);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[getRankings]', error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[getRankings] ${(error as { message: string })?.message}`);
    }
  };

  // トイレ全体データ取得
  const getAllRestroomSubtotal = async () => {
    if (restrooms === undefined) {
      return;
    }
    const ret = InitAllRestroomDatas();

    try {
      const PromiseAll = [];
      for (let i = 0; i < restrooms.length; i += 1) {
        PromiseAll.push(
          GetSubtotalByRestroomId(restrooms[i].restroomId, toDate.toDates.top).then((subTotalDatas) => {
            subTotalDatas.forEach((item) => {
              // 利用人数
              ret.Use.male += item.maleBothUse + item.maleToiletUse + item.maleWashUse;
              ret.Use.female += item.femaleBothUse + item.femaleToiletUse + item.femaleWashUse;
              ret.Use.multi += item.multiBothUse + item.multiToiletUse + item.multiWashUse;

              // 手洗い率
              ret.Wash.male.all += item.maleBothUse + item.maleToiletUse;
              ret.Wash.male.wash += item.maleBothUse;
              ret.Wash.female.all += item.femaleBothUse + item.femaleToiletUse;
              ret.Wash.female.wash += item.femaleBothUse;
              ret.Wash.multi.all += item.multiBothUse + item.multiToiletUse;
              ret.Wash.multi.wash += item.multiBothUse;
            });
          }),
        );
      }

      await Promise.all(PromiseAll);

      if (!umnounted.current) {
        setAllRestroomDatas(ret);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[getAllRestroomSubtotal]', error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[getAllRestroomSubtotal] ${(error as { message: string })?.message}`);
    }
  };

  // トイレ一覧取得
  const getRestroomsList = async () => {
    let tmpRestroomItems: RestroomItems[] = [];
    const nextToken: string | null | undefined = null;

    // 1000件 昇順
    const conditions: ListClipHgUserRestroomsQueryVariables = {
      user: auth.user?.username,
      limit: 1000,
      nextToken: null,
    };

    try {
      do {
        conditions.nextToken = nextToken;

        // eslint-disable-next-line no-await-in-loop
        const result = await ApiGraphqlOperationWrapper<
          ListClipHgUserRestroomsQueryVariables,
          GraphQLResult<ListClipHgUserRestroomsQuery>
        >(conditions, listClipHgUserRestrooms);

        const tmp = (result.data?.listClipHgUserRestrooms?.items as unknown) as ClipHgUserRestroom[];

        for (let i = 0; i < tmp.length; i += 1) {
          const saveRestroom = ((tmp[i].restrooms?.items as unknown) as RestroomItems[]).map((item) => {
            // eslint-disable-next-line no-param-reassign
            item.isNotify = tmp[i].isNotify ?? false;

            return item;
          });
          tmpRestroomItems = [...tmpRestroomItems, ...saveRestroom];
        }
      } while (nextToken !== null);

      // 名前でソート
      tmpRestroomItems.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      });

      // 全てのトイレにデータ追加
      const restroomItems = await Promise.all(
        tmpRestroomItems.map(async (restroom) => {
          const images = await GetImageList(restroom.restroomId);

          return { ...restroom, images };
        }),
      );

      if (!umnounted.current) {
        if (restroomItems) {
          setRestrooms(restroomItems);
        } else {
          setRestrooms([]);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[getRestroomsListError]', error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[getRestroomsListError] ${(error as { message: string })?.message}`);
    }
  };

  // エリアデータ更新
  useEffect(() => {
    if (restrooms === undefined) {
      return;
    }

    // エリアデータ
    const newAreaPref: AreaDatas = {};
    const newPrefRestroomIds: string[] = [];
    const newAreaCity: AreaDatas = {};
    const newCityRestroomIds: string[] = [];
    const newAreaWard: AreaDatas = {};
    const newWardRestroomIds: string[] = [];
    const SetArea = (returnArray: AreaDatas, restroom: RestroomItems, area: ClipHgArea | undefined): boolean => {
      if (area) {
        if (area.areaId) {
          if (!returnArray[area.areaId]) {
            // eslint-disable-next-line no-param-reassign
            returnArray[area.areaId] = {
              lat: area.location?.lat ?? 0,
              lon: area.location?.lon ?? 0,
              name: area.name ?? '',
              areaId: area.areaId ?? '',
              restrooms: [],
            };
          }
          returnArray[area.areaId].restrooms.push(restroom);

          return true;
        }
      }

      return false;
    };

    // エリアデータを保持：データがない場合は下位エリアのデータを表示する
    restrooms.forEach((restroom) => {
      // pref
      if (!SetArea(newAreaPref, restroom, restroom.pref)) {
        if (!SetArea(newAreaPref, restroom, restroom.city)) {
          if (!SetArea(newAreaPref, restroom, restroom.ward)) {
            newPrefRestroomIds.push(restroom.restroomId);
          }
        }
      }

      // city
      if (!SetArea(newAreaCity, restroom, restroom.city)) {
        if (!SetArea(newAreaCity, restroom, restroom.ward)) {
          newCityRestroomIds.push(restroom.restroomId);
        }
      }

      // ward
      if (!SetArea(newAreaWard, restroom, restroom.ward)) {
        newWardRestroomIds.push(restroom.restroomId);
      }
    });

    // 下位エリアデータが存在する場合は入れ替える
    Object.keys(newAreaPref).forEach((key) => {
      if (key in newAreaCity) {
        newAreaPref[key] = newAreaCity[key];
      }
      if (key in newAreaWard) {
        newAreaPref[key] = newAreaWard[key];
      }
    });
    Object.keys(newAreaCity).forEach((key) => {
      if (key in newAreaWard) {
        newAreaCity[key] = newAreaWard[key];
      }
    });

    if (!umnounted.current) {
      setAllAreaDatas({
        pref: newAreaPref,
        prefRestroomIds: newPrefRestroomIds,
        city: newAreaCity,
        cityRestroomIds: newCityRestroomIds,
        ward: newAreaWard,
        wardRestroomIds: newWardRestroomIds,
      });
    }
  }, [restrooms]);

  useEffect(() => {
    return () => {
      umnounted.current = true;
    };
  }, []);

  // 初期マップデータ更新
  useEffect(() => {
    if (auth.user) {
      const coordinate = auth.user.attributes?.['custom:map_coordinate'];
      const level = auth.user.attributes?.['custom:map_zoom_level'] ?? 13;
      const dispTwZoomLevel = auth.user.attributes?.['custom:disp_tw_zoom_level'] ?? DISP_TOILET_WINDOW_ZOOM_LEVEL;

      const pos = ConvertCoordinateByUser(coordinate);
      if (!umnounted.current) {
        setMapInit({
          lat: pos.lat,
          lon: pos.lon,
          zoom: Number(level),
          dispTwZoomLevel: Number(dispTwZoomLevel),
        });
      }
    }
  }, [auth]);

  // データ更新
  useEffect(() => {
    const fn = async () => {
      if (!umnounted.current) {
        setIsLoading(true);
      }

      const PromiseAll = [];
      PromiseAll.push(getRankings());
      PromiseAll.push(getAllRestroomSubtotal());
      PromiseAll.push(getAllWarningByType());
      await Promise.all(PromiseAll);

      if (!umnounted.current) {
        setIsLoading(false);
      }
    };
    void fn();

    clearInterval(updateIntervalId.current as number);
    updateIntervalId.current = setInterval(() => {
      void fn();
    }, AUTO_UPDATE_TIME);

    return () => {
      clearInterval(updateIntervalId.current as number);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrooms, toDate]);

  // 日付の更新
  useEffect(() => {
    const fn = () => {
      const dayjsNow = dayjs().subtract(1, 'hours');
      const updateDate = dayjs().subtract(1, 'hours').format('YYYY-MM-DD HH:00:00');
      // 時間のみ
      if (
        dayjs(toDate.toDates.top).format('YYYY-MM-DD') === dayjsNow.format('YYYY-MM-DD') &&
        dayjs(toDate.toDates.top).format('HH') !== dayjsNow.format('HH')
      ) {
        setToDateWrapper(updateDate);

        return;
      }
      // 日付が変わった
      if (
        dayjs(toDate.toDates.top).format('YYYY-MM-DD') !== dayjsNow.format('YYYY-MM-DD') &&
        dayjs(toDate.toDates.top).isBetween(dayjsNow.subtract(23, 'hours'), dayjsNow)
      ) {
        setToDateWrapper(updateDate);
      }
    };
    fn();

    clearInterval(updateTimerId.current as number);
    updateTimerId.current = setInterval(() => {
      fn();
    }, AUTO_UPDATE_TIME);

    // ログアウト不要でisAdminを使用するためAuth再読み込み
    auth.updateUser();

    return () => {
      clearInterval(updateTimerId.current as number);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!mapInit) {
    return <div />;
  }

  return (
    <React.Fragment>
      {isLoading && (
        <React.Fragment>
          <div css={cssTopContainer}>
            <Loader active size="massive" inline="centered" />
          </div>
          <Overlay isOpen={isLoading} isTransparent />
        </React.Fragment>
      )}
      <Top
        mapDefaultOption={mapInit}
        restrooms={restrooms}
        getRestroomsList={getRestroomsList}
        allRestroomDatas={allRestroomDatas}
        ranking={ranking}
        warningByType={warningByType}
        GetSubtotalsByRestroomIds={GetSubtotalsByRestroomIds}
        GetWarningIconByRestroomIds={GetWarningIconByRestroomIds}
        toDate={toDate.toDates.top}
        setToDate={setToDateWrapper}
        allAreaDatas={allAreaDatas}
        auth={auth}
      />
    </React.Fragment>
  );
};

export default TopContainer;
