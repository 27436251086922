/** @jsx jsx */
/**
 * エラー表示
 * 復帰できないようなエラーのみに使用
 */
import { FC, useEffect, useState } from 'react';
import { jsx, css } from '@emotion/core';
import { Redirect, Link } from 'react-router-dom';

// リダイレクト秒数
const REDIRECT_SEC = 5;

const cssMain = css`
  display: table-cell;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;

  .title {
    font-size: 2em;
    font-weight: bold;
  }
`;

type ImpossibleErrorProps = {
  message: string;
  AutoRedirect?: boolean;
};
const ImpossibleError: FC<ImpossibleErrorProps> = ({ message, AutoRedirect = true }) => {
  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (AutoRedirect) {
        setIsRedirect(true);
      }
    }, REDIRECT_SEC * 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isRedirect) {
    return <Redirect to="/" />;
  }

  return (
    <div css={cssMain}>
      <div>
        <p className="title">{message}</p>
        <p>
          このページをリロードするか、<Link to="/">TOPページ</Link>へ戻って下さい。
        </p>
        {AutoRedirect && <p>{REDIRECT_SEC}秒後に自動でTOPページへ戻ります。</p>}
      </div>
    </div>
  );
};
export default ImpossibleError;
