/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC, useRef, useState, useEffect } from 'react';
import { weatherIconType, AUTO_UPDATE_WEATHER } from 'utils/AppConfig';
import { GetWeather, GetWeatherParams, MapLatlon, ConvertCoordinateByUser } from 'utils/Common';
import { WeatherFolder } from 'components/WeatherFolder';
import bgWeatherFolderToday from 'images/background/weather_folder/today.svg';
import bgWeatherFolderTomorrow from 'images/background/weather_folder/tomorrow.svg';
import bgWeatherFolderAfterTomorrow from 'images/background/weather_folder/after_tomorrow.svg';
import { useAuth } from 'custom_hook/useAuth';

const cssWeatherFolderContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

/**
 * 天気フォルダー
 */
const WeatherFolderContainer: FC = () => {
  const unmounted = useRef(false);
  const intervalId = useRef<NodeJS.Timeout | number>(0);
  const [mapPos, setMapPos] = useState<MapLatlon>();
  const [weathers, setWeathers] = useState<GetWeatherParams[]>([]);
  const auth = useAuth();

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  // ユーザー変更
  useEffect(() => {
    if (auth.user) {
      const coordinate = auth.user.attributes?.['custom:map_coordinate'];
      const pos = ConvertCoordinateByUser(coordinate);

      if (!unmounted.current) {
        setMapPos(pos);
      }
    }
  }, [auth]);

  // 座標変更
  useEffect(() => {
    const Update = async () => {
      if (!mapPos) {
        return;
      }

      const weather = await GetWeather(mapPos.lat, mapPos.lon);
      if (!unmounted.current && typeof weather !== 'boolean') {
        setWeathers(weather);
      }
    };

    const fn = () => void Update();
    fn();

    clearInterval(intervalId.current as number);
    intervalId.current = setInterval(fn, AUTO_UPDATE_WEATHER);

    return () => {
      clearInterval(intervalId.current as number);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPos]);

  if (weathers.length < 3) {
    return <div />;
  }

  return (
    <section css={cssWeatherFolderContainer}>
      <WeatherFolder
        folder={bgWeatherFolderToday}
        tempMin={weathers[0].tempMin}
        tempMax={weathers[0].tempMax}
        humidity={weathers[0].humidity}
        icon={weathers[0].icon as weatherIconType}
      />
      <WeatherFolder
        folder={bgWeatherFolderTomorrow}
        tempMin={weathers[1].tempMin}
        tempMax={weathers[1].tempMax}
        humidity={weathers[1].humidity}
        icon={weathers[1].icon as weatherIconType}
      />
      <WeatherFolder
        folder={bgWeatherFolderAfterTomorrow}
        tempMin={weathers[2].tempMin}
        tempMax={weathers[2].tempMax}
        humidity={weathers[2].humidity}
        icon={weathers[2].icon as weatherIconType}
      />
    </section>
  );
};

export default WeatherFolderContainer;
