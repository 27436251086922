/** @jsx jsx */
/**
 * ワーニング画面
 */
import { FC, useRef, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { Loader } from 'semantic-ui-react';
import { Usage, alertColor, alertIcon, usageIconWhite, AlertIconType, toiletTypeText } from 'utils/AppConfig';
import { RestroomItems } from 'custom_hook/useRestroom';
import { GetWarningValue, ConvertClipHgWarningInput, CheckViewData } from 'utils/Warnings';
import { GetStatusIcon } from 'utils/Common';
import Toilet from 'utils/Toilet';
import InfiniteScroll from 'react-infinite-scroller';

const cssWarningList = css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  user-select: none;
  background: linear-gradient(180deg, rgba(9, 19, 36, 0.9), rgba(36, 97, 131, 0.9));
  border: solid 1px #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #fff;

  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .warning-list-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 3em;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      border-bottom: solid 2px rgba(255, 255, 255, 0.4);
    }

    .icon {
      justify-content: center;
      width: 35px;

      img {
        height: calc(100% / 2);
      }
    }

    .text {
      justify-content: start;
      width: 130px;
      padding-left: 0.5em;
      font-size: 25px;
      font-weight: bold;
    }
  }

  .warning-list {
    position: relative;
    display: flex;
    flex-basis: 0;
    flex-direction: column;
    width: 100%;
    padding: 0 3px;
    overflow-x: hidden;
    overflow-y: auto;

    ul {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin: 0;
      list-style: none;

      .icon {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        text-align: center;

        .icon-img {
          width: 100%;
        }
      }

      .text {
        width: 300px;
      }

      li {
        color: #fff;
        vertical-align: middle;
        border-bottom: solid 2px rgba(255, 255, 255, 0.4);

        .info {
          display: flex;
          flex-direction: column;

          li {
            border-bottom: none;
          }

          .row {
            display: flex;
            width: 100%;
            height: 50%;
          }

          .row.date {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            font-size: 12px;

            li + li {
              padding-left: 7px;
            }

            .usage-icon {
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                height: 19px;
              }
            }
          }

          .row.info {
            align-items: start;
            font-size: 12px;
          }

          .row .text {
            font-size: 13px;
          }
        }
      }
    }
  }

  .warning-list.color-dirt {
    ::-webkit-scrollbar-thumb {
      background: ${alertColor.dirt};
    }
  }

  .warning-list.color-repair {
    ::-webkit-scrollbar-thumb {
      background: ${alertColor.repair};
    }
  }

  .warning-list.color-warning {
    ::-webkit-scrollbar-thumb {
      background: ${alertColor.warning};
    }
  }

  .number {
    position: relative;
    display: inline-block;
    width: 40px;
    padding-left: 5px;
    margin-right: 13px;
    font-weight: bold;
    background-color: #fff;

    &::after {
      position: absolute;
      top: 0;
      right: -10px;
      width: 0;
      height: 100%;
      margin: auto;
      content: '';
      border-color: transparent transparent transparent #fff;
      border-style: solid;
      border-width: 8px 0 10px 10px;
    }
  }
`;

// 一覧表示データ
type ViewItem = {
  warningId: string;
  usage: Usage;
  type: AlertIconType;
  warningNumber: number;
  datetime: string;
  title: string;
  text: string;
  toiletName?: string | null;
  toiletTypeName?: string;
};
/**
 * 警告一覧
 *
 * @param {ConvertClipHgWarningInput[]} warnings 警告データ
 * @param {Usage} usage             使用用途
 * @param {function} getWarnings    データ取得
 * @param {string} apiError         APIエラーメッセージ
 * @param {RestroomItems} restroom  トイレデータ
 * @param {Toilet} toiletAll        トイレに紐づくすべての便器データ
 */
type WarningListProps = {
  warnings: ConvertClipHgWarningInput[] | null;
  usage: Usage;
  apiError: string;
  restroom: RestroomItems | undefined;
  toiletAll: Toilet;
};
export const WarningList: FC<WarningListProps> = ({ warnings, usage, apiError = '', restroom, toiletAll }) => {
  const unmounted = useRef(false);
  const [datas, setDatas] = useState<ViewItem[]>([]);
  const [scrollList, setScrollList] = useState<ViewItem[]>([]);
  const [isFetchList, setIsFetchList] = useState(false);
  const LoadCount = 20;

  // スクロールデータ読み込み
  const LoadMore = (index: number) => {
    if (isFetchList) {
      return;
    }
    if (datas === undefined) {
      return;
    }
    if (!unmounted.current) {
      setIsFetchList(true);
    }
    const start = (index - 1) * LoadCount;
    const end = start + LoadCount;

    if (!unmounted.current) {
      setScrollList(datas.slice(0, end));
    }
  };

  // 元データ変更時
  useEffect(() => {
    if (datas === undefined) {
      return;
    }

    if (datas.length <= 0) {
      if (!unmounted.current) {
        setScrollList([]);
      }
    } else if (!unmounted.current) {
      setScrollList(datas.slice(0, LoadCount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas]);

  // 無限スクロールに表示するデータがあるかどうか
  useEffect(() => {
    if (datas === undefined) {
      return;
    }
    if (scrollList.length > 0) {
      if (!unmounted.current) {
        setIsFetchList(scrollList.length >= datas.length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollList]);

  useEffect(() => {
    if (warnings === null) {
      return;
    }

    const ret: ViewItem[] = [];
    for (let i = 0; i < warnings.length; i += 1) {
      const item = warnings[i];

      if (CheckViewData(restroom, item, toiletAll, usage)) {
        const value =
          item.warningNumber === 204
            ? GetWarningValue(item, toiletAll.GetThresholdByCongestion(usage))
            : GetWarningValue(item);
        if (value) {
          ret.push({
            warningId: item.warningId,
            warningNumber: item.warningNumber,
            title: value.title,
            text: value.text,
            datetime: value.datetime || '',
            usage,
            type: value.type,
            toiletName: value.range === 'toilet' ? item.toiletName : undefined,
            toiletTypeName: value.range === 'toilet' && item.type ? toiletTypeText[item.type] : undefined,
          });
        }
      }
    }

    if (!unmounted.current) {
      setDatas(ret);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warnings, usage]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  // エラー
  if (apiError.length > 0) {
    return (
      <section css={cssWarningList}>
        <span style={{ color: '#fff' }}>{apiError}</span>
      </section>
    );
  }

  // ローディング
  if (warnings === null) {
    return (
      <section css={cssWarningList}>
        <span className="loading-spinner">
          <Loader active size="massive" />
        </span>
      </section>
    );
  }

  // 優先度はrepair→warning→dirt
  const type = ((): AlertIconType => {
    let ret: AlertIconType = 'good';
    for (let i = 0; i < datas.length; i += 1) {
      if (datas[i].type === 'repair') {
        return 'repair';
      }
      if (ret !== 'warning') {
        ret = datas[i].type;
      }
    }

    return ret;
  })();
  const Color = alertColor[type];

  return (
    <section css={cssWarningList}>
      <ul className="warning-list-title">
        <li className="text" style={{ color: `${Color}` }}>
          Warning
        </li>
        {datas.some((b) => b.type === 'repair') && (
          <li className="icon">
            <img alt="icon" src={GetStatusIcon('repair')} />
          </li>
        )}
        {datas.some((b) => b.type === 'warning') && (
          <li className="icon">
            <img alt="icon" src={GetStatusIcon('warning')} />
          </li>
        )}
        {datas.some((b) => b.type === 'dirt') && (
          <li className="icon">
            <img alt="icon" src={GetStatusIcon('dirt')} />
          </li>
        )}
        <li style={{ flexGrow: 1 }} />
      </ul>
      <article className={`warning-list color-${type}`}>
        <InfiniteScroll
          pageStart={0}
          loadMore={LoadMore}
          hasMore={!isFetchList}
          useWindow={false}
          loader={<div key={0}>loading</div>}
        >
          {scrollList.map((item) => {
            const key = `WarningList-id-${item.warningId}`;

            return (
              <ul key={key}>
                <li className="icon">
                  <img className="icon-img" alt="icon" src={alertIcon[item.type]} />
                </li>
                <li className="text">
                  <div className="info">
                    <ul className="row date">
                      <li>{item.datetime}</li>
                      <li className="usage-icon">
                        <img alt="icon" src={usageIconWhite[item.usage]} />
                      </li>
                      <li>{item.toiletTypeName && item.toiletTypeName}</li>
                      <li>
                        &nbsp;
                        {item.toiletName && item.toiletName}
                      </li>
                    </ul>
                    <div className="row">
                      <span className="text">
                        <div className="number" style={{ color: `${alertColor[item.type]}` }}>
                          {item.warningNumber}
                        </div>
                        {item.text}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            );
          })}
          <ul style={{ height: '1em' }} />
        </InfiniteScroll>
      </article>
    </section>
  );
};
