/** @jsx jsx */
/**
 * トイレWindow
 *
 * 注意
 * マップ上のコンポーネントは再描画が頻繁に発生するためコンテナでデータを読み込んではいけない
 */
import { GraphQLResult } from '@aws-amplify/api';
import { FC, useEffect, useRef, useState } from 'react';
import { jsx } from '@emotion/core';
import { ToiletWindow, Pos, DEFAULT_POS } from 'components/ToiletWindow';
import { TopType } from 'custom_hook/useTopType';
import { CreateClipHgSubtotalInput, UpdateClipHgRestroomMutation, UpdateClipHgRestroomMutationVariables } from 'API';
import { updateClipHgRestroom } from 'graphql/mutations';
import { ViewWarning } from 'utils/Warnings';
import { AlertIconType, Usage } from 'utils/AppConfig';
import { RestroomItems } from 'custom_hook/useRestroom';
import { ApiGraphqlOperationWrapper } from 'utils/Common';
import { LastestLog } from 'utils/Log';

/**
 * トイレWindow
 *
 * @param {RestroomItems} restroom トイレデータ
 * @param {TopType} topType TOP種別
 * @param {HTMLDivElement} parent 親オブジェクト
 * @param {CreateClipHgSubtotalInput[]} subTotals subtotalデータ
 * @param {ViewWarning[]} warnings warningデータ
 * @param {AlertIconType} iconType アラート種別
 * @param {Usage[]} usages 使用全用途
 * @param {number} iconType アラート種別
 * @param {number} mapZoomLevel 地図のズームレベル
 * @param {number} dispTwZoomLevel トイレウィンドウ表示ズームレベル
 */
type ToiletWindowContainerProps = {
  restroom: RestroomItems | undefined;
  topType: TopType;
  parent: HTMLDivElement | null;
  subTotals: CreateClipHgSubtotalInput[];
  warnings: ViewWarning[] | undefined;
  iconType: AlertIconType;
  usages: Usage[];
  mapZoomLevel: number | undefined;
  dispTwZoomLevel?: number;
};
export const ToiletWindowContainer: FC<ToiletWindowContainerProps> = ({
  restroom,
  topType,
  parent,
  subTotals,
  warnings,
  iconType,
  usages,
  mapZoomLevel,
  dispTwZoomLevel,
}) => {
  const [topleft, setTopleft] = useState<Pos | null>(null);
  const unmounted = useRef(false);

  // テスト用
  const pos: {
    [key in string]: Pos;
  } = {
    // '4f974d2b-076e-4ab3-9eb1-4c510792c2a4': { x: -188, y: -171 },
    'ded4fcc5-0a40-4c39-856a-af0707f810a5': { x: -158, y: -101 },
    'a28289df-4850-41e6-ac67-df4debca2a3a': { x: 0, y: 0 },
    '9b6f12c7-e901-4340-b0f3-9f3c7aba3bbe': { x: -88, y: -88 },
    '64b2aa0a-ed82-4668-96cf-314f3859b543': { x: 50, y: 50 },
  };
  // dev用テストデータ
  if (restroom?.restroomId !== undefined) {
    if (!pos[restroom?.restroomId]) {
      pos[restroom?.restroomId] = {
        x: 0,
        y: 0,
      };
    }
  }

  // 位置保存
  const SavePos = async (savePos?: Pos) => {
    const newPos = savePos ?? DEFAULT_POS;
    if (restroom?.restroomId === undefined) {
      return;
    }

    const updateData = {
      restroomId: restroom.restroomId,
      topPosition: newPos.y,
      leftPosition: newPos.x,
    };

    try {
      await ApiGraphqlOperationWrapper<
        UpdateClipHgRestroomMutationVariables,
        GraphQLResult<UpdateClipHgRestroomMutation>
      >({ input: updateData }, updateClipHgRestroom);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('[SavePos]', e);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      LastestLog(`[SavePos] ${(e as { message: string })?.message}`);
    }
  };
  // 位置読み込み
  const LoadPos = () => {
    if (restroom?.restroomId === undefined) {
      return;
    }
    setTopleft({ x: restroom.leftPosition || 0, y: restroom.topPosition || 0 });
  };

  useEffect(() => {
    LoadPos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (restroom === undefined || topleft === null || !warnings) {
    return <div />;
  }

  return (
    <ToiletWindow
      subTotals={subTotals}
      parent={parent}
      topType={topType}
      restroom={restroom}
      topleft={topleft}
      SavePos={SavePos}
      warnings={warnings}
      iconType={iconType}
      usages={usages}
      mapZoomLevel={mapZoomLevel}
      dispTwZoomLevel={dispTwZoomLevel}
    />
  );
};
