/**
 * 共通処理
 */
import Amplify, { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import awsExports from 'aws-exports';
import dayjs from 'dayjs';
import { LastestLog } from 'utils/Log';
import iconStatusDirt from 'images/icon/toilet_window/title/dirt.png';
import iconStatusGood from 'images/icon/toilet_window/title/good.png';
import iconStatusRepair from 'images/icon/toilet_window/title/repair.png';
import iconStatusWarning from 'images/icon/toilet_window/title/warning.png';
import { AlertIconType, Usage, DEFAULT_COORDINATE } from 'utils/AppConfig';
import * as holiday from 'holidays-jp';
import {
  CreateClipHgSubtotalInput,
  ListClipHgSubtotalsQuery,
  ListClipHgSubtotalsQueryVariables,
  ModelSortDirection,
  GetClipHgUserRestroomQueryVariables,
  GetClipHgUserRestroomQuery,
} from 'API';
import { listClipHgSubtotals, getClipHgUserRestroom } from 'graphql/queries';
import { ConvertClipHgWarningInput, GetAlertIconTypeByWarningNumber } from 'utils/Warnings';
import { StorageItems, RestroomItems } from 'custom_hook/useRestroom';

Amplify.configure(awsExports);

// 緯度経度
export type MapLatlon = {
  lat: number;
  lon: number;
};

// graphqlのエラー
type GraphqlErrorResponse = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [key in string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: { [key in string]: any }[];
};

/**
 * graphqlOperationをエラーハンドリングしたラッパー
 *
 * @template T graphqlOperationに使う引数の型
 * @template P GraphQLResultの戻り値
 * @param {T} variables graphqlOperationの引数
 * @param {unknown} query graphqlOperationのクエリ
 * @return GraphQLResult<P>を返す
 */
export const ApiGraphqlOperationWrapper = async <T, P>(variables: T, query: unknown): Promise<P> => {
  if (!variables) {
    throw new Error('[ApiGraphqlOperationWrapper] variables error');
  }

  try {
    return (await API.graphql(graphqlOperation(query, variables))) as P;
  } catch (graphqlError) {
    const e = graphqlError as GraphqlErrorResponse;

    let message = '';
    for (let i = 0; i < e.errors.length; i += 1) {
      message = `【${e.errors[i].errorType}】${e.errors[i].message}`;
    }

    throw new Error(message.length > 0 ? message : 'Unknown error!');
  }
};

/**
 * 天気取得
 * 使い方
 * const ret = await GetWeather(43.199867, 140.995553);
 *
 * @param {number} lat 緯度
 * @param {number} lon 経度
 * @return {Promise<boolean | GetWeatherParams[]>} 失敗した場合はfalseを返す
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type OpenWeatherMapParams = {
  cod: string; // 終了コード
  cnt: number; // 取得データ数
  message: string; // codが200以外の場合のメッセージ
  list: {
    // eslint-disable-next-line camelcase
    dt_txt: string; // 日時
    main: {
      humidity: number; // 湿度
      temp: number; // 気温
      // eslint-disable-next-line camelcase
      temp_max: number; // 最高気温
      // eslint-disable-next-line camelcase
      temp_min: number; // 最低気温
    };
    weather: {
      description: string; // 天気の説明
      icon: string; // 天気アイコン
    }[];
  }[];
};
export type GetWeatherParams = {
  tempMin: number;
  tempMax: number;
  humidity: number;
  icon: string;
  description: string;
  datetime: string;
};

type WeatherForecast = {
  attributes?: {
    'custom:weather_forecast'?: string;
  };
} | null;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GetWeather = async (lat: number, lon: number): Promise<boolean | GetWeatherParams[]> => {
  let ret: GetWeatherParams[] | boolean = false;

  try {
    const userInfo = (await Auth.currentUserInfo()) as WeatherForecast;
    if (userInfo && userInfo.attributes?.['custom:weather_forecast']) {
      ret = JSON.parse(userInfo.attributes?.['custom:weather_forecast']) as GetWeatherParams[];
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`[GetUserInfo] ${error}`);
    LastestLog(`[GetUserInfo] ${error}`);

    return false;
  }

  return ret;
};

/**
 * ステータスアイコン取得
 *
 * @param {AlertIconType} type アラートアイコン種別
 */
export const GetStatusIcon = (type: AlertIconType): string => {
  switch (type) {
    case 'dirt':
      return iconStatusDirt;
    case 'good':
      return iconStatusGood;
    case 'repair':
      return iconStatusRepair;
    default:
      return iconStatusWarning;
  }
};

/**
 * ワーニングデータからAlertIconTypeを取得
 *
 * @param {ConvertClipHgWarningInput[]} warnings ワーニングデータ
 * @param {AlertIconType} init goodから始めない場合に入れる
 * @return {AlertIconType}
 */
export const GetAlertIconTypeByWarningData = (
  warning: ConvertClipHgWarningInput,
  init?: AlertIconType,
): AlertIconType => {
  let ret = init ?? 'good';
  const type = GetAlertIconTypeByWarningNumber(warning.warningNumber);
  if (type === 'repair') {
    return type;
  }
  if (type === 'warning') {
    ret = type;
  } else if (ret === 'good') {
    ret = type;
  }

  return ret;
};
export const GetAlertIconTypeByWarningDatas = (
  warnings: ConvertClipHgWarningInput[],
  init?: AlertIconType,
): AlertIconType => {
  let ret = init ?? 'good';
  for (let i = 0; i < warnings.length; i += 1) {
    ret = GetAlertIconTypeByWarningData(warnings[i], ret);
    if (ret === 'repair') {
      return ret;
    }
  }

  return ret;
};

/**
 * 数値のゼロ埋め
 *
 * @param {number} num 数値
 * @param {number} len 桁数
 * @return {string} 0埋めされた数値
 */
export const ZeroPadding = (num: number, len: number): string => {
  let zero = '';
  for (let i = 0; i < len; i += 1) {
    zero = `${zero}0`;
  }

  return `${zero}${num}`.slice(-len);
};

/**
 * 休日判定
 *
 * @param {string} yyyymmdd 判定する日付
 * @return {boolean} trueで休日
 */
export const IsHoliday = (yyyymmdd: string): boolean => {
  // 土日
  const day = dayjs(yyyymmdd).day();
  if (day === 0 || day === 6) {
    return true;
  }

  return holiday.isHoliday(dayjs(yyyymmdd).toDate());
};

/**
 * トイレの年間平均subTotalデータ
 *
 * @param {string} restroomId トイレID
 */
export const getSubTotalDatasByAverage = async (restroomId: string): Promise<CreateClipHgSubtotalInput[]> => {
  let ret: CreateClipHgSubtotalInput[] = [];
  let nextToken: string | null | undefined = null;

  // deviceId:デバイスID 取得範囲:時間降順
  const conditions: ListClipHgSubtotalsQueryVariables = {
    restroomId,
    period: { beginsWith: 'all-' },
    sortDirection: ModelSortDirection.ASC,
    nextToken: null,
  };

  try {
    do {
      conditions.nextToken = nextToken;

      // eslint-disable-next-line no-await-in-loop
      const result = await ApiGraphqlOperationWrapper<
        ListClipHgSubtotalsQueryVariables,
        GraphQLResult<ListClipHgSubtotalsQuery>
      >(conditions, listClipHgSubtotals);

      nextToken =
        undefined === result.data?.listClipHgSubtotals?.nextToken ? null : result.data?.listClipHgSubtotals?.nextToken;

      const items = (result.data?.listClipHgSubtotals?.items as unknown) as CreateClipHgSubtotalInput[];
      ret = [...ret, ...items];
    } while (nextToken !== null);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('[getSubTotalDatasByAverage]', e);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    LastestLog(`[getSubTotalDatasByAverage] ${(e as { message: string })?.message}`);
  }

  return ret;
};

/**
 * getSubTotalDatasByAverageで取得したデータを時間昇順の配列に変換
 *
 * @param {CreateClipHgSubtotalInput[]} datas getSubTotalDatasByAverageで取得したデータ
 */
export const getSubTotalDatasByAverageConvertArray = (
  datas: CreateClipHgSubtotalInput[],
): (number | null | undefined)[] => {
  const ret: (number | null | undefined)[] = [];

  datas.sort((a, b) => {
    if (a.period && b.period) {
      if (a.period < b.period) return -1;
      if (a.period > b.period) return 1;
    }

    return 0;
  });

  for (let i = 0; i < 24; i += 1) {
    let data = null;
    if (datas[i]) {
      data = datas[i]?.average ?? null;
    }
    ret.push(data);
  }

  return ret;
};

/**
 * 日時Toから日時From取得（TOP画面）
 *
 * @param {string} toDatetime 日時to
 * @return {dayjs.Dayjs} 日時From
 */
export const GetFromDatetimeByTop = (toDatetime: string | undefined): dayjs.Dayjs => {
  return dayjs(toDatetime).add(1, 'minute').subtract(24, 'hours');
};

/**
 * トイレのSubtotal取得
 *
 * @param {string} restroomId トイレID
 * @param {string} to 日付to
 */
export const GetSubtotalByRestroomId = async (
  restroomId: string,
  to: string | undefined,
  isDetail = false,
): Promise<CreateClipHgSubtotalInput[]> => {
  const toDate = dayjs(to).format('YYYYMMDDHH');
  const fromDate = isDetail
    ? dayjs(to).subtract(23, 'hours').format('YYYYMMDDHH')
    : GetFromDatetimeByTop(to).format('YYYYMMDDHH');
  let conditions: ListClipHgSubtotalsQueryVariables | null = null;

  try {
    let subTotalDatas: CreateClipHgSubtotalInput[] = [];
    let nextToken: string | null | undefined = null;

    // トイレID、時間範囲指定
    conditions = {
      restroomId,
      period: {
        between: [fromDate, toDate],
      },
      sortDirection: ModelSortDirection.ASC,
      nextToken: null,
    };

    do {
      conditions.nextToken = nextToken;

      // eslint-disable-next-line no-await-in-loop
      const result = await ApiGraphqlOperationWrapper<
        ListClipHgSubtotalsQueryVariables,
        GraphQLResult<ListClipHgSubtotalsQuery>
      >(conditions, listClipHgSubtotals);

      nextToken =
        undefined === result.data?.listClipHgSubtotals?.nextToken ? null : result.data?.listClipHgSubtotals?.nextToken;

      const items = (result.data?.listClipHgSubtotals?.items as unknown) as CreateClipHgSubtotalInput[];
      subTotalDatas = [...subTotalDatas, ...items];
    } while (nextToken !== null);

    return subTotalDatas;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[GetSubtotalByRestroomId]', error, conditions);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    LastestLog(`[GetSubtotalByRestroomId] ${(error as { message: string })?.message}`);

    return [];
  }
};

/**
 * 用途ごとの利用回数取得
 *
 * @param {CreateClipHgSubtotalInput[]} subtotals subtotalデータ
 * @param {Usage} usage 用途
 */
// 用途ごとの利用回数取得
export const GetSubTotalToUseCount = (subtotals: CreateClipHgSubtotalInput[], usage?: Usage): number => {
  let ret = 0;

  subtotals.forEach((data) => {
    if (usage === undefined || usage === null) {
      ret +=
        data.maleBothUse +
        data.maleToiletUse +
        data.maleWashUse +
        data.femaleBothUse +
        data.femaleToiletUse +
        data.femaleWashUse +
        data.multiBothUse +
        data.multiToiletUse +
        data.multiWashUse;
    } else {
      switch (usage) {
        case 1:
          ret += data.maleBothUse + data.maleToiletUse + data.maleWashUse;
          break;
        case 2:
          ret += data.femaleBothUse + data.femaleToiletUse + data.femaleWashUse;
          break;
        case 3:
          ret += data.multiBothUse + data.multiToiletUse + data.multiWashUse;
          break;
        default:
          break;
      }
    }
  });

  return ret;
};

/**
 * 用途ごとの手洗い率取得
 *
 * @param {CreateClipHgSubtotalInput[]} subtotals subtotalデータ
 * @param {Usage} usage 用途
 * @param {Usage[]} usages 使用全用途
 */
export const GetSubTotalToWashRate = (
  subtotals: CreateClipHgSubtotalInput[],
  usage?: Usage,
  usages?: Usage[],
): number | null | undefined => {
  let count = 0;
  let total = 0;

  // 用途外判定
  if (usages !== null && usages !== undefined && usage !== null && usage !== undefined) {
    if (!usages.some((v) => v === usage)) {
      return undefined;
    }
  }

  subtotals.forEach((data) => {
    switch (usage) {
      case 1:
        count += data.maleBothUse;
        total += data.maleBothUse + data.maleToiletUse;
        break;
      case 2:
        count += data.femaleBothUse;
        total += data.femaleBothUse + data.femaleToiletUse;
        break;
      case 3:
        count += data.multiBothUse;
        total += data.multiBothUse + data.multiToiletUse;
        break;
      default:
        count += data.femaleBothUse;
        count += data.maleBothUse;
        count += data.multiBothUse;
        total += data.maleBothUse + data.maleToiletUse;
        total += data.femaleBothUse + data.femaleToiletUse;
        total += data.multiBothUse + data.multiToiletUse;
        break;
    }
  });

  if (total <= 0) {
    return null;
  }

  return Math.floor((count / total) * 100);
};

/**
 * KLチェック
 *
 * @param {number | null | undefined} src チェック元
 * @return {boolean}
 */
export const CheckKl = (val: number | null | undefined): boolean => {
  if (val === null || val === undefined) {
    return false;
  }
  if (val >= 1000) {
    return true;
  }

  return false;
};

/**
 * 数値チェック
 *
 * @param {string | null | undefined} val チェック元
 * @return {boolean}
 */
export const IsNumber = (val: string | null | undefined): boolean => {
  if (val === null || val === undefined) {
    return false;
  }

  const regexp = new RegExp(/^[-]?([1-9]\d*|0)(\.\d+)?$/);

  return regexp.test(val);
};

/**
 * トイレの画像リスト取得
 *
 * @param {string} restroomId トイレID
 * @return {Promise<StorageItems[]>} 画像リスト
 */
export const GetImageList = async (restroomId: string): Promise<StorageItems[]> => {
  try {
    const files = (await Storage.list(`${restroomId}/`)) as StorageItems[];

    return files.filter((file) => file?.size !== 0);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[GetImageList]', error);

    return [];
  }
};

/**
 * ストレージの署名付きURL取得
 *
 * @param {string | undefined} key ストレージキー
 * @return {Promise<string | undefined>}
 */
export const GetImageUrl = async (key: string | undefined): Promise<string | undefined> => {
  let imageUrl;
  if (key) {
    try {
      imageUrl = (await Storage.get(key)) as string;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[GetImageUrl]', error);
    }
  }

  return imageUrl;
};

/**
 * ユーザー情報の緯度経度を変換
 *
 * @param {string | undefined} coordinate ユーザー情報の緯度経度（undefinedの場合はデフォルト値を使用する）
 * @return {MapLatlon>}
 */
export const ConvertCoordinateByUser = (coordinate: string = DEFAULT_COORDINATE): MapLatlon => {
  let tmp = coordinate.split(',');
  if (tmp.length < 2) {
    tmp = DEFAULT_COORDINATE.split(',');
  }

  return {
    lat: Number(tmp[0]),
    lon: Number(tmp[1]),
  };
};

/**
 * ユーザー別トイレ情報取得
 *
 * @param {string} restroomId トイレID
 * @param {string} username ユーザー名
 * @return {RestroomItems | null} 失敗時にNULLを返す
 */
export const getRestroom = async (restroomId: string, username: string): Promise<RestroomItems | null> => {
  let ret = null;
  const conditions: GetClipHgUserRestroomQueryVariables = {
    user: username,
    restroomId,
  };

  try {
    const result = await ApiGraphqlOperationWrapper<
      GetClipHgUserRestroomQueryVariables,
      GraphQLResult<GetClipHgUserRestroomQuery>
    >(conditions, getClipHgUserRestroom);

    ret = (result.data?.getClipHgUserRestroom as unknown) as RestroomItems;

    if (ret) {
      ret.isNotify = ret.isNotify ?? false;
      ret.isHiddenSecurityWarning = ret.isHiddenSecurityWarning ?? false;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[getRestroomError]', error);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    LastestLog(`[getRestroomError] ${(error as { message: string })?.message}`);
  }

  return ret;
};
