/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClipHgRestroom = /* GraphQL */ `
  mutation CreateClipHgRestroom(
    $input: CreateClipHgRestroomInput!
    $condition: ModelClipHgRestroomConditionInput
  ) {
    createClipHgRestroom(input: $input, condition: $condition) {
      restroomId
      prefAreaId
      cityAreaId
      wardAreaId
      name
      address
      location {
        lat
        lon
      }
      latestLocationTime
      isWinter
      isPremium
      useMin
      useMax
      topPosition
      leftPosition
      isMove
      pref {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      city {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      ward {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      devices {
        items {
          deviceId
          restroomId
          co2SensorUsage
          createdAt
          updatedAt
          restroom {
            restroomId
            prefAreaId
            cityAreaId
            wardAreaId
            name
            address
            latestLocationTime
            isWinter
            isPremium
            useMin
            useMax
            topPosition
            leftPosition
            isMove
            createdAt
            updatedAt
          }
          toilets {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteClipHgRestroom = /* GraphQL */ `
  mutation DeleteClipHgRestroom(
    $input: DeleteClipHgRestroomInput!
    $condition: ModelClipHgRestroomConditionInput
  ) {
    deleteClipHgRestroom(input: $input, condition: $condition) {
      restroomId
      prefAreaId
      cityAreaId
      wardAreaId
      name
      address
      location {
        lat
        lon
      }
      latestLocationTime
      isWinter
      isPremium
      useMin
      useMax
      topPosition
      leftPosition
      isMove
      pref {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      city {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      ward {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      devices {
        items {
          deviceId
          restroomId
          co2SensorUsage
          createdAt
          updatedAt
          restroom {
            restroomId
            prefAreaId
            cityAreaId
            wardAreaId
            name
            address
            latestLocationTime
            isWinter
            isPremium
            useMin
            useMax
            topPosition
            leftPosition
            isMove
            createdAt
            updatedAt
          }
          toilets {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createClipHgDevice = /* GraphQL */ `
  mutation CreateClipHgDevice(
    $input: CreateClipHgDeviceInput!
    $condition: ModelClipHgDeviceConditionInput
  ) {
    createClipHgDevice(input: $input, condition: $condition) {
      deviceId
      restroomId
      co2SensorUsage
      createdAt
      updatedAt
      restroom {
        restroomId
        prefAreaId
        cityAreaId
        wardAreaId
        name
        address
        location {
          lat
          lon
        }
        latestLocationTime
        isWinter
        isPremium
        useMin
        useMax
        topPosition
        leftPosition
        isMove
        pref {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        city {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        ward {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        devices {
          items {
            deviceId
            restroomId
            co2SensorUsage
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      toilets {
        items {
          deviceId
          name
          usage
          number
          type
          fixedFlowRate
          doorLockId
          isOccupied
          emergencyButtonId
          isConnected
          disconnectedTime
          hasDisconnectWarning
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateClipHgDevice = /* GraphQL */ `
  mutation UpdateClipHgDevice(
    $input: UpdateClipHgDeviceInput!
    $condition: ModelClipHgDeviceConditionInput
  ) {
    updateClipHgDevice(input: $input, condition: $condition) {
      deviceId
      restroomId
      co2SensorUsage
      createdAt
      updatedAt
      restroom {
        restroomId
        prefAreaId
        cityAreaId
        wardAreaId
        name
        address
        location {
          lat
          lon
        }
        latestLocationTime
        isWinter
        isPremium
        useMin
        useMax
        topPosition
        leftPosition
        isMove
        pref {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        city {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        ward {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        devices {
          items {
            deviceId
            restroomId
            co2SensorUsage
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      toilets {
        items {
          deviceId
          name
          usage
          number
          type
          fixedFlowRate
          doorLockId
          isOccupied
          emergencyButtonId
          isConnected
          disconnectedTime
          hasDisconnectWarning
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteClipHgDevice = /* GraphQL */ `
  mutation DeleteClipHgDevice(
    $input: DeleteClipHgDeviceInput!
    $condition: ModelClipHgDeviceConditionInput
  ) {
    deleteClipHgDevice(input: $input, condition: $condition) {
      deviceId
      restroomId
      co2SensorUsage
      createdAt
      updatedAt
      restroom {
        restroomId
        prefAreaId
        cityAreaId
        wardAreaId
        name
        address
        location {
          lat
          lon
        }
        latestLocationTime
        isWinter
        isPremium
        useMin
        useMax
        topPosition
        leftPosition
        isMove
        pref {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        city {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        ward {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        devices {
          items {
            deviceId
            restroomId
            co2SensorUsage
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      toilets {
        items {
          deviceId
          name
          usage
          number
          type
          fixedFlowRate
          doorLockId
          isOccupied
          emergencyButtonId
          isConnected
          disconnectedTime
          hasDisconnectWarning
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createClipHgToilet = /* GraphQL */ `
  mutation CreateClipHgToilet(
    $input: CreateClipHgToiletInput!
    $condition: ModelClipHgToiletConditionInput
  ) {
    createClipHgToilet(input: $input, condition: $condition) {
      deviceId
      name
      usage
      number
      type
      fixedFlowRate
      doorLockId
      isOccupied
      emergencyButtonId
      isConnected
      disconnectedTime
      hasDisconnectWarning
      createdAt
      updatedAt
    }
  }
`;
export const deleteClipHgToilet = /* GraphQL */ `
  mutation DeleteClipHgToilet(
    $input: DeleteClipHgToiletInput!
    $condition: ModelClipHgToiletConditionInput
  ) {
    deleteClipHgToilet(input: $input, condition: $condition) {
      deviceId
      name
      usage
      number
      type
      fixedFlowRate
      doorLockId
      isOccupied
      emergencyButtonId
      isConnected
      disconnectedTime
      hasDisconnectWarning
      createdAt
      updatedAt
    }
  }
`;
export const deleteClipHgCalcData = /* GraphQL */ `
  mutation DeleteClipHgCalcData(
    $input: DeleteClipHgCalcDataInput!
    $condition: ModelClipHgCalcDataConditionInput
  ) {
    deleteClipHgCalcData(input: $input, condition: $condition) {
      calcDataId
      restroomId
      deviceId
      sendDateTime
      carbonDioxide
      temperature
      humidity
      usage
      number
      type
      flushCount
      flowTime
      flowRate
      ev
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteClipHgUseCount = /* GraphQL */ `
  mutation DeleteClipHgUseCount(
    $input: DeleteClipHgUseCountInput!
    $condition: ModelClipHgUseCountConditionInput
  ) {
    deleteClipHgUseCount(input: $input, condition: $condition) {
      useCountId
      restroomId
      deviceId
      useDateTime
      usage
      type
      number
      washDeadline
      washDeviceId
      washNumber
      washTime
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteClipHgSubtotal = /* GraphQL */ `
  mutation DeleteClipHgSubtotal(
    $input: DeleteClipHgSubtotalInput!
    $condition: ModelClipHgSubtotalConditionInput
  ) {
    deleteClipHgSubtotal(input: $input, condition: $condition) {
      restroomId
      period
      maleBothUse
      maleToiletUse
      maleWashUse
      femaleBothUse
      femaleToiletUse
      femaleWashUse
      multiBothUse
      multiToiletUse
      multiWashUse
      maleCarbonDioxide
      femaleCarbonDioxide
      multiCarbonDioxide
      latestTemperature
      latestHumidity
      maleFlowRate
      femaleFlowRate
      multiFlowRate
      average
      elapsedDays
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createClipHgArea = /* GraphQL */ `
  mutation CreateClipHgArea(
    $input: CreateClipHgAreaInput!
    $condition: ModelClipHgAreaConditionInput
  ) {
    createClipHgArea(input: $input, condition: $condition) {
      areaId
      name
      location {
        lat
        lon
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateClipHgArea = /* GraphQL */ `
  mutation UpdateClipHgArea(
    $input: UpdateClipHgAreaInput!
    $condition: ModelClipHgAreaConditionInput
  ) {
    updateClipHgArea(input: $input, condition: $condition) {
      areaId
      name
      location {
        lat
        lon
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteClipHgArea = /* GraphQL */ `
  mutation DeleteClipHgArea(
    $input: DeleteClipHgAreaInput!
    $condition: ModelClipHgAreaConditionInput
  ) {
    deleteClipHgArea(input: $input, condition: $condition) {
      areaId
      name
      location {
        lat
        lon
      }
      createdAt
      updatedAt
    }
  }
`;
export const createClipHgUserRestroom = /* GraphQL */ `
  mutation CreateClipHgUserRestroom(
    $input: CreateClipHgUserRestroomInput!
    $condition: ModelClipHgUserRestroomConditionInput
  ) {
    createClipHgUserRestroom(input: $input, condition: $condition) {
      user
      restroomId
      isNotify
      isHiddenSecurityWarning
      createdAt
      updatedAt
      restrooms {
        items {
          restroomId
          prefAreaId
          cityAreaId
          wardAreaId
          name
          address
          location {
            lat
            lon
          }
          latestLocationTime
          isWinter
          isPremium
          useMin
          useMax
          topPosition
          leftPosition
          isMove
          pref {
            areaId
            name
            createdAt
            updatedAt
          }
          city {
            areaId
            name
            createdAt
            updatedAt
          }
          ward {
            areaId
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          devices {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateClipHgUserRestroom = /* GraphQL */ `
  mutation UpdateClipHgUserRestroom(
    $input: UpdateClipHgUserRestroomInput!
    $condition: ModelClipHgUserRestroomConditionInput
  ) {
    updateClipHgUserRestroom(input: $input, condition: $condition) {
      user
      restroomId
      isNotify
      isHiddenSecurityWarning
      createdAt
      updatedAt
      restrooms {
        items {
          restroomId
          prefAreaId
          cityAreaId
          wardAreaId
          name
          address
          location {
            lat
            lon
          }
          latestLocationTime
          isWinter
          isPremium
          useMin
          useMax
          topPosition
          leftPosition
          isMove
          pref {
            areaId
            name
            createdAt
            updatedAt
          }
          city {
            areaId
            name
            createdAt
            updatedAt
          }
          ward {
            areaId
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          devices {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteClipHgUserRestroom = /* GraphQL */ `
  mutation DeleteClipHgUserRestroom(
    $input: DeleteClipHgUserRestroomInput!
    $condition: ModelClipHgUserRestroomConditionInput
  ) {
    deleteClipHgUserRestroom(input: $input, condition: $condition) {
      user
      restroomId
      isNotify
      isHiddenSecurityWarning
      createdAt
      updatedAt
      restrooms {
        items {
          restroomId
          prefAreaId
          cityAreaId
          wardAreaId
          name
          address
          location {
            lat
            lon
          }
          latestLocationTime
          isWinter
          isPremium
          useMin
          useMax
          topPosition
          leftPosition
          isMove
          pref {
            areaId
            name
            createdAt
            updatedAt
          }
          city {
            areaId
            name
            createdAt
            updatedAt
          }
          ward {
            areaId
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          devices {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateClipHgRestroom = /* GraphQL */ `
  mutation UpdateClipHgRestroom(
    $input: UpdateClipHgRestroomInput!
    $condition: ModelClipHgRestroomConditionInput
  ) {
    updateClipHgRestroom(input: $input, condition: $condition) {
      restroomId
      prefAreaId
      cityAreaId
      wardAreaId
      name
      address
      location {
        lat
        lon
      }
      latestLocationTime
      isWinter
      isPremium
      useMin
      useMax
      topPosition
      leftPosition
      isMove
      pref {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      city {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      ward {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      devices {
        items {
          deviceId
          restroomId
          co2SensorUsage
          createdAt
          updatedAt
          restroom {
            restroomId
            prefAreaId
            cityAreaId
            wardAreaId
            name
            address
            latestLocationTime
            isWinter
            isPremium
            useMin
            useMax
            topPosition
            leftPosition
            isMove
            createdAt
            updatedAt
          }
          toilets {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateClipHgToilet = /* GraphQL */ `
  mutation UpdateClipHgToilet(
    $input: UpdateClipHgToiletInput!
    $condition: ModelClipHgToiletConditionInput
  ) {
    updateClipHgToilet(input: $input, condition: $condition) {
      deviceId
      name
      usage
      number
      type
      fixedFlowRate
      doorLockId
      isOccupied
      emergencyButtonId
      isConnected
      disconnectedTime
      hasDisconnectWarning
      createdAt
      updatedAt
    }
  }
`;
export const createClipHgCalcData = /* GraphQL */ `
  mutation CreateClipHgCalcData(
    $input: CreateClipHgCalcDataInput!
    $condition: ModelClipHgCalcDataConditionInput
  ) {
    createClipHgCalcData(input: $input, condition: $condition) {
      calcDataId
      restroomId
      deviceId
      sendDateTime
      carbonDioxide
      temperature
      humidity
      usage
      number
      type
      flushCount
      flowTime
      flowRate
      ev
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateClipHgCalcData = /* GraphQL */ `
  mutation UpdateClipHgCalcData(
    $input: UpdateClipHgCalcDataInput!
    $condition: ModelClipHgCalcDataConditionInput
  ) {
    updateClipHgCalcData(input: $input, condition: $condition) {
      calcDataId
      restroomId
      deviceId
      sendDateTime
      carbonDioxide
      temperature
      humidity
      usage
      number
      type
      flushCount
      flowTime
      flowRate
      ev
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createClipHgWarning = /* GraphQL */ `
  mutation CreateClipHgWarning(
    $input: CreateClipHgWarningInput!
    $condition: ModelClipHgWarningConditionInput
  ) {
    createClipHgWarning(input: $input, condition: $condition) {
      warningId
      restroomId
      deviceId
      sendDateTime
      warningNumber
      warningType
      usage
      number
      type
      toiletName
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateClipHgWarning = /* GraphQL */ `
  mutation UpdateClipHgWarning(
    $input: UpdateClipHgWarningInput!
    $condition: ModelClipHgWarningConditionInput
  ) {
    updateClipHgWarning(input: $input, condition: $condition) {
      warningId
      restroomId
      deviceId
      sendDateTime
      warningNumber
      warningType
      usage
      number
      type
      toiletName
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteClipHgWarning = /* GraphQL */ `
  mutation DeleteClipHgWarning(
    $input: DeleteClipHgWarningInput!
    $condition: ModelClipHgWarningConditionInput
  ) {
    deleteClipHgWarning(input: $input, condition: $condition) {
      warningId
      restroomId
      deviceId
      sendDateTime
      warningNumber
      warningType
      usage
      number
      type
      toiletName
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createClipHgUseCount = /* GraphQL */ `
  mutation CreateClipHgUseCount(
    $input: CreateClipHgUseCountInput!
    $condition: ModelClipHgUseCountConditionInput
  ) {
    createClipHgUseCount(input: $input, condition: $condition) {
      useCountId
      restroomId
      deviceId
      useDateTime
      usage
      type
      number
      washDeadline
      washDeviceId
      washNumber
      washTime
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateClipHgUseCount = /* GraphQL */ `
  mutation UpdateClipHgUseCount(
    $input: UpdateClipHgUseCountInput!
    $condition: ModelClipHgUseCountConditionInput
  ) {
    updateClipHgUseCount(input: $input, condition: $condition) {
      useCountId
      restroomId
      deviceId
      useDateTime
      usage
      type
      number
      washDeadline
      washDeviceId
      washNumber
      washTime
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createClipHgSubtotal = /* GraphQL */ `
  mutation CreateClipHgSubtotal(
    $input: CreateClipHgSubtotalInput!
    $condition: ModelClipHgSubtotalConditionInput
  ) {
    createClipHgSubtotal(input: $input, condition: $condition) {
      restroomId
      period
      maleBothUse
      maleToiletUse
      maleWashUse
      femaleBothUse
      femaleToiletUse
      femaleWashUse
      multiBothUse
      multiToiletUse
      multiWashUse
      maleCarbonDioxide
      femaleCarbonDioxide
      multiCarbonDioxide
      latestTemperature
      latestHumidity
      maleFlowRate
      femaleFlowRate
      multiFlowRate
      average
      elapsedDays
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateClipHgSubtotal = /* GraphQL */ `
  mutation UpdateClipHgSubtotal(
    $input: UpdateClipHgSubtotalInput!
    $condition: ModelClipHgSubtotalConditionInput
  ) {
    updateClipHgSubtotal(input: $input, condition: $condition) {
      restroomId
      period
      maleBothUse
      maleToiletUse
      maleWashUse
      femaleBothUse
      femaleToiletUse
      femaleWashUse
      multiBothUse
      multiToiletUse
      multiWashUse
      maleCarbonDioxide
      femaleCarbonDioxide
      multiCarbonDioxide
      latestTemperature
      latestHumidity
      maleFlowRate
      femaleFlowRate
      multiFlowRate
      average
      elapsedDays
      ttl
      createdAt
      updatedAt
    }
  }
`;
