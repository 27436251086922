/**
 * ログ管理
 */
import dayjs from 'dayjs';

// LastestLogのキー
const LASTEST_LOG_KEY = 'LASTEST_LOG';

// LastestLogの最大保持数
const LASTEST_LOG_MAX = 5;

// 保存フォーマット
type SaveProps = {
  datetime: string;
  value: string;
};

/**
 * LASTEST_LOG_MAX件までのログをローカルストレージに出力する
 *
 * @param {string} value 出力内容
 */
export const LastestLog = (text: string): void => {
  const data = localStorage.getItem(LASTEST_LOG_KEY);
  const save: SaveProps[] = ((): SaveProps[] => {
    try {
      if (data) {
        const json = JSON.parse(data) as SaveProps[];
        if (json) {
          return json;
        }
      }
    } catch {
      return [];
    }

    return [];
  })();

  // 古いデータを消す
  if (LASTEST_LOG_MAX < save.length + 1) {
    save.sort((a, b) => {
      if (a.datetime < b.datetime) return -1;
      if (a.datetime > b.datetime) return 1;

      return 0;
    });
    save.splice(0, 1);
  }
  save.push({
    datetime: dayjs().format('YYYY/MM/DD HH:mm:ss').toString(),
    value: text,
  });

  localStorage.setItem(LASTEST_LOG_KEY, JSON.stringify(save));
};
